.homepage-slant {
  background-image: linear-gradient(to right, #00B983, #1f3e84);
  height: 70rem;
  transform: skewY(-3deg);
}

.homepage-slant::after {
  content: unset;
}

.homepage-container {
  margin-top: 20rem;
  margin-bottom: 25rem;
}

.homepage-header {
  padding: 0 4rem;
}

.hero-heading--homepage {
  color: white;
  line-height: 1.4;
  font-size: 3.2rem;
  font-weight: 800;
}

.hero-sub-heading--homepage {
  color: white;
  font-size: 1.6rem;
  line-height: 1.1;
}

.hp-btn--homepage-hero:link,
.hp-btn--homepage-hero:visited {
  padding: .7rem 4.5rem;
}

.homepage-hero-image {
  width: 150%;
  margin-left: -5rem;
  margin-top: 2rem; 
  display: none;
}

/* Benefits section */


.homepage-benefits-section {
  margin-bottom: 10rem;
}

.homepage-benefits__row {
  justify-content: center;
  margin: 0;
}

.homepage-benefits__heading {
  color: #00B983;
  margin-bottom: 4rem;
  font-size: 2.4rem;
}

.homepage-benefits__snippet {
  margin-bottom: 4rem;
  font-size: 1.6rem;
}

.hp-btn--homepage:link,
.hp-btn--homepage:visited {
  padding: .7rem 3rem;
}

.homepage-benefits__image {
  width: 100%;
  padding: 0 2rem;
  margin-left: 10rem;
  display: none;
}

/* Slanted section */

.slanted-section.slanted-section--homepage {
  background-color: #f6f7fa;
  transform: skewY(-3deg);
  padding: 12rem 0 8rem 0;
}

.slanted-section--homepage__container {
  transform: skewY(3deg);
}

.slanted-section--homepage__row {
  justify-content: center;
}

.slanted-section--homepage__link-cont {
  text-align: end;
  transition: all .2s;
}

.slanted-section--homepage__link-cont:hover {
  background-color: white;
  border-radius: .7rem;
  box-shadow: 0 1rem 1rem rgba(0,0,0,0.1);
}

.slanted-section--homepage__link-cont:hover .slanted-section--homepage__link {
  color: var(--healpay-pro-blue);
}

.slanted-section--homepage__link:link,
.slanted-section--homepage__link:visited {
  color: #00BA82;
  font-size: 1.4rem;
  transition: all .2s;
  width: 100%;
  padding: 1.5rem 2rem 1.5rem 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.slanted-section--homepage__link:hover,
.slanted-section--homepage__link:active {
  color: #1F3C84;
  transform: none;
}

.slanted-section--homepage__svg {
  margin-left: 1.3rem;
  width: 1.8rem;
}

.slanted-section--homepage__svg-a {
  fill: none;
}

.slanted-section--homepage__svg-b {
  fill: #00BA82;
  transition: all .2s;
}

.slanted-section--homepage__link-cont:hover .slanted-section--homepage__svg-b,
.slanted-section--homepage__link-cont:active .slanted-section--homepage__svg-b {
  fill: #1F3C84;
}

.slanted-section--homepage__header-cont {
  margin-top: -1.5rem;
  margin-bottom: 3rem;
}

.slanted-section--homepage__header {
  color: #1F3C84;
  font-size: 2.6rem;
  line-height: 1.2;
  margin-bottom: 4rem;
}

.slanted-section--homepage__snippet {
  font-size: 1.6rem;
}


/* Homepage card section */

.homepage-card__container {
  margin-top: 10rem;
  margin-bottom: 15rem;
}

.homepage-card__row {
  flex-direction: column;
  align-content: center;
}

.homepage-card__header-row {
  margin-bottom: 10rem;
}

.homepage-card__header {
  color: #00B983;
  font-size: 2.6rem;
  text-align: center;
}

.homepage-card__header-image {
  display: none;
}

.homepage-card {
  padding: 3.5rem 2.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.2);
  border-radius: 5px;
  background-color: white;
  position: relative;
  max-width: 80%;
  margin-bottom: 7rem;
}

.homepage-card__snippet {
  font-size: 2.2rem;
}

.homepage-card__snippet-link:link,
.homepage-card__snippet-link:visited {
  color: var(--healpay-green-light);
  font-weight: 600;
  font-size: 2.2rem;
  text-decoration: none;
}

.homepage-card__snippet-link:hover,
.homepage-card__snippet-link:active {
  color: var(--hover-black);
}

.homepage-card__icon {
  position: absolute;
  bottom: -3.5rem;
  right: -3rem;
  width: 20%;
}

/* Testimonial section */

.homepage-testimonial {
  margin-bottom: 15rem;
}

.homepage-testimonial__video-cont {
  padding: 0;
}

.homepage-testimonial__video {
  width: 100%;
}

.homepage-testimonial__caption-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem;
}

.homepage-testimonial__caption {
  font-style: italic;
  font-size: 1.6rem;
  line-height: 1.3;
  margin-bottom: 2rem;
}

.homepage-testimonial__name, .homepage-testimonial__title, .homepage-testimonial__merchant {
  margin-bottom: 0;
}

.homepage-testimonial__name {
  font-family: 'Metric Web-Bold', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
}

/* Contact Section */

.contact-sales__section--homepage-adjust {
  margin-bottom: 22rem;
  padding: 0;
}

@media (min-width: 400px) {

  .homepage-container {
    margin-top: 25rem;
    margin-bottom: 25rem;
  }

  .hero-heading--homepage {
    width: 30rem;
  }

  .hero-sub-heading--homepage {
    width: 85%;
  }

  .homepage-benefits__heading {
    width: 25rem;
  }

  .slanted-section--homepage__link:link,
  .slanted-section--homepage__link:visited {
    font-size: 1.6rem;
  }

  .homepage-card {
    max-width: 70%;
  }

}

@media (min-width: 575px) {

  .homepage-container {
    margin-bottom: 30rem;
  }

  .slanted-section--homepage__link:link,
  .slanted-section--homepage__link:visited {
    font-size: 1.8rem;
  }

  .homepage-benefits__heading {
    width: 80%;
  }

  .homepage-testimonial__caption-cont {
    padding: 1rem;
  }

  .homepage-card__icon {
    position: absolute;
    bottom: -3rem;
    right: -3rem;
    width: 17%;
  }

}


@media (min-width: 768px) {

  .hero-sub-heading--homepage {
    font-size: 2.2rem;
  }

  .homepage-benefits__row {
    justify-content: space-around;
  }

  .homepage-benefits__heading {
    font-size: 2.8rem;
  }

  .homepage-benefits__snippet {
    margin-bottom: 4rem;
    font-size: 2rem;
  }

  .homepage-benefits__image {
    width: 110%;
  }

  .slanted-section--homepage__snippet {
    font-size: 2rem;
  }

  .slanted-section--homepage__header {
    font-size: 3.2rem;
  }

  .slanted-section--homepage__link-col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .slanted-section--homepage__link-cont {
    min-width: 40rem;
  }

  .homepage-card {
    max-width: 60%;
  }

  .homepage-card__icon {
    bottom: -3rem;
    right: -3rem;
    width: 15%;
  }

}

@media (min-width: 992px) {

  .homepage-container {
    margin-top: 10rem;
    margin-bottom: 25rem;
  }
  
  .hero-heading--homepage {
    font-size: 4rem;
    width: 40rem;
  }

  .homepage-hero-image {
    width: 140%;
    margin-left: -4rem;
    display: block;
  }

  .homepage-benefits-section {
    margin-bottom: 5rem;
  }

  .homepage-benefits__heading {
    font-size: 3.6rem;
  }

  .homepage-benefits__image {
    margin-left: 0;
    display: block;
  }

  .homepage-card__container {
    margin-bottom: 15rem;
  }

  .homepage-card__header {
    font-size: 3.2rem;
    text-align: left;
    line-height: 1.3;
  }

  .homepage-card__row {
    flex-direction: row;
    justify-content: space-around;
  }

  .homepage-card__header-row {
    margin-bottom: 10rem;
  }

  .homepage-card__header-image {
    display: block;
    position: absolute;
    top: -5rem;
    right: -5rem;
    width: 60%;
  }

  .homepage-card {
    max-width: 27%;
  }

  .homepage-card__icon {
    width: 24%;
    bottom: -3rem;
    right: -3rem;
  }

  .slanted-section.slanted-section--homepage {
    margin-bottom: 15rem;
    padding: 7% 0 5% 0;
    height: 50rem;
  }

  .slanted-section--homepage__header-cont {
    padding-left: 5rem;
    margin-top: .5rem;
  }

  .slanted-section--homepage__header {
    font-size: 3.2rem;
  }

  .slanted-section--homepage__snippet {
    font-size: 2.2rem;
  }

  .slanted-section--homepage__link:link,
  .slanted-section--homepage__link:visited {
    font-size: 1.7rem;
  }

  .homepage-testimonial__caption-cont {
    padding: 0 1.5rem;
  }

}

@media (min-width: 1200px) {

  .hero-heading--homepage {
    font-size: 5rem;
    width: 80%
  }

  .hero-sub-heading--homepage {
    width: 80%;
  }

  .homepage-hero-image {
    margin-left: -10rem;
    width: 120%;
  }

  .homepage-benefits-section {
    margin-bottom: 10rem;
  }

  .homepage-benefits__image {
    width: 100%;
    padding: 0 2rem;
    margin-left: 3rem;
  }

  .homepage-card__header-row {
    margin-bottom: 10rem;
  }

  .homepage-card__header {
    font-size: 3.8rem;
  }

  .homepage-card__header-image {
    position: absolute;
    top: -8rem;
    right: -10rem;
    width: 60%;
  }

  .slanted-section--homepage__header-cont {
    padding-left: 10rem;
  }

  .slanted-section--homepage__header {
    font-size: 3.6rem;
  }

  .slanted-section--homepage__link:link,
  .slanted-section--homepage__link:visited {
    font-size: 2rem;
  }

  .slanted-section--homepage__svg {
    margin-left: 2rem;
  }

  .homepage-testimonial__caption {
    font-size: 1.8rem;
  }

  .homepage-card__icon {
    width: 23%;
    bottom: -3.5rem;
    right: -3rem;
  }

}


@media (min-width: 1500px) {

  .hero-heading--homepage {
    font-size: 5.5rem;
  }

  .homepage-hero-image {
    margin-left: -5rem;
    width: 140%;
  }

  .homepage-benefits__image {
    margin-left: 10rem;
  }

}