.contact-us__title {
    margin: 15rem auto;
    margin-bottom: 10rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 3rem;
}

.contact-us-form__container {
    margin-top: 3rem;
    margin-bottom: 10rem;
}

.contact-us__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 95rem;
    margin-bottom: 10rem;
}

.contact-us-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 45%;
    justify-content: space-evenly;
}

.contact-us-address__img-cont {
    text-align: center;
}

.contact-us-address__img {
    max-width: 75%;
}

.contact-us-address__block {
    text-align: center;
}

.contact-us-address__block--location {
    text-transform: uppercase;
    color: #008961;
    font-weight: 600;
}

.hp-btn--contact-us {
    margin-top: 3rem;
    min-width: 20rem;
    height: 5rem;
}

.hp-btn--contact-us a {
    padding: 2rem;
    color: #F2F2F2;
}

.contact-us-form__header {
    margin-left: 1rem;
}

.contact-us__iframe {
    height:750px;
    width:99%;
    border:none;
}

@media only screen and (min-width: 575px) {

    .contact-us-form__header {
        text-align: center;
    }

    .contact-us__iframe {
        width: 75%;
    }

}

@media only screen and (min-width: 768px) {

    .contact-us__row {
        height: 60rem;
    }

    .contact-us-form__header {
        font-size: 2.5rem;
    }

    .contact-us-address {
        flex-direction: row;
        justify-content: space-evenly;
    }

}

@media only screen and (min-width: 992px) {

    .contact-us__row {
        height: 55rem;
    }

    .contact-us-address {
        height: unset;
    }

    .contact-us__iframe {
        width: 60%;
    }
}

@media only screen and (min-width: 1200px) {
    .contact-us__row {
        flex-direction: row;
        height: 30rem;
    }

    .contact-us__iframe {
        width: 50%;
    }
}