.get-started__container {
    margin: 5rem auto;
}

.get-started__container h3 {
    color: #128961;
}

.get-started__form {
    background-color: #56DBA1;
    padding: 2rem;
    margin-bottom: 15rem;
    border-radius: 1rem;
}

.get-started__form input {
    border-radius: 3px;
    transition: all .3s ease;
    border: 4px transparent;
    display: block;
    padding: 9px 11px;
    width: 100%;
    margin: 0;
    font-size: 1.3rem;
    line-height: 0;
}

.get-started__form input:focus {
    box-shadow: 0px 3px 6px rgba(0,0,0,.25);
}

.get-started__form label {
    margin: 1rem auto;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.get-started__select {
    background-color: #fff;
    transition: all .3s ease;
    display: block;
    padding: 9px 11px;
    width: 100%;
    margin: 0;
    font-size: 1.3rem;
    line-height: 0;
    border: 2px;
    border-radius: 3px;
}

.get-started__select:focus {
    box-shadow: 0px 3px 6px rgba(0,0,0,.25);
}

.styled-select select {
    box-shadow: 0px 3px 6px #00000029;
    transition: all .1s ease;
    display: block;
    padding: 9px 11px;
    width: 100%;
    margin: 0;
    font-size: 1.3rem;
    line-height: 0;
}

.multiselect-native-select {
    transition: all .3s ease;
    display: block;
    width: 100%;
    margin: 0;
    font-size: 13px;
    line-height: 0;
    border-radius: 3px;
    background-color: white;
    max-height: 3.7rem;
}

.multiselect-native-select .multiselect {
    padding: 1rem 1rem .8rem 1.6rem;
    background-color: #fff;
}

.multiselect-native-select .multiselect:focus {
    box-shadow: 0px 3px 6px rgba(0,0,0,.25);
}

.multiselect-native-select .btn {
    font-size: 13px;
    text-align: left;
}

.multiselect-container {
    margin: 4px auto;
}

.multiselect-container>li>a>label {
    margin: 0;
    height: 100%;
    cursor: pointer;
    font-weight: 400;
    padding: 0.3rem 2rem;
    bottom: 100%;
    color: rgb(0, 0, 0);
}

.multiselect-native-select .btn-group {
    width: 100%;
}

.multiselect-native-select .btn-group ul.dropdown-menu {
    right: 0px;
}

.multiselect-native-select .btn-group ul.dropdown-menu .checkbox input {
    display: inline;
    width: auto;
    margin-right: 10px;
}

.multiselect.dropdown-toggle:hover {
    color: #222;
}

.multiselect.dropdown-toggle .multiselect-selected-text {
    display: inline-block;
    width: 95%;
}