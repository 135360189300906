.slanted-masthead.resources-slant.news-slant,
.slanted-masthead.resources-slant.news-slant::after {
  background-color: #f6f7fa;
}

.blog-post-header__container.blog-post-header__container--news .category-navigation__link.active{
  color: var(--healpay-pro-blue);
}

.blog-post-header__category.news-post-header__category {
  color: var(--healpay-pro-blue);
}

.blog-post-header__link--news:link,
.blog-post-header__link--news:visited {
  color: var(--healpay-pro-blue);
  transition: all .1s;
}

.blog-post-header__link--news:hover,
.blog-post-header__link--news:active {
  color: #707070;
}

.news__row {
  justify-content: center;
  margin: 0 2rem 5rem 2rem;
}

.news-item {
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
}

.news-item:not(:last-of-type) {
  border-bottom: 1px solid #00000029;
}

.blog-card__title--news {
  color: var(--healpay-pro-blue);
}

.blog-card__title--news-link:link,
.blog-card__title--news-link:visited {
  text-decoration: none;
}

.blog-navigation {
  padding-bottom: 10rem;
}

.related-post__type.related-post__type--news {
  color: var(--healpay-pro-blue);
}

.newsletter-signup--news.newsletter-signup--news {
  background-color: #A2D2FF;
}

@media screen and (min-width: 768px) {

  .blog-post-list__recent-posts {
    font-size: 1.8rem;
    margin-left: 5rem;
  }

  .news-item {
    flex-direction: row;
  }
  
  .blog-card__date--news {
    flex: 0 0 20%;
  }

}
