.header {
  position: fixed;
  top: -8px;
  width: 100%;
  z-index: 2000;
  padding-top: 8px;
}

.navbar {
  padding: .8rem 1.6rem;
}

a:link,
a:visited {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #212529;
}

a:hover {
  color: rgba(86, 219, 161, 1);
}

.header__logo {
  height: 5rem;
}

.nav-cta-container {
  display: initial;
}

.navbar-dropdown-menu-link {
  color: rgba(255, 255, 255, 1);
}

.navbar-dark .navbar-nav .main-nav-item a {
  color: rgba(255, 255, 255, 1);
}

.navbar-dark .navbar-nav .main-nav-item a:hover {
  color: rgba(86, 219, 161, 1);
}

.navbar-dark .navbar-nav .navbtn {
  color: rgba(162, 210, 255, 1)
}

.navbar-dark .navbar-nav .navbtn:hover {
  color: rgba(255, 255, 255, 1)
}

.navbar-dark .navbar-nav .nav-btn-item {
  margin: 0 1.6rem;
}

.nav-item {
  padding: 0 .56rem;
  text-align: center;
  cursor: pointer;
}

.btn:hover {
  color: #56DBA1;
}

ul.navbar-nav li.dropdown:hover > ul.hp-dropdown-menu-center {
  display: block;    
}

.main-dropdown-container {
  position: absolute;
  top: 73%;
  left: 0;
  padding-top: 3.2rem;
  background-color: rgba(0, 0, 0, 0);
  transition: opacity .5 ease-out;
  min-height: 3.2rem;
  display: block;
  float: left;
  width: 100vw;
}

.main-dropdown-container.show {
  opacity: 1;
}

.main-dropdown {
  position: absolute;
  max-width: min-content;
  background-color: white;
  top: 70%;
  display: none;
  border-radius: .4rem;
  left: 35%;
  z-index: 999;
  /* transition: max-width 1s ease-out; */
}

.hp-dropdown-menu {
  border: none;
  padding: 3.5rem 2rem 1rem 4.5rem;
  position: initial;
  display: none;
  float: left;
  text-align: left;
  list-style: none;
  background-color: transparent;
  height: 0;
  width: 0;
  opacity: 0;
  border-radius: .4rem;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.2);
}

.hp-dropdown-menu.show {
  display: block;
  /* transition: all 1s ease-out; */
  opacity: 1;
  width: auto;
  height: auto;
}

.dropdown-item {
  font-weight: 500;
  outline: none;
  margin-bottom: 3rem;
  padding: 0;
}

.dropdown-item:hover {
  background-color: initial;
  color: rgba(86, 219, 161, 1);
}

.why-healpay-dropdown {
  min-width: 30rem;
}

.products-dropdown {
  padding-bottom: 4rem;
  padding-left: 5rem;
  padding-right: 3rem;
}

.products-dropdown small {
  color: #C6C6C6;
  display: block;
  margin-bottom: 3.5rem;
  font-weight: 600;
  font-size: 1.4rem;
}

.products-dropdown .dropdown-item {
  display: inline;
  color: #383838;
}

.products-dropdown .dropdown-item:hover {
  color: #00ba82;
}

.products-dropdown .dropdown-item--pro:link,
.products-dropdown .dropdown-item--pro:visited {
  margin-left: 2.5rem;
  color: #1F3C84;
}

.products-dropdown .dropdown-item--pro:hover,
.products-dropdown .dropdown-item--pro:active {
  color: #707070;
}

.products-container {
  display: flex;
  min-width: max-content;
  margin: 0 auto;
  justify-content: space-between;
}

.dropdown-item--merchant-service--cont {
  border-top: 1px solid #e0e0e0;
  padding-top: 1rem;
  margin-top: -.75rem;
}

.dropdown-item--merchant-service:link,
.dropdown-item--merchant-service:visited {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
}

.products-dropdown .features {
  margin-right: 3rem;
}

.products-dropdown img, .products-dropdown a  {
  vertical-align: middle;
}

.products-dropdown .software img {
  margin-right: 2rem;
  width: 1.6rem;
}

.products-dropdown .features img {
  margin-right: 2rem;
  width: 1.6rem;
}

.products-dropdown .software div, .products-dropdown .features div {
  margin-bottom: 1.8rem;
}

.industries-dropdown {
  padding: 3.2rem 3.2rem 2rem 3.2rem;
  transition: display .3s ease-in-out;
}

.industries-container div {
  display: flex;
  margin-bottom: 2.2rem;
  justify-content: center;
}

.industries-container a {
  margin: 0 0 0 0;
}

.resources-dropdown {
  color: #383838;
  padding: 3.6rem 4.5rem 1rem 4.5rem;
  transition: display .3s ease-in-out;
  width: 19rem;
}

.resources-dropdown .dropdown-item {
  color: #383838;
}

.resources-dropdown .dropdown-item:hover {
  color: #00BA82;
}

.resources-dropdown div {
  padding-top: 2.4rem;
  border-top: .1rem #ccc solid;
}

.resources-dropdown p {
  margin: 0;
  color: #222;
  display: inline;
}

.resources-dropdown img, .resources-dropdown a {
  vertical-align: middle;
}

.resources-dropdown img {
  width: 1.6rem;
  margin-right: .5rem;
}

.dropdown-mailto {
  min-width: max-content;
}

.dropdown-mailto a {
  color: #383838;
  text-decoration: none;
}

.dropdown-mailto a:hover {
  color: rgba(86, 219, 161, 1);
}

.mobile-dropdown {
  width: 90%;
  background-color: white;
  min-width: 90%;
  z-index: 1052;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 2%;
  left: 5%;
  border-radius: 5px;
  padding: 4rem 6rem;
  box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.3);
  transition: all .2s ease-in-out;
}

.mobile-dropdown-active {
  visibility: visible;
  opacity: 1;
}

.mobile-close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  transition: all .2s;
}

.mobile-close-icon:hover {
  transform: rotate(90deg);
  cursor: pointer;
}

.mobile-dropdown .products-container {
  max-width: 500px;
  margin: 0 auto;
  justify-content: center;
  display: block;
}

.mobile-dropdown .software {
  margin-right: 3rem;
  margin-bottom: 3rem;
}

.mobile-dropdown .mobile-category {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: #C6C6C6;
}

.mobile-dropdown .dropdown-item-cont {
  margin-bottom: 1rem;
}

.mobile-dropdown .dropdown-icon {
  width: 1.5rem;
  margin-right: 1.5rem;
}

.mobile-dropdown .dropdown-item {
  font-size: 1.4rem;
  display: inline;
  margin: 0;
}

.mobile-dropdown .dropdown-item--pro {
  margin-left: 2rem;
}

.mobile-dropdown .software .dropdown-item:link,
.mobile-dropdown .software .dropdown-item:visited {
  color: #383838;
}

.mobile-dropdown .software .dropdown-item:hover,
.mobile-dropdown .software .dropdown-item:active {
  color: #C6C6C6;
}

.mobile-dropdown .software .dropdown-item--pro:link,
.mobile-dropdown .software .dropdown-item--pro:visited {
  color: #1F3C84;
}

.mobile-dropdown .software .dropdown-item--pro:hover,
.mobile-dropdown .software .dropdown-item--pro:active {
  color: #C6C6C6;
}

.mobile-dropdown .features .dropdown-item:link,
.mobile-dropdown .features .dropdown-item:visited {
  color: #989898;
}

.mobile-dropdown .features .dropdown-item:hover,
.mobile-dropdown .features .dropdown-item:active {
  color: #00BA82;
}

.mobile-dropdown .dropdown-item--merchant-service--cont {
  border: unset;
}

.mobile-dropdown .software .dropdown-item--merchant-service:link,
.mobile-dropdown .software .dropdown-item--merchant-service:visited {
  font-size: 1rem;
  color: black;
}

.mobile-dropdown .software .dropdown-item--merchant-service:hover,
.mobile-dropdown .software .dropdown-item--merchant-service:active {
  color: #C6C6C6;
}

.mobile-add-links-cont {
  margin: 4rem auto;
  max-width: 70%;
  display: flex;
  justify-content: space-evenly;
}

.mobile-add-links-cont .dropdown-item-cont {
  display: block;
  max-width: max-content;
}

.mobile-add-links-cont .dropdown-item:link,
.mobile-add-links-cont .dropdown-item:visited {
  color: #989898;
}

.mobile-add-links-cont .dropdown-item:hover,
.mobile-add-links-cont .dropdown-item:active {
  color: #00BA82;
}

.mobile-add-links_col {

}

.mobile-add-links_col--1 {
  margin-right: 1rem;
}

.mobile-add-links_col--2 {
  margin-left: 1rem;
}

.mobile-button-cont {
  text-align: center;
  margin-top: 5rem;
}

.hp-btn.hp-btn--mobile-1, .hp-btn.hp-btn--mobile-2 {
  min-width: 12rem;
}

.hp-btn.hp-btn--mobile-1 {
}

.hp-btn.hp-btn--mobile-2 {
  color: #00BA82;
  background-color: #FFF;
  margin-left: 1rem;
  display: none;
}

@media (min-width: 400px) {

  .mobile-dropdown {
    padding: 4rem 2.5rem;
  }

  .mobile-dropdown .products-container {
    display: flex;
  }

  .mobile-dropdown .software {
    margin-bottom: 0;
  }

  .hp-btn.hp-btn--mobile-1 {
    margin-right: 1rem;
  }
  
  .hp-btn.hp-btn--mobile-2 {
    display: inline-block;
  }
}

@media (min-width: 575px) {
  
  .mobile-dropdown .software {
    margin-right: 6rem;
  }
  
  .mobile-dropdown .dropdown-item {
    font-size: 1.6rem;
  }
}

@media (min-width: 768px) {

  .hp-dropdown-menu {
      text-align: center;
      background-color: rgba(255, 255, 255, 1);
  }

  .navbar-dark .navbar-nav .main-nav-item a {
      color: rgba(255, 255, 255, 1);
  }

  .navbar-dark .navbar-nav .hp-dropdown-menu .dropdown-item {
      color: rgba(56, 56, 56, 1);
  }

  .nav-cta-container {
      display: flex;
      margin-left: 1rem;
  }

  .nav-item {
      /* margin: 0 .35rem; */
  }

  .nav-item a {
      font-size: 1.5rem;
  }

  .navbar-dark .navbar-nav .nav-btn-item {
      margin: 0;
  }

  .hp-dropdown-menu {
      border: none;
      text-align: left;
  }

  .main-dropdown::after, .main-dropdown::before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
  }

   

  .main-dropdown::before {
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: #FFFFFF;
      border-width: 1rem;
      margin-left: -1rem;
  }

}

@media (min-width: 992px) {

  .navbar-dark .navbar-nav .main-nav-item a {
      color: rgba(255, 255, 255, 1);
  }
  
  .navbar-dark .navbar-nav .main-nav-item a:hover {
      color: rgba(86, 219, 161, 1);
  }

  .navbar-dark .navbar-nav .hp-dropdown-menu .dropdown-item {
      color: rgba(56, 56, 56, 1);
  }

  .nav-item {
      padding: 0 1.6rem;
  }

  .nav-item a {
      font-size: 1.6rem;
  }

  .nav-cta-container {
      margin-left: 2.56rem;
  }

  .hp-dropdown-menu {
      text-align: initial;
      background-color: rgba(255,255,255,1);
  }
  
}
