.slanted-masthead.slanted-masthead--auto-finance {
  height: 43rem;
}

.industries-hero__sub-heading.industries-hero__sub-heading--auto-finance {
  margin-bottom: 2rem;
}


/* BENEFITS SECTION */

.auto-finance-benefits {
  margin-bottom: 8rem;
}

.auto-finance-benefits__row {
  justify-content: center;
}

.auto-finance-benefits__heading {
  color: #1F3C84;
  margin-bottom: 5rem;
  width: 90%;
  font-size: 2.8rem;
}

.auto-finance-benefits__heading-2 {
  color: #1F3C84;
  margin-bottom: 5rem;
  font-size: 2.3rem;
  line-height: 2.7rem;
}

.auto-finance-benefits__snippet {
  font-size: 2rem;
}

.auto-finance-benefits__snippet:not(:last-of-type) {
  margin-bottom: 4rem;
}

.auto-finance-benefits__img-cont {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.auto-finance-benefits__img {
  width: 100vw;
  height: auto;
  align-self: flex-start;
}

.auto-finance-benefits__img-2 {
  border-radius: .7rem;
  display: none;
}


/* FEATURES SECTION */

.auto-finance-features {
  margin-top: 10rem;
}

.auto-finance-features__heading {
  color: #1F3C84;
  margin-bottom: 5rem;
  width: 90%;
  font-size: 2.8rem;
}

.auto-finance-features__snippet {
  font-size: 2rem;
  line-height: 2.7rem;
}

.auto-finance-features__snippet:not(:last-of-type) {
  margin-bottom: 4rem;
}

.auto-finance-features__img-cont {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.auto-finance-features__img {
  width: 100vw;
  align-self: flex-start;
  height: auto;
}

/* ADDITIONAL FEATURES SECTION */

.slanted-section.auto-finance-cards__section {
  height: unset;
  padding: 10rem 0 1rem 0;
  margin: 0;
  background-color: #f6f7fa;
  transform: skewY(-4deg);
}

.auto-finance-cards__container {
  transform: skewY(4deg);
}

.auto-finance-cards__header {
  color: #1F3C84;
  margin-bottom: 2rem;
  font-size: 2.4rem;
}

.auto-finance-cards__row {
  margin: 3rem 0 5rem 0;
  justify-content: center;
}


/* CONTACT SALES SECTION */

.contact-sales__header--char-orgs {
  color: #1F3C84;
}

.contact-sales__btn--char-orgs {
  background-color: #1F3C84;
}


@media (min-width: 575px) {

  .slanted-masthead.slanted-masthead--auto-finance {
    height: 37rem;
  }

  .auto-finance-benefits__img {
    width: 100%;
    margin-left: 0;
    border-radius: .7rem;
  }

  .auto-finance-features__img {
    width: 100%;
    margin-left: 0;
    border-radius: .7rem;
  }  

}


@media (min-width: 768px) {

  .auto-finance-benefits__img {
    width: 80%;
  }

  .auto-finance-cards__row {
    margin-left: -1.5rem;
    margin-right: -1.5rem;    
  }

  .auto-finance-cards__header {
    font-size: 2.6rem;
  }

  .auto-finance-features__row {
    justify-content: center;
  }

  .auto-finance-features__img-cont {
    display: flex;
    justify-content: center;
  }

  .auto-finance-features__img {
    width: 80%;
  }

}

@media (min-width: 992px) {

  .slanted-masthead.slanted-masthead--auto-finance {
    height: 43rem;
  }

  .industries-hero__heading.industries-hero__heading--auto-finance {
    width: 105%;
  }

  .auto-finance-benefits {
    margin-top: -5rem;
  }

  .auto-finance-benefits__img-cont {
    position: relative;
  }

  .auto-finance-benefits__img {
    right: -25rem;
    height: 35%;
    top: -10rem;
    position: absolute;
    width: unset;
  }

  .auto-finance-benefits__img-2 {
    right: -25rem;
    height: 35%;
    top: 72rem;
    position: absolute;
    display: block;
  }
  .auto-finance-img-fluid__3 {
    max-width: 77rem;
    height: 38rem;
  }

  .auto-finance-cards__row {
    justify-content: left;
  }

  .auto-finance-cards__header {
    font-size: 2.6rem;
  }

  .auto-finance-cards__snippet {
    width: 65%;
    font-size: 2rem;
  }

  .auto-finance-features__img {
    position: absolute;
    top: 0rem;
    right: -20rem;
    height: 100%;
    width: unset;
  }
}

@media (min-width: 1200px) {

  .auto-finance-benefits {
    margin-top: -8rem;
  }

  .auto-finance-benefits__img {
    height: 40rem;
    right: -21rem;
    top: -10rem;
  }

  .auto-finance-benefits__img-2 {
    height: 40rem;
    right: -21rem;
    top: 50rem;
  }

  .auto-finance-cards__row {
    justify-content: center;
  }

  .auto-finance-features__img {
    right: -12rem;
    height: 90%;
    top: -2rem;
    height: 100%
  }

  .industries-hero__heading-container.industries-hero__heading-container--auto-finance {
    width: 60%
  }

}

@media screen and (min-width: 1500px) {
  .auto-finance-features__img {
    position: static;
    margin-left: 10rem;
    margin-top: 1rem;
    width: 110%;
  }
}