.software-hero--portal-pro .category-navigation__link.active:link,
.software-hero--portal-pro .category-navigation__link.active:visited {
  color: #1F3C84;
}

.software-hero--portal-pro .category-navigation__link:hover,
.software-hero--portal-pro .category-navigation__link:active {
  color: #1F3C84;
}

.software-hero--portal-pro .category-navigation__link.active {
  color: #1F3C84;
}

.software-hero--portal-pro .category-navigation__link {
  color: #1F3C84;
}

.portal-pro-hero__image {
  position: absolute;
  top: 4rem;
  right: 1rem;
  width: 75%;
}

.portal-pro-benefits__sub-heading {
  color: #0071DC;
}

.portal-pro-features__section {
  transform: skewY(-3deg);
  padding: 10rem 0 1rem 0;
  margin-top: 8rem;
}

.portal-pro-features__container {
  transform: skewY(3deg);
}

.portal-pro-features__header {
  color: #0071DC;
  margin-bottom: 2rem;
  font-weight: 600;
}

.portal-pro-features__section {
  background-color: #f0f7fd;
}

.portal-pro-features__row {
  margin-bottom: 5rem;
  justify-content: center;
}

.portal-pro-features__sub-heading {
  font-weight: 500;
  color: rgba(0, 186, 130, 1);
  margin-bottom: 1.5rem;
  width: 100%;
  font-size: 2.4rem;
}

.portal-pro-features__snippet-lower--1 {
  margin-bottom: 6rem;
}

.portal-pro-features__snippet-lower--2 {
  margin-bottom: 3rem;
}

.portal-pro-features__snippet-lower--3 {
  font-style: italic;
}

.portal-pro__testimonial-card {
  background-color: #fff;
  padding: 2.5rem 2rem;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 .3rem .5rem rgba(0,0,0,.1);
  border-radius: .5rem;
  width: 98%;
  margin: 0 auto;
  display: none;
}

.portal-pro__testimonial-card-title {
  color: var(--healpay-pro-blue);
  font-size: 1.4rem;
}

.portal-pro__testimonial-card-icon {
  width: 2rem;
}

.portal-pro__testimonial-card-img {
  width: 3.8rem;
  margin: 0 0.5rem;
}

.portal-pro-features__feature {
  margin-top: 8rem;
}

.portal-pro-features__header-row {
  justify-content: center;
}

.portal-pro-features__image-mobile-row {
  margin-top: 4rem;
  justify-content: center;
}

.portal-pro-features__image-mobile-container {
  display: flex;
  justify-content: center;
}

.portal-pro-features__image-mobile {
  width: 90%;
}

.portal-pro-features__image {
  width: 55rem;
  position: relative;
  display: none;
}

.portal-pro__card-section {
  margin-top: 10rem;
  margin-bottom: 5rem;
}

.portal-pro__product-benefits-row--adjust {
  justify-content: left;
}

.portal-pro__card-group {
  margin-bottom: 2rem;
}

.portal-pro__card-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.portal-pro__card {
  width: 100%;
  max-width: 30rem;
  padding: 2rem;
  height: 27rem;
  transition: all .2s;
  margin-bottom: 2rem;
}

.portal-pro__card:hover {
  transform: translateY(-4%);
}

.portal-pro__card-title {
  color: #1F3C84;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 2.5rem;
}

.portal-pro__card-1 {
  background-color: #f0f7fd;
}

.portal-pro__card-2 {
  background-color: #d6f4eb;
}

.portal-pro__card-3 {
  background-color: #f2f2f2;
}

.portal-pro__card-4 {
  background-color: #fdfaf0;
}

.portal-pro__card-text {
  margin: 4rem 0;
}

.portal-pro__card-button {
  display: flex;
  justify-content: center;
}

.payment-pro-features__row:not(:last-of-type) {
  margin-bottom: 5rem;
}

.payment-pro-features__image {
  margin-top: .5rem;
  width: 90%;
}

@media (min-width: 400px) {

  .portal-pro-hero__image {
    position: absolute;
    top: 5rem;
    right: 2rem;
    width: 33rem;
  }

}

@media (min-width: 575px) {

  .hero-heading--portal-pro {
    width: 80%;
  }

  .portal-pro-hero__image {
    position: absolute;
    top: 39rem;
    right: 5%;
    width: 36rem;
  }

  .portal-pro__testimonial-card {
    display: flex;
  }

  .portal-pro__card {
    padding: 2rem;
    height: 27rem;
    transition: all .2s;
  }

  .payment-pro-features__image {
    width: 30rem;
  }

}

@media (min-width: 768px) {

  .hero-heading--portal-pro {
    width: 100%;
  }

  .portal-pro-hero__image {
    position: absolute;
    top: 39.5rem;
    right: 8%;
    width: 43rem;
  }

  .portal-pro-features__image-mobile {
    width: 60%;
  }

  .portal-pro__testimonial-card {
    padding: 2.5rem 2rem;
    width: 60rem;
  }  

  .portal-pro__testimonial-card-title {
    color: var(--healpay-pro-blue);
    font-size: 1.8rem;
  }
  
  .portal-pro__testimonial-card-img {
    width: 4rem;
    margin: 0 0.5rem;
    transition: all .2s;
    border-radius: 50%;
  }

  .portal-pro__testimonial-card-img:hover {
    transform: scale(1.05);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,0.3);
  }
  .portal-pro-features__sub-heading {
    font-weight: 400;
    color: rgba(0, 186, 130, 1);
    margin-bottom: 1.5rem;
    width: 70%;
    font-size: 2.4rem;
  }

}

@media (min-width: 992px) {

  .portal-pro-hero__image {
    position: absolute;
    top: 36rem;
    right: 3%;
    width: 45rem;
  }

  .portal-pro-features__image-mobile {
    display: none;
  }

  .portal-pro-features__header-row {
    justify-content: flex-start;
  }

  .portal-pro-features__header-container {
    padding-bottom: 5rem;
    border-bottom: 1px solid #ccc;
  }

  .portal-pro-features__row {
    justify-content: flex-start;
    margin-bottom: 10rem;
  }

  .portal-pro-features__image {
    display: block;
    left: 7rem;
    top: 8rem;
    width: 45rem;
  }
  
  .portal-pro-features__sub-heading {
    margin-bottom: 2rem;
    width: unset;
  }

  .portal-pro__testimonial-card {
    padding: 2.5rem 3rem;
    justify-content: space-between;
    width: 72rem;
  }

  .portal-pro__testimonial-card-title {
    font-size: 2rem;
  }

  .portal-pro__testimonial-card-img {
    width: 6rem;
    margin: 0 1rem;
  }

  .portal-pro__card {
    width: 24%;
    margin-bottom: 0  ;
  }

  .portal-pro__card-title {
    font-size: 2.2rem;
  }

  .portal-pro-features__section {
    background-color: #f0f7fd;
  }

  .portal-pro-features__header {
    font-size: 3.5rem;
  }

  .payment-pro__features {
    margin-top: 10rem;
  }

  .payment-pro-features__row {
    justify-content: space-between;
  }

  .payment-pro-features__image {
    margin-top: 2rem;
    width: 30rem;
  }

  .portal-pro__card-row {
    flex-direction: row;
  }

  .portal-pro__card-group {
    margin-bottom: 4rem;
  }

}

@media (min-width: 1200px) {

  .portal-pro-hero__image {
    position: absolute;
    top: 27rem;
    right: 5%;
    width: 55rem;
  }

  .portal-pro__row {
    justify-content: space-between;
  }

  .portal-pro-features__image {
    display: block;
    left: 7rem;
    top: -8rem;
    width: 55rem;
  }

  .portal-pro__card-row {
    margin: 0 -3rem;
  }

}

@media (min-width: 1250px) {

  .portal-pro-hero__image {
    position: absolute;
    top: 27rem;
    right: 5%;
    width: 55rem;
  }

  .portal-pro__row {
    margin-left: -6rem;
    margin-right: -6rem;
  }

}

@media (min-width: 1400px) {

  .portal-pro-hero__image {
    right: 7%;
  }

}

@media (min-width: 1500px) {

  .portal-pro-hero__image {
    right: -40%;
    top: 0;
  }
}
