.contact-sales__container {
  justify-content: center;
}

.contact-sales {
  margin: 0 2rem;
}

.contact-sales__snippet {
  font-size: 1.8rem;
  margin-bottom: 4rem;
}

.contact-sales__section {
  padding: 15rem 0; 
}

.contact-sales__header {
  color: rgba(0, 186, 130, 1);
  margin-bottom: 1.5rem;
}

.contact-sales__button {
  width: 25%;
  float: left;
  margin-left: 50%;
  transform: translate(-50%, -.3rem);
  background-color: rgba(0, 186, 130, 1);
}

.contact-sales__button:hover {
  transform: translate(-50%, -5px);
}

.contact-sales__button:active {
  transform: translate(-50%, -5px);
}

@media (min-width: 400px) {
  .contact-sales {
    margin: 0 2rem;
  }
}

@media (min-width: 992px) {
  .contact-sales__container {
    justify-content: center;
  }

  .contact-sales__snippet {
    width: 75%;
    float: left;
    font-size: 1.8rem;
    padding-right: 3rem;
    margin-bottom: 1rem;
  }

  .contact-sales__button {
    margin-left: 0;
    transform: none;
    background-color: #0071DC;
  }

  .contact-sales__button:hover {
    transform: translateY(-.5rem);
  }
  
  .contact-sales__button:active {
    transform: translateY(-.5rem);
  }
}

@media (min-width: 992px) {
  .contact-sales__snippet {
    width: 70%;
  }
}