.career-listing-slant {
    background-color: #fbfbfb;
    clip-path: polygon(0% 0%, 100% 0, 100% 66%, 0% 100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 88vh;
    width: 100%;
    z-index: -1;
}

.career-listing__container {
    margin: 10rem 2rem 30rem 2rem;
}

.career-listing__header {
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 3rem;
}

.career-listing__title {
    margin-bottom: 2rem;
}

.career-listing__header, 
.career-listing__title {
    color: var(--healpay-pro-blue);
}

.career-listing__description {
    width: 90%;
}

.career-listing__row {
    margin-top: 6rem;
    display: none;
}

.career-listing__row--mobile {
    margin: 5rem 0;
}

.career-listing__row--mobile .career-listing__form--element {
    margin-bottom: 2rem;
}

.career-listing__apply-header {
    color: var(--healpay-pro-blue);
    margin-bottom: 2rem;
}

.career-listing__row h3 {
    color: var(--healpay-pro-blue);
    font-family: 'Metric Web-Bold', Helvetica, Arial, Lucida, sans-serif;
}

.career-listing__row h4 {
    color: var(--healpay-pro-blue);
}

.career-listing__dropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: .3rem;
    border: 1px solid #dfdfdf;
    padding: .5rem;
    font-size: 1.4rem;
    color: var(--healpay-pro-blue);
    box-shadow: 0 0 0.5rem rgba(0,0,0,.2);
}

.career-listing__dropdown > option {
    color: var(--healpay-pro-blue);
    padding: .3rem 0;
}

.career-listing__menu-container {
    position: absolute;
    left: 1rem;
    top: -7.5rem;
}

.career-listing__quals--required {
    margin-bottom: 5rem;
}

.career-listing__quals__header {
    color: var(--healpay-pro-blue);
    margin-bottom: 2rem;
}

.career-listing__quals-section {
    margin-top: 5rem;
}

.career-listing__quals-section ul {
    padding-left: 1.5rem;
}

.career-listing__quals__list-item {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
}

.career-listing__form {
    width: 100%;
}

.career-listing__form--row-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem; 
}

.career-listing__form--row-1, .career-listing__form--row-2 {
    display: flex;
    flex-direction: row;
}

.career-listing__form--row-1 {
    margin-bottom: 2rem;
    justify-content: space-between;
}

.career-listing__form--row-input-cont {
    width: 48%;
}

.career-listing__form--row-2 {
    align-items: flex-end;
    justify-content: space-between;
}

.career-listing__form-label {
    font-size: 1.4rem;
    margin: 0;
}

.career-listing__form--attach-files {
    margin-right: 1rem;
    font-size: 1.3rem;
}

.career-listing__input {
    border-radius: 3px;
    box-shadow: 0px 3px 6px #00000029;
    transition: all .1s ease;
    border: 4px transparent;
    padding: 9px 11px;
    font-size: 1.3rem;
    line-height: 0;
}

.career-listing__form--row-2 .career-listing__input--1 {
    padding-right: .5rem;
}

.career-listing__form h2 {
    color: #000000;
}

.hp-btn.hp-btn--career-listing {
    background-color: var(--healpay-pro-blue);
    min-width: unset;
    padding: .7rem 1rem;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 0;
    cursor: pointer;
    border: none;
    color: var(--healpay-pro-blue);
    white-space: nowrap;
    font-size: 1.6rem;
    margin: 0;
    transition: all .1s;
}

.custom-file-upload:hover {
    color: #315FD3;

}

.file-upload-cont {
    display: flex;
    align-items: first baseline;
}

.upload-filename {
    display: inline;
    margin-left: 10px;
    font-size: 1.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

input[type="file"] {
    display: none;
    border: none;
    width: max-content;
    outline: none;
}

@media screen and (min-width: 575px) {
    .career-listing__menu-container {
        top: 0;
        right: -2.5rem;
        left: unset;
    }
}

@media screen and (min-width: 768px) {

    .career-listing__container {
        margin-right: 6rem;
    }
}


@media screen and (min-width: 992px) {

    .career-listing__container {
        margin: 0 auto;
        margin-top: 15rem;
        margin-bottom: 30rem;
    }

    .career-listing__desc-section {
        padding-right: 10rem;
        border-right: 1px solid #ddd;
    }

    .career-listing__quals-section {
        margin-top: 0;
        padding-left: 10rem;
    }

    .career-listing__row {
        display: block;
    }

    .career-listing__row--mobile {
        display: none;
    }

    .career-listing__menu,
    .career-listing__menu ul {
        width: 17rem;
    }


    .career-listing__menu-container {
        right: 1rem;
    }

    .career-listing__form--row-2 {
        display: flex;
    }

    .career-listing__form--attach-files {
        padding: 2rem 0 1rem 0;
        display: block;
    }

    .career-listing__form--row-3 {
        display: flex;
        justify-content: flex-end;
        margin-top: 3rem;
    }
    
    .custom-file-upload {
        font-size: 1.4rem;
    }

    .upload-filename {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1200px) {
    .career-listing__menu-container {
        right: 5rem;
    }

    .career-listing__form--attach-files {
        display: inline;
        padding: 0;
    }

    .career-listing__menu,
    .career-listing__menu ul {
        width: 20rem;
    }

    .custom-file-upload {
        font-size: 1.6rem;
    }

    .upload-filename {
        font-size: 1.2rem;
    }
}

