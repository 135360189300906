.category-navigation {
  display: none;
  margin: 4rem 0 8rem 0;
  font-weight: 500;
}

.category-navigation__group {
  text-transform: uppercase;
  color: #C6C6C6;
  margin-bottom: 2rem;
  font-size: 1.4rem;
}

.category-navigation__link:hover {
  text-decoration: none;
}

.category-navigation__link:not(:last-child) {
  margin-right: 6rem;
}

.category-navigation__link.active {
  color: #00BA82;
  background-color: rgba(255,255,255,.9);
  border-radius: 25px;
  box-shadow: 0 .35rem .5rem rgba(0,0,0,.2);
  padding: .5rem 2rem;
  transform: translateY(-.5rem);
  transition: all .1s;
}

.category-navigation__link.active:hover {
  box-shadow: 0 .5rem .7rem rgba(0,0,0,.2);
  transform: translateY(-.65rem);
}

.category-navigation__link.active:active {
  box-shadow: 0 .4rem .55rem rgba(0,0,0,.2);
  transform: translateY(-.55rem);
}

.category-navigation__link:hover {
  color: #00BA82;
}

@media (min-width: 992px) {
  .category-navigation {
    display: block;
  }

  .features-hero__container .category-navigation__link:not(:last-child) {
    margin-right: 5rem;
  }
}

@media (min-width: 1200px) {
  .category-navigation__link:not(:last-child) {
    margin-right: 10rem;
  }
}