.slanted-masthead.slanted-masthead--property-management {
  height: 43rem;
}

.industries-hero__sub-heading.industries-hero__sub-heading--property-management {
  margin-bottom: 4rem;
}

/* BENEFITS SECTION */

.property-management-benefits {
  margin-bottom: 8rem;
}

.property-management-benefits__heading {
  color: #1F3C84;
  margin-bottom: 3rem;
  width: 85%;
  font-size: 2.4rem;
}

.property-management-benefits__snippet {
  font-size: 1.6rem;
  line-height: 1.3;
}

.property-management-benefits__snippet:not(:last-of-type) {
  margin-bottom: 4rem;
}

.property-management-benefits__img-cont {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.property-management-benefits__img {
  width: 100vw;
  height: auto;
  align-self: flex-start;
}


/* FEATURES SECTION */

.property-management-features {
  margin-top: 5rem;
}

.property-management-features__heading {
  color: #1F3C84;
  margin-bottom: 3rem;
  width: 85%;
  font-size: 2.4rem;
}

.property-management-features__snippet {
  font-size: 1.6rem;
  line-height: 1.3;
}

.property-management-features__snippet:not(:last-of-type) {
  margin-bottom: 4rem;
}

.property-management-features__img-cont {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.property-management-features__img {
  width: 100vw;
  height: auto;
  align-self: flex-start;
}

/* ADDITIONAL FEATURES SECTION */

.slanted-section.property-management-cards__section {
  height: unset;
  padding: 10rem 0 5rem 0;
  margin: 0 0 5rem 0;
  background-color: #f6f7fa;
  transform: none;
  transform: skewY(-4deg);
}

.property-management-cards__container {
  transform: skewY(4deg);
}

.property-management-cards__header {
  color: #1F3C84;
  margin-bottom: 2rem;
}

.property-management-cards__row {
  margin: 3rem 0 5rem 0;
  justify-content: center;
}

/* CONTACT SALES SECTION */

.contact-sales__header--char-orgs {
  color: #1F3C84;
}

.contact-sales__btn--char-orgs {
  background-color: #1F3C84;
}

@media (min-width: 400px) {

  .slanted-masthead.slanted-masthead--property-management {
    height: 37rem;
  }

  .industries-hero__sub-heading.industries-hero__sub-heading--property-management {
    margin-bottom: 8rem;
  }

}

@media (min-width: 575px) {

  .slanted-masthead.slanted-masthead--property-management {
    height: 37rem;
  }

  .industries-hero__sub-heading.industries-hero__sub-heading--property-management {
    width: 100%;
  }

  .property-management-benefits__img {
    width: 100%;
    border-radius: .7rem;
  }

  .property-management-features__img {
    width: 100%;
    border-radius: .7rem;
  }

  .property-management-cards__header {
    width: 90%;
  }

}

@media (min-width: 768px) {

  .industries-hero__sub-heading.industries-hero__sub-heading--property-management {
    margin-bottom: 6rem;
    width: 80%;
  }

  .property-management-benefits__row {
    justify-content: center;
    margin-left: -1.5rem;
    margin-right: -1.5rem;    
  }

  .property-management-benefits__img-cont {
    display: flex;
    justify-content: center;
  }

  .property-management-benefits__img {
    width: 80%;
  }

  .slanted-section.property-management-cards__section {
    margin: 0 0 10rem 0;
  }

  .property-management-cards__header {
    font-size: 2.8rem;
  }  

  .property-management-cards__snippet {
    width: 75%;
  }  

  .property-management-features__row {
    justify-content: center;
  }

  .property-management-features__img-cont {
    display: flex;
    justify-content: center;
  }

  .property-management-features__img {
    width: 80%;
  }

  .property-management-cards__header {
    width: 85%;
  }

}

@media (min-width: 992px) {

  .slanted-masthead.slanted-masthead--property-management {
    height: 43rem;
  }

  .property-management-benefits {
    margin-top: -10rem;
  }

  .property-management-benefits__row {
    justify-content: flex-start;
  }

  .property-management-benefits__heading {
    width: 85%;
    font-size: 2.8rem;
  }

  .property-management-benefits__header {
    font-size: 2.8rem;
  }
  
  .property-management-benefits__snippet {
    font-size: 2rem;
  }

  .property-management-benefits__img-cont {
    position: relative;
    display: block;
  }

  .property-management-benefits__img {
    position: absolute;
    top: -12rem;
    right: -20rem;
    height: 100%;
    width: unset;
    margin-top: 0;
  }

  .slanted-section.property-management-cards__section {
    margin: 0 0 15rem 0;
  }

  .property-management-cards__header {
    width: 75%;
  }

  .property-management-cards__snippet {
    width: 70%;
  }

  .property-management-features__snippet {
    font-size: 2rem;
  }

  .property-management-features__img {
    font-size: 2.8rem;
  }

  .property-management-features__img-cont {
    justify-content: flex-start;
  }

  .property-management-features__img {
    position: absolute;
    top: -4rem;
    right: -18rem;
    height: 110%;
    width: unset;
    margin-top: 0;
  }

}

@media (min-width: 1200px) {

  .industries-hero__sub-heading.industries-hero__sub-heading--property-management {
    margin-bottom: 6rem;
  }

  .slanted-masthead.slanted-masthead--property-management {
    height: 40rem;
  }

  .industries-hero__heading-container.industries-hero__heading-container--property-management {
    width: 60%
  }

  .property-management-benefits__img {
    right: -15rem;
    height: 120%;
    position: absolute;
    top: -20rem;
    width: unset;
  }

  .property-management-cards__header {
    width: 50%;
  }

  .property-management-cards__snippet {
    width: 55%;
  }

  .property-management-features__img {
    position: absolute;
    top: -7rem;
    right: -10rem;
    height: 130%;
    width: unset;
    margin-top: 0;
  }

}