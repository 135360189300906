.industry-cards__row {
  justify-content: center;
  margin-right: 1rem;
  margin-left: 1rem;
}

.industry-cards__card {
  margin-top: 4rem;
  border-radius: 5px;
  min-height: 35rem;
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.2);
  transition: all .2s;
}

.industry-cards__card--collection-attorneys {
  min-height: 20rem;
}

.industry-cards__card:hover {
  box-shadow: 0 0.7rem 1.2rem rgba(0,0,0,.3);
  transform: scale(1.02);
  background-color: white;
}

.industry-cards__link:link,
.industry-cards__link:visited {
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 3.5rem 2rem;
  text-decoration: none;
}

.industry-cards__link--collection-attorneys:link,
.industry-cards__link--collection-attorneys:visited {
  padding: 3rem 2rem 1rem 2rem;
}

.industry-cards__link:hover,
.industry-cards__link:active {
  color: rgb(33, 37, 41);
}

.industry-cards__product-cont:hover {
  transition: all .2s;
}

.industry-cards__svg {
  float: left;
  margin-right: 2rem;
}

.industry-cards__svg-path-a {
  fill: none;
}

.industry-cards__svg-path-b {
  fill: #1F3C84;
}

.industry-cards__card:hover .industry-cards__product {
  color: #0071DC;
  transition: all .3s;
}

.industry-cards__card:hover .industry-cards__svg-path-b {
  fill: #0071DC;
  transition: all .3s;
}

.industry-cards__product {
  font-weight: 700;
  text-transform: uppercase;
  line-height: 2.5rem;  
  font-size: 2.25rem;
  margin-bottom: 2rem;
  color: #1F3C84;
  transition: all .2s;
  white-space: nowrap;
}

.industry-cards__sub-header {
  margin-bottom: 3rem;
}

.industry-cards__point {
  color: #1F3C84;
  margin-bottom: 1rem;
}

@media screen and (min-width: 400px) {
  .industry-cards__link:link,
  .industry-cards__link:visited {
    padding: 3.5rem 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  
  .industry-cards__card {
    box-shadow: unset;
    background-color: transparent;
  }

  .industry-cards__card:hover {
    transform: translateY(-2rem);
  }

}

@media screen and (min-width: 992px) {
  .industry-cards__row {
    justify-content: center;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }

  .industry-cards__link--collection-attorneys:link,
  .industry-cards__link--collection-attorneys:visited {
    padding: 3rem 2rem;
  }
  
}

