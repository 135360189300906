.payment-reminders__header-image {
    width: 85%;
    margin-top: 7rem;
    float: right;
}

.payment-reminders-benefits__section {
    margin: 10rem 0;
}

.payment-reminders-features__sub-heading {
    color: rgba(0, 186, 130, 1);
    margin-bottom: 0;
    font-weight: 500;
}

.payment-reminders-features__sub-heading--2 {
    width: 80%;
}

.payment-reminders-features__snippet {
    margin-bottom: 4rem
}

.payment-reminders-features__feature-img--3 {
    width: 80vw;
    margin-top: -5rem;
    margin-left: -2rem;
}

.payment-reminders-features__feature--2 {
    border-bottom: solid 1px #989898
}

.payment-reminders-features__sub-heading--3 {
    margin-bottom: 3rem;
}

@media (min-width: 575px) {

    .payment-reminders__header-image {
        width: 72%;
        margin-top: -3rem;
        margin-right: -4rem;
    }

    .payment-reminders-features__feature-img--3 {
        width: 70vw;
        margin-left: -2rem;
    }

}

@media (min-width: 768px) {

    .payment-reminders__header-image {
        width: 72%;
        margin-top: -3rem;
        margin-right: -4rem;
    }

    .payment-reminders-features__feature-img--3 {
        width: 65vw;
        margin-top: 0;
        margin-left: 9rem;
    }
    .payment-reminders-features__sub-heading {
        color: rgba(0, 186, 130, 1);
        margin-bottom: 0;
        font-weight: 400;
    }

}

@media (min-width: 992px) {

    .payment-reminders__header-image {
        width: 80%;
        margin-top: 3rem;
        margin-left: 5rem;
    }

    .payment-reminders-features__feature {
        margin-left: 4rem;
        padding: 0;
    }

    .payment-reminders-features__feature--1 {
        margin-bottom: 8rem;
    }

    .payment-reminders-features__feature--2 {
        padding-bottom: 5rem;
        margin-bottom: 8rem;
    }

    .payment-reminders-benefits__section {
        margin: 18rem 16rem 10rem 16rem;
    }

    .payment-reminders-features__feature-img--3 {
        width: 55vw;
        margin-top: 0;
        margin-left: 2rem;
    }

}

@media (min-width: 1200px) {

    .hero-sub-heading--payment-reminders {
        width: 80%;
    }

    .payment-reminders__header-image {
        width: 90%;
        margin-top: 2rem;
        margin-left: 10rem;
    }

    .payment-reminders-features__feature--3rd {
        display: flex;
    }

    .payment-reminders-features__feature-img {
        margin-left: 3rem;
    }

    .payment-reminders-features__sub-heading--2 {
        width: unset;
    }

    .payment-reminders-features__sub-heading--3 {
        width: 50%;
        font-size: 2.8rem;
        margin-bottom: 3rem;
    }

    .payment-reminders-features__feature-img--3 {
        margin-top: 1rem;
        margin-left: -6rem;
        width: 50rem;
    }

    .payment-reminders-features__snippet {
        width: 75%;
    }

    .payment-reminders-features__feature-img--3 {
        margin-top: 4rem;
        margin-left: -10rem;
        width: 45rem;
    }

}

