.hp-btn--cta-text:link,
.hp-btn--cta-text:visited {
  padding: 0;
  text-align: left;
  color: #0071DC;
}

.hp-btn--cta-arrow {
  padding-left: 2rem;
  height: 1.6rem;
  margin-top: -1px;
}

.hp-btn--cta-text:link,
.hp-btn--cta-text:visited {
  min-width: max-content;
  color: #0071DC;
  padding: 0;
  margin-top: .75rem;
  width: 16rem;
  height: 3rem;
  display: block;
  text-align: left;
  background-image: url(/assets/img/software/virtual-terminal/icon/arrow_forward-24px-blue.svg);
  background-position: 83% 25%;
  background-repeat: no-repeat;
  background-size: 1.8rem;
}

.hp-btn--cta-text:hover,
.hp-btn--cta-text:active {
  color: #383838;
  background-image: url(/assets/img/software/virtual-terminal/icon/arrow_forward-24px-dark-gray.svg);
}

.text-hero__image {
  position: absolute;
  top: 4rem;
  right: 0;
  width: 75%;
}

.slanted-decoration-text {
  display: none;
}

.text-benefits__container {
  height: inherit;
}

.text-benefits__row {
  position: relative;
  height: inherit;
  justify-content: center;
}

.text-benefit:not(:last-of-type) {
  margin-bottom: 4rem;
}

.text-benefit__sub-heading {
  margin-bottom: 2rem;
  width: 105%;
  font-weight: 500;
}  

.text-benefits__image {
  display: none;
}

.text-slanted-section {
  padding: 5rem;
  height: auto;
  transform: skewY(-5deg);
}

.text-slanted-section__contents {
  transform: skewY(5deg);
}

.contact-sales__section--text-adjust {
  margin-top: 0rem;
  padding-top: 0rem;
}

.text-slanted-section__heading {
  padding-left: 0.1rem; 
}

.text-slanted-section__item {
  padding-left: 0.1rem;
  padding-top: 1rem;
  font-weight: 500;
}

.text-slanted-section__sub-heading {
  font-weight: 500;
}

@media (min-width: 401px) {

  .text-hero__image {
    top: 5rem;
    right: 5%;
    width: 25rem;
  }

}

@media (min-width: 575px) {

  .text-hero__image {
    position: absolute;
    top: 36rem;
    right: 9%;
    width: 28rem;
  }

  .software-hero.software-hero--text {
    margin-bottom: 5rem;
  }

  .text-benefits__row {
    justify-content: left;
  }

}

@media (min-width: 768px) {
  
  .text-hero__image {
    top: 39rem;
    right: 15%;
    width: 33rem;
  }
  .text-benefit__sub-heading {
    margin-bottom: 2rem;
    font-weight: 400;
  } 

  .software-hero.software-hero--text {
    margin-bottom: 10rem;
  }

  .text-benefit:not(:last-of-type) {
    margin-bottom: 8rem;
  }

  .text-benefits__image {
    display: inline-block;
    width: 55rem;
  }

  .text-slanted-section__contents {
    padding: 5rem 5rem;
  }

  .contact-sales__section--text-adjust {
    margin-top: 15rem;
    padding-top: 0rem;
  }

  .text-slanted-section__sub-heading {
    font-weight: 400;
  }

}

@media (min-width: 992px) {

  .text-hero__image {
    top: 35rem;
    right: 14%;
    width: 35rem;
  }

  .text-benefits__section {
    margin-bottom: 10rem;
    height: 40rem;
  }

  .text-benefits__row {
    justify-content: left;
  }

  .text-benefit__sub-heading {
    font-size: 2.8rem;
    margin-bottom: 1.5rem;
  }  

  .text-benefit__snippet {
    font-size: 2rem;
  }

  .text-benefits__image-container {
    position: absolute;
    right: 0;
  }

  .text-benefits__image {
    width: 65rem;
  }

  .slanted-decoration-text {
    display: block;
  }

  .text-slanted-section {
    transform: skewY(-3deg);
  }
  
  .text-slanted-section__contents {
    padding: 5rem;
    transform: skewY(3deg);
  }

  .text-slanted-section__heading {
    /* margin-left: 5rem; */
    font-size: 4.1rem;
    margin-bottom: 5rem;
  }

  .text-slanted-section__item:not(:last-of-type) {
    margin-bottom: 0;
  }

}

@media (min-width: 1200px) {

  .text-hero__image {
    top: 26rem;
    right: 13%;
    width: 42rem;
  }

  .software-header__image--text-adjust {
    right: 0;
  }

  .text-benefits__image-container {
    position: absolute;
    right: -22vw;
  }

  .text-benefits__image {
    float: right;
    width: 70rem;
  }

}

@media (min-width: 1400px) {

  .text-hero__image {
    right: 17%;
  }

}

@media (min-width: 1500px) {

  .text-hero__image {
    right: -25%;
    top: 0;
  }

  .text-benefits__image-container {
    right: -25vw;
  }

}