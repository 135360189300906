.info-icon-group {
  text-align: center;
  display: flex;
  justify-content: center;
}

.info-icon-cont {
  display: none;
  max-width: 30rem;
  position: relative;
  min-width: 25rem;
}

.info-icon-cont--1st {
  left: 24rem;
}

.info-icon-cont--2nd {
  left: 12rem;
}

.info-icon-cont--4th {
  right: 12rem;
}

.info-icon-cont--5th {
  right: 24rem;
}

.info-icon-cont--1st-even {
  left: 5.5rem;
}

.info-icon-cont--2nd-even {
  right: 5.5rem;
}

.info-icon__link {
  display: block;
  width: max-content;
  margin: 0 auto;
}

.info-icon {
  background-color: #56dba1;
  display: inline-block;
  padding: 2rem;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 2px 1rem rgba(0,0,0,.2);
}

.info-icon--slanted-portal {
  display: inline-block;
  position: relative;
}

.info-icon img {
  width: 3rem;
}

.info-icon__link:hover + .info-icon-snippet {
  visibility: visible;
  opacity: 1;
}

.info-icon-snippet {
  opacity: 0;
  visibility: hidden;
  transition: all .1s;
  margin-top: 1rem;
  padding-top: 1rem;
  min-width: 22.5rem;
  max-width: 30.5rem;
}

.info-icon-snippet-header {
  color: #0071DC;
}

.info-icon-snippet-header--features {
  color: #00BA82;
}

.info-icon-snippet::before {
	left: 1.5%;
	content: " ";
	height: 0;
	width: 0;
	position: relative;
	pointer-events: none;
	border: solid transparent;
	border-color: rgba(136, 183, 213, 0);
	border-top-color: #00BA82;
	border-width: .8rem;
  margin-left: -.8rem;
}
#info-icon-snippet__pro::before {
	left: 1.5%;
	content: " ";
	height: 0;
	width: 0;
	position: relative;
	pointer-events: none;
	border: solid transparent;
	border-color: rgba(136, 183, 213, 0);
	border-top-color: #1F3C84;
	border-width: .8rem;
  margin-left: -.8rem;
}


.info-icon-group--mobile-item {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.info-icon--mobile {
  padding: 1rem;
  margin-right: 4rem;
}

.info-icon--mobile img {
  width: 2rem;
}

.info-icon--mobile-feature:link,
.info-icon--mobile-feature:visited {
  color: var(--link-gray);
}

.info-icon--mobile-feature:hover,
.info-icon--mobile-feature:active {
  color: var(--features-blue);
  text-decoration: none;
}

@media (min-width: 992px) {
  .info-icon-cont {
    display: inline-block;
  }

  .info-icon-group--mobile {
    display: none;
  }

  .info-icon:hover {
    background-color: #00BA82;
  }
}