.why-healpay-intro__snippet--resellers {
    margin-bottom: 5rem;
    width: 80%;
}

.resellers-benefits__section {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.resellers__container h3 {
    color: var(--healpay-pro-blue);
}

.resellers-benefits__row--row-1 {
    margin-bottom: 7rem;
}

.resellers-benefits__row--row-2 {
    margin-bottom: 3rem;
}

.resellers-benefits__row--row-1-heading {
    margin-bottom: 3rem;
    color: var(--healpay-pro-blue);
}

.resellers-benefits__row--row-1-snippet {
    font-size: 1.8rem;
    width: 85%;
}

.resellers-benefits__row--row-2-heading {
    color: var(--healpay-pro-blue);
}

.resellers-card__image {
    max-width: 3rem;
}

.resellers__small-cards {
    box-shadow: 0px 3px 16px #00000029;
    padding: 3.5rem 2.5rem;
    margin: 1.5rem 2rem;
}

.resellers__small-cards--top-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.resellers-features__section {
    margin-bottom: 14rem;
}

.resellers-features__row--row-1 {
    margin-bottom: 8rem;
}

.resellers-features__header {
    color: var(--healpay-pro-blue);
    margin-bottom: 3rem;
}

.resellers__list {
    margin: 0;
    padding-left: 0;
}

.resellers__list li {
    line-height: 1.3;
    margin: 1.5rem;
}

.resellers__header-image {
    display: none;
}

@media screen and (min-width: 768px) {

    .resellers-benefits__row--row-1-snippet {
        font-size: 2rem;
        width: 100%;
    }

}

@media screen and (min-width: 992px) {

    .resellers__header-image {
        width: 55%;
        margin-top: -12rem;
        margin-left: 35rem;
        display: block;
    }

    .resellers-benefits__section {
        margin-bottom: 12rem;
    }

    .resellers-benefits__row--row-1 {
        margin-bottom: 9rem;
    }

    .resellers-benefits__row--row-2 {
        margin-bottom: 6rem;
    }

    .resellers__small-cards {
        padding: 3.5rem 2.5rem;
        margin: 0 8rem 7rem 0rem;
    }

    .resellers__small-cards--row-2 {
        margin-bottom: 0;
    }

    .resellers-features__row--row-1 {
        margin-bottom: 12rem;
    }

    .resellers__list li {
        font-size: 2rem;
    }

}

@media screen and (min-width: 1200px) {
    .resellers__header-image {
        width: 100%;
        margin-top: 7rem;
        margin-left: -5rem;
    }

    .resellers__container {
        margin: 5rem 0rem;
    }

}