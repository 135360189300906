  .slanted-masthead.error-slant,
  .slanted-masthead.error-slant::after {
    background-color: #f5fcfa;
  }

  .error {
    height: 100rem;
  }

  .error__container {
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: .5rem;
    position: relative;
    top: 0%;
    padding: 7rem 5rem 15rem 6rem;
    display: inline-block;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }

  .error__type {
    font-weight: 800;
    font-size: 2.4rem;
    line-height: 3rem;
    color: #383838;
    margin-bottom: 1rem;
  }

  .error__title {
    color: #00BA82;
    font-size: 3.6rem;
    line-height: 4.2rem;
    padding-bottom: 3rem;
  }
  
  .error__message {
    font-size: 1.6rem;
    color: #000;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  @media screen and (min-width: 768px) {

    .error__container {
      height: 100rem;
      margin: 0 auto;
      display: block;
    }

  }

  @media screen and (min-width: 992px) {

    .error__container {
      background-color: rgba(255, 255, 255, .75);
      border-radius: .5rem;
      padding: 12rem 40rem 15rem 15rem;
      display: block;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
    }

    .error__type {
      font-weight: 800;
      font-size: 2.4rem;
      line-height: 3rem;
      color: #383838;
      margin-bottom: 1rem;
    }

    .error__title {
      color: #00BA82;
      font-size: 3.6rem;
      line-height: 4.2rem;
      padding-bottom: 3rem;
    }

    .error__message {
      font-size: 1.6rem;
      color: #000;
      margin-top: 3rem;
      margin-bottom: 4rem;
    }

    .error__hr {
      width: 140%;
      left: 0;
    }

    .hp-btn--cta--margin {
      margin-left: 4rem;
    }

  }