.slanted-masthead {
  position: absolute;
  top: 0;
  left: 0;
  height: 32rem;
  width: 100%;
  z-index: -1;
  background-color: #f6f7fa;
}

.slanted-masthead:after {
  content: "";
  transform: skewY(-10.4deg);
  transform-origin: 0;
  background-color: #f6f7fa;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35rem;
  height: 50rem;
}


@media screen and (min-width: 575px) {
  .slanted-masthead {
    height: 29rem;
  }
}

@media screen and (min-width: 768px) {
  .slanted-masthead {
    height: 38rem;
  }
}

@media screen and (min-width: 1500px) {
  .slanted-masthead {
    height: 42rem;
  }
}