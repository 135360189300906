.software-slant.software-slant, .software-slant.software-slant:after {
  background-color: #f5fcfa;
}
.software-slant__pro.software-slant__pro, .software-slant__pro.software-slant__pro:after {
  background-color: #f6faff;
}

.software-header {
  position: relative;
}

.product-hero {
  height: 60em;
}

.product-hero--features {
  height: 65rem;
}


.software-mobile-header {

}

.software-mobile-header__row {
  margin: 6rem 0 2rem 0;
  justify-content: space-between;
}

.software-mobile-header__product {
  text-transform: uppercase;
  font-weight: bold;
  color: rgba(0, 186, 130, 1);
}

.software-header__image-container {
  position: absolute;
  top: 41vh;
  right: 51vw;
  width: 50%;
}

.software-header__image {
  width: 65vw;
  display: none;
}

.software-mobile-header__image {
  width: 94vw;
}

.software-header__icon {
  display: none;
}

.software-mobile-header__get-started:link,
.software-mobile-header__get-started:visited {
  background-color: rgba(0, 186, 130, 1);
}

.software-mobile-header__get-started:hover,
.software-mobile-header__get-started:active {
  background-color: #383838;
}

@media (max-width: 400px) {

  .software-header__image-container {
    top: 47vh;
    right: 41vw;
    width: 50%;
  }

  .software-mobile-header__image {
    width: 92vw;
  }

}

@media (min-width: 575px) {

  .software-header__image-container {
    top: 38vh;
    right: 37vw;
    width: 50%;
  }

  .software-mobile-header__image {
    width: 80vw;
  }

}

@media (min-width: 768px) {

  .software-header__image-container {
    top: 20.5vh;
    right: 32vw;
  }

  .software-mobile-header__image {
    width: 77vw;
  }

}

@media (min-width: 992px) {

  .software-hero__container {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .software-mobile-header {
    display: none;
  }

  .software-mobile-header__row {
    margin-bottom: 2rem;
  }

  .software-mobile-header__product {
    font-size: 2.6rem;
  }

  .software-rainbow-mobile-background {
    display: none;
  }

  .software-header__image-container {
    top: 0rem;
    right: 22rem;
    width: 50%;
  }

  .software-header__image {
    width: 75vw;
    display: block;
  }

  .product-hero {
    height: 65rem;
  }
  
  .product-hero--features {
    height: 60rem;
  }
  
}

@media (min-width: 1200px) {

  .software-header__image-container {
    right: 17rem;
  }

  .software-header__image {
    width: 55vw;
  }
}

@media screen and (min-width: 1300px) {

  .software-header__icon {
    display: block;
    position: absolute;
    left: -5rem;
    top: 1rem;
    width: 2.9rem;
    opacity: 90%;
  }

}

@media (min-width: 1500px) {

  .software-header__image {
    width: 55vw;
  }
}