.about-us-intro__container {
  justify-content: center;
}

.about-us-intro__snippet--bold {
  font-weight: 700;
}

.team-bios {
  margin-top: 24rem;
}

.team-bios__header {
  text-align: center;
  font-size: 4.8rem;
}

.co-founder {
  margin-bottom: 2.5rem;
  padding: 0 3.5rem;
}

.co-founder__img {
  width: 20rem;
}

.employee {
  margin-bottom: 2rem;
  padding: 2.5rem;
}

.employee__img {
  width: 15rem; 
}

.employee__firstname, .employee__lastname  {
  display: block;
  color: #383838;
}

.employee__title, .co-founder__title {
  color: #6c757d;
  font-size: 1.3rem;
}

.employee__description, .co-founder__description {
  color: #5f666d;
  margin-top: 1.5rem;
}

.about-us__logo-container {
  margin-top: 2rem;
  text-align: center;
  display: none;
}

.about-us__logo {
  width: 17rem;
}

.disable {
  pointer-events: none;
  cursor: default;
  color: #C6C6C5;
}

.contact {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.contact__title {
  margin-bottom: 5rem;
  font-size: 4rem;
}

.contact__container {
  margin-bottom: 9rem;
}

.contact__header, .contact__address {
  font-size: 1.8rem;
}

.contact__header {
  color: #1F3C84;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.contact__address span {
  color: #707070;
  display: block;
  margin-bottom: 1rem;
}

.contact__video-container video {
  width: 100%;
  height: auto;
  border-radius: 2%;
}

.contact__video-info {
  display: flex;
  margin-top: 2rem;
}

.contact__video-title {
  white-space: nowrap;
  font-size: 3rem;
}

.contact__video-description {
  color: #707070;
  margin-left: 6rem;
}

a.active {
  color: #C6C6C6;
}

@media screen and (max-width: 575px) {
  .team-bios__header {
    font-size: 2.5rem;
  }
  
  .contact__video-info {
    display: block;
    margin-top: 2rem;
  }

  .contact__video-description {
    margin-left: 0;
  }
}

@media screen and (min-width: 400px) {

  .about-us__logo-container {
    display: block;
  }

}

@media screen and (min-width: 768px) {
  .intro-container {
    padding: 0;
  }

  .about-us__logo-container {
    margin-top: 2rem;
    text-align: right;
  }

  .employee {
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: 992px) {
  .about-us-intro__container {
    justify-content: unset;
  }
  
  .about-us__logo-container {
    margin-top: 0;
  }
  
  .intro-container {
    padding-right: 1rem;
  }

  .contact__video-title {
    white-space: normal;
  }
}

@media screen and (min-width: 1200px) {
  
  .employee {
    margin-bottom: 2rem;
    padding: 2.5rem;
  }
  
  .contact__video-title {
    white-space: nowrap;
  }
}