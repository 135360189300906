/* BENEFITS SECTION */

.law-firms-benefits {
  margin-top: -15rem;
  margin-bottom: 8rem;
}

.law-firms-benefits__heading {
  color: #1F3C84;
  margin-bottom: 5rem;
  width: 75%;
  font-size: 2.4rem;
}

.law-firms-benefits__heading-2 {
  color: #1F3C84;
  margin-bottom: 5rem;
  font-size: 2.4rem;
  line-height: 1.3;
}

.law-firms-benefits__snippet {
  font-size: 1.6rem;
  line-height: 1.3;
}

.law-firms-benefits__snippet:not(:last-of-type) {
  margin-bottom: 3rem;
}

.law-firms-benefits__img-cont {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.law-firms-benefits__img {
  width: 100vw;
  height: auto;
  align-self: flex-start;
}


/* FEATURES SECTION */


.law-firms-features {
  margin-top: -3rem;
}

.law-firms-features__heading {
  color: #1F3C84;
  margin-bottom: 5rem;
  width: 85%;
  font-size: 2.4rem;
}

.law-firms-features__snippet {
  font-size: 1.6rem;
  line-height: 1.3;
}

.law-firms-features__snippet:not(:last-of-type) {
  margin-bottom: 3rem;
}

.law-firms-features__img-cont {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.law-firms-features__img {
  width: 100vw;
  height: auto;
  align-self: flex-start;
}


/* ADDITIONAL FEATURES SECTION */

.slanted-section.law-firms-cards__section {
  height: unset;
  padding: 10rem 0 15rem 0;
  margin: 0;
  background-color: #f6f7fa;
  transform: skewY(-4deg);
}

.law-firms-cards__container {
  transform: skewY(4deg);
}

.law-firms-cards__header {
  color: #1F3C84;
  margin-bottom: 2rem;
}

.law-firms-cards__row {
  margin: 3rem 0 5rem 0;
  justify-content: center;
}

.law-firms__collection-link-cont {
  display: none;
}

/* CONTACT SALES SECTION */

.contact-sales__header--char-orgs {
  color: #1F3C84;
}

.contact-sales__btn--char-orgs {
  background-color: #1F3C84;
}

@media (min-width: 575px) {

  .law-firms-benefits__row {
    justify-content: center;
  }

  /* .law-firms-benefits__img-cont {
    display: flex;
    justify-content: center;
  }   */

  .law-firms-benefits__img {
    width: 100%;
    /* margin-left: 0; */
    border-radius: .7rem;
  }

  .law-firms-features__row {
    justify-content: center;
  }

  .law-firms-features__img-cont {
    display: flex;
    justify-content: center;
  }  

  .law-firms-features__img {
    width: 100%;
    margin-left: 0;
    border-radius: .7rem;
  }

}

@media (min-width: 768px) {

  .law-firms-benefits__img {
    width: 80%;
    margin-left: 0;
  }

  .law-firms-features__img {
    width: 80%;
    margin-left: 0;
  }

  .law-firms-cards__row {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

}

@media (min-width: 992px) {

  .industries-hero__heading {
    width: 90%;
  }

  .law-firms-benefits {
    margin-top: -10rem;
  }

  .law-firms-benefits__row {
    justify-content: flex-start;
  }

  .law-firms-benefits__heading {
    color: #1F3C84;
    margin-bottom: 5rem;
    width: 75%;
    font-size: 2.8rem;
  }

  .law-firms-benefits__heading-2 {
    color: #1F3C84;
    margin-bottom: 5rem;
    font-size: 2.3rem;
  }

  .law-firms-benefits__snippet {
    font-size: 2rem;
  }

  .law-firms-benefits__img-cont {
    margin-top: 0;
    position: relative;
    display: block;
  }

  .law-firms-benefits__img {
    right: -20rem;
    position: absolute;
    top: -10rem;
    border-radius: .7rem;
    width: unset;
    height: 80%;
  }

  .law-firms-cards__row {
    justify-content: left;
  }

  .law-firms-features__heading {
    font-size: 2.8rem;
  }

  .law-firms-features__snippet {
    font-size: 2rem;
  }

  .law-firms-features__img-cont {
    margin-top: 0;
    display: block;
  }

  .law-firms-features__img {
    right: -25rem;
    border-radius: .7rem;
    width: unset;
    height: 85%;
  }


  .law-firms__collection-link-cont {
    display: block;
  }

}

@media (min-width: 1200px) {

  .industries-hero__heading {
    width: 100%;
  }

  .law-firms-benefits {
    margin-top: -10rem;
  }

  .law-firms-benefits__img {
    right: -12rem;
    top: -10rem;
  }
  
  .law-firms-cards__row {
    justify-content: center;
  }

  .law-firms-features__img {
    right: -10rem;
    height: 115%;
    position: absolute;
    top: -5rem;
  }

}

@media (min-width: 1500px) {

  .law-firms-benefits__img {
    right: -15rem;
    height: 45rem;
    top: -10rem;
  }

  .law-firms-features__img {
    right: -10rem;
    height: 103%;
    top: -1rem;
  }

}