@font-face {
  font-family: "Metric Web";
  src: url("/assets/fonts/metric/metric-web-regular.eot"),
       url("/assets/fonts/metric/metric-web-regular.woff"),
       url("/assets/fonts/metric/metric-web-regular.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metric Web";
  src: url("/assets/fonts/metric/metric-web-regular-italic.eot"),
       url("/assets/fonts/metric/metric-web-regular-italic.woff"),
       url("/assets/fonts/metric/metric-web-regular-italic.woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metric Web";
  src: url("/assets/fonts/metric/metric-web-medium.eot"),
       url("/assets/fonts/metric/metric-web-medium.woff"),
       url("/assets/fonts/metric/metric-web-medium.woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metric Web";
  src: url("/assets/fonts/metric/metric-web-medium-italic.eot"),
       url("/assets/fonts/metric/metric-web-medium-italic.woff"),
       url("/assets/fonts/metric/metric-web-medium-italic.woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metric Web";
  src: url("/assets/fonts/metric/metric-web-semibold.eot"),
       url("/assets/fonts/metric/metric-web-semibold.woff"),
       url("/assets/fonts/metric/metric-web-semibold.woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metric Web";
  src: url("/assets/fonts/metric/metric-web-semibold-italic.eot"),
       url("/assets/fonts/metric/metric-web-semibold-italic.woff"),
       url("/assets/fonts/metric/metric-web-semibold-italic.woff2");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metric Web";
  src: url("/assets/fonts/metric/metric-web-bold.eot"),
       url("/assets/fonts/metric/metric-web-bold.woff"),
       url("/assets/fonts/metric/metric-web-bold.woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metric Web";
  src: url("/assets/fonts/metric/metric-web-bold-italic.eot"),
       url("/assets/fonts/metric/metric-web-bold-italic.woff"),
       url("/assets/fonts/metric/metric-web-bold-italic.woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}