.large-benefits-card {
  margin-top: 5rem;
}

.large-benefits-card__container {
  padding: 5rem 5rem 8rem 5rem;
  border-radius: 3px;
  position: relative;
  width: 96%;
}

.large-benefits-card__container--ivr {
  padding: 5rem 4rem 8rem 4rem;
}

.large-benefits-card__row {
  justify-content: space-between;
}

.large-benefits-card__header {
  color: #1F3C84;
  margin-bottom: 3rem;
  margin-right: 3rem;
  font-size: 2.8rem;
}

.large-benefits-card__snippet {
  margin-bottom: 3rem;
}

.large-benefits-card__main-icon {
  position: absolute;
  top: 5.25rem;
  right: 2rem;
  width: 35px;
  display: none;
}

.large-benefits-card__point-container {
  width: 100%;
}

.large-benefits-card__point-line {
  padding: 1.25rem 0;
  display: flex;
}

.large-benefits-card__point-line:not(:last-of-type) {
  border-bottom: 1px solid #989898;
}

.large-benefits-card__point-line-text-adjust {
  border-bottom: 1px solid #989898;
}

.large-benefits-card__point:link,
.large-benefits-card__point:visited {
  color: #0071DC;
  font-size: 1.8rem;
  display: block;
  text-decoration: none;
}

.large-benefits-card__point--ivr {
  color: #0071DC;
}

.large-benefits-card__point-svg-container {
  width: 3rem;
  margin-right: 2.5rem;
}

.large-benefits-card__point-svg {
  width: 2.5rem;
}

.large-benefits-card__point-svg-a {
  fill: none;
}

.large-benefits-card__point-svg-b {
  fill: #989898;
}

.large-benefits-card__point-line h5 {
  color: #989898;
}

.large-benefits-card__point-line--ivr-pro:not(:last-of-type) {
  border-bottom: none;
  margin-bottom: 2rem;
}



.large-benefits-card__point--adjust:link,
.large-benefits-card__point--adjust:visited {
  border-bottom: none;
}

@media (min-width: 400px) {

  .large-benefits-card__container--ivr {
    padding: 5rem 3rem 8rem 3rem;
  }

}

@media (min-width: 768px) {

  .large-benefits-card {
    margin-top: 10rem;
  }

  .large-benefits-card__container {
    box-shadow: 0 .1rem 1rem .1rem rgba(0,0,0,.1);
    padding: 5rem 10rem 8rem 8rem;
    width: 100%;
    background-color: white;
  }
  
  .large-benefits-card__header {
    margin-left: 0;
    width: 85%;
    line-height: 1.4;
  }

  .large-benefits-card__main-icon {
    position: absolute;
    top: 3rem;
    right: 3rem;
    width: 5rem;
    display: block;
  }

  .large-benefits-card__point-container {
    width: 45%;
  }

  .large-benefits-card__point:link,
  .large-benefits-card__point:visited {
    border-bottom: none;
  }

  .large-benefits-card__point-line:not(:last-of-type) {
    border-bottom: 1px solid #989898;
  }

  .large-benefits-card__point-line-text-adjust {
    border-bottom: unset;
  }

  

}


@media screen and (min-width: 992px) {

  .large-benefits-card {
    margin-top: 18rem;
  }

  .large-benefits-card__container {
    padding: 5rem 7rem 8rem 7rem;
  }

  .large-benefits-card__snippet {
    width: 75%;
  }

  .large-benefits-card__main-icon {
    top: 4rem;
    right: 5rem;
    width: 6rem;
  }

  .large-benefits-card__point-line--ivr-pro:not(:last-of-type) {
    border-bottom: 1px solid #989898;
    margin-bottom: 0;
  }

  .large-benefits-card__point-line--portal {
    width: 85%;
  }

  .large-benefits-card__point-line:hover .large-benefits-card__point {
    color: var(--healpay-green-light);
  }

  .large-benefits-card__point-line:hover .large-benefits-card__point-svg-b {
    fill: var(--healpay-green-light);
  }

  .large-benefits-card__header {
    width: 75%;
    line-height: 1.4;
    font-size: 3.2rem;
  }

}

@media screen and (min-width: 1200px) {

  .large-benefits-card__container {
    padding: 5rem 9rem;
    width: 100rem;
  }

  .large-benefits-card__main-icon {
    top: 5rem;
    right: 6rem;
    width: 6rem;
  }

  .large-benefits-card__header {
    width: 75%;
    font-size: 3.6rem;
  }
  
}