.why-healpay-intro {
  margin-top: 8rem;
}

.why-healpay-intro__title {
  font-size: 3rem;
  margin-bottom: 2rem;
  line-height: 1.3;
}

.why-healpay-intro__snippet {
  font-size: 2rem;
  line-height: 1.3;
}

@media screen and (min-width: 768px) {
  .why-healpay-intro__title {
    font-size: 4rem;
  }
}

@media screen and (min-width: 992px) {
  .why-healpay-intro__title {
    font-size: 4rem;
  }
}