.why-healpay-container .category-navigation__link.active:link,
.why-healpay-container .category-navigation__link.active:visited {
  color: #000000;
}

.why-healpay-container .category-navigation__link.active:hover,
.why-healpay-container .category-navigation__link.active:active {
  color: #00BA82;
}

.testimonial-hero__container {
  margin-bottom: 4rem;
}

.hero-heading.hero-heading--why-healpay {
  color: #000000;
}

.testimonials {
  margin-bottom: -5rem;
  position: relative;
  z-index: 2;
}

.testimonials__container {
  border-radius: 5px;
  background-color: white;
  padding: 5rem 7rem;
  margin: 0 2rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
  width: unset;
}

.testimonials__row--1 {
  justify-content: space-around;
  align-items: baseline;
}

.testimonials__row--2 {
  justify-content: space-evenly;
  align-items: baseline;
}

.testimonials__logo {
  transition: all .2s;
  padding: 2rem 3rem;
  text-align: center;
  margin-bottom: 3rem;
}

.testimonials__logo svg {
  width: 100%;
}

.testimonials__logo .testimonials-logo,
.testimonials__logo .testimonials-logo--fbcg {
  transition: all .2s;
}

.testimonials__logo:hover .testimonials-logo,
.testimonials__logo:active .testimonials-logo,
.testimonials__logo:hover .testimonials-logo--fbcg,
.testimonials__logo:active .testimonials-logo--fbcg {
  fill: #00BA82;
}

.testimonials__logo--fbcg svg {
  width: 100%;
}

.testimonials__logo--wide {
  max-width: 25rem;
}

.testimonials-logo--fbcg tspan {
  font-size: 10px;
  font-family: TrajanPro3-Regular, 'Trajan Pro 3', serif;
}

.aca--b {
  fill: #fff;
}

.fbc-logo--a {
  font-size: 13px;
  font-family: TrajanPro3-Regular, 'Trajan Pro 3', serif;
}

.fbc-logo--b {
  font-size: 29px;
  font-family: TrajanPro3-Regular, 'Trajan Pro 3', serif;
}

.vertican--a {
  font-size: 51px;
}

@media screen and (min-width: 400px) {

  .why-healpay-intro__snippet--testimonials {
    width: 85%
  }

  .testimonials__logo svg {
    width: 90%;
  }

  .testimonials__logo--fbcg svg {
    width: 75%;
  }
}

@media screen and (min-width: 575px) {

  .why-healpay-intro__snippet--testimonials {
    width: 90%
  }

  .testimonials__container {
    box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .testimonials__logo--fbcg svg {
    width: 60%;
  }
}

@media screen and (min-width: 768px) {

  .why-healpay-intro__snippet--testimonials {
    width: 100%
  }

  .testimonials__logo--fbcg svg {
    width: 45%;
  }

}

@media screen and (min-width: 992px) {

  .testimonials {
    margin-bottom: 30rem;
  }

  .testimonials__logo {
    margin-bottom: 1rem;
  }

  .testimonials__logo--fbcg svg {
    width: 125%;
  }
}

@media screen and (min-width: 1200px) {
  .testimonials__logo--fbcg svg {
    width: 110%;
  }
}