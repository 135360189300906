.product-link:link,
.product-link:visited {
    text-transform: uppercase;
    color: var(--healpay-green-dark);
    font-size: 2rem;
    font-weight: 600;
    transition: all .1s;
}

.product-link:hover,
.product-link:active {
    color: var(--hover-black);
    text-decoration: none;
}

.industry-link:link,
.industry-link:visited {
    
    color: var(--healpay-pro-blue);
    font-size: 2rem;
    text-transform: capitalize;
    transition: all .1s;
}

.industry-link:hover,
.industry-link:active {
    color: var(--hover-black);
    text-decoration: none;
}

.product-pro-link:link,
.product-pro-link:visited {
    text-transform: uppercase;
    color: var(--healpay-pro-blue);
    font-size: 1.8rem;
    font-weight: 600;
    transition: all .1s;
}

.product-pro-link:hover,
.product-pro-link:active {
    color: var(--hover-black);
    text-decoration: none;
}

@media screen and (min-width: 992px) {
    
    .product-link:link,
    .product-link:visited {
        font-size: 2rem;
    } 
    
    .product-pro-link:link,
    .product-pro-link:visited {
        font-size: 2rem;
    } 
}