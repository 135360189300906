.slanted-section {
  background: #F0FBF8;
  transform: skewY(-3deg);
  position: relative;
  padding: 10% 0 5% 0;
  margin: 7% 0;
  display: flex;
  align-items: center;
}

.slanted-section__mobile {
  background: #f0fbf8;
  height: 70vh;
  transform: skewY(-3deg);
  position: relative;
  margin-top: 10rem;
}

.slanted-section__mobile p {
  color: #C6C6C6;
}

.slanted-section__container {
  transform: skewY(3deg);
}

.slanted-section__contents {
  position: relative;
  top: 50%;
  left: 50%;
  display: inline-block;
  margin-left: -29.6rem;
  margin-top: -14.2rem;
  vertical-align: middle;
  transform: skewY(3deg);
  padding: 100px;
  background-color: rgba(255, 255, 255, .7);
}

.slanted-section--line-outer-top {
  top: -2rem;
  right: 0;
}

.slanted-section--line-outer-bottom {
  bottom: -2rem;
  left: 0;
}

.slanted-section--line-outer {
  z-index: 100;
  background-color: #56dba1;
  position: absolute;
  transform: skewX(-10deg);
  height: 1rem;
  width: 43%;
  display: none;
}

.slanted-section--line-inner-top {
  top: -1rem;
  right: 0;
}

.slanted-section--line-inner-bottom {
  bottom: -1rem;
  left: 0;
}

.slanted-section--line-inner {
  z-index: 100;
  background-color: #00ba82;
  position: absolute;
  transform: skewX(-10deg);
  height: 1rem;
  width: 45%;
  display: none;
}

.slant-section__container {
  transform: skewY(3deg);
  max-width: 30rem;
}

.slanted-section__header {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 5rem;
}

@media (min-width: 575px) {
  .slant-section__container {
    max-width: 37rem;
  }
}

@media (min-width: 992px) {

  .slanted-section--line-inner, .slanted-section--line-outer {
    display: block;
  }

  .slanted-section {
    height: 45rem;
    padding: 5% 0 2% 0;
  }

} 