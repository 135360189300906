.privacy-policy__container {
    margin-top: 12rem;
    margin-bottom: 25rem;
}

.privacy-header {
    margin-bottom: 4rem;
}

.privacy-section-heading {
    font-weight: 500;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}

.privacy-link:link,
.privacy-link:visited {
    color: #00BA82;
}

.privacy-link:hover,
.privacy-link:active {
    color: #707070;
}

.privacy-list {
    margin: 3rem 0;
    font-size: 1.6rem;
}

.privacy-list li {
    line-height: 1.6;
}   