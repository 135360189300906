

.software-slant__ivr-pro {
  background-color: #f6faff;
}

.mobile-hero-heading {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.hero-heading-portalpro {
  color: #1F3C84;
}

.software-hero--ivr-pro .category-navigation__link.active {
  color: #1F3C84;
}

.software-hero--ivr-pro .category-navigation__link:hover {
  color: #1F3C84;
}


/* IVR PRO Hero Section */

.ivr-pro-hero__image {
  position: absolute;
  top: 4rem;
  right: 0;
  width: 75%;
}

.software-hero--ivr-pro .category-navigation__link.active:link,
.software-hero--ivr-pro .category-navigation__link.active:visited {
  color: #1F3C84;
}

.software-hero--ivr-pro .category-navigation__link:hover,
.software-hero--ivr-pro .category-navigation__link:active {
  color: #1F3C84;
}


/* IVR PRO Benefits Section */

.ivrpro-benefits__section a {
  color: #00BA82;
}

.ivr-pro-benefits__sub-heading {
  color: #1f3c84;
}

.ivr-pro-benefits__sub-heading--adjust {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5rem;
  margin-bottom: 0;
}



/* IVR PRO Features Section */

.slanted-section.ivr-pro-features__section {
  transform: skewY(-1.5deg);
  padding: 0;
  height: auto;
  margin: 0;
  display: flex;
  align-items: center;
  margin-top: 7rem;
}

.ivr-pro-features__container {
  margin: 10rem auto;
  margin-bottom: 6rem;
  transform: skewY(1.5deg);
}



.ivr-pro-features__row-1 {
  padding-bottom: 2rem;
}

.ivr-pro-features__row-2 {
  padding-top: 2.5rem;
}

/* .ivr-pro-features__header {
  color: #0071DC;
  margin-bottom: 2rem;
} */

.ivr-pro-features__snippet {
  font-size: 2.2rem;
}

.ivr-pro-features__sub-heading {
  color: #1f3c84;
  margin-bottom: 1.5rem;
}

.ivr-pro-features__feature {
  margin-top: 8rem;
}

.ivr-pro-features__image {
  width: 55rem;
  position: relative;
  top: -8rem;
}

.ivr-pro-features__section {
  background-color: #f0f7fd;
}

.hp-btn--cta--ivr-pro-adjust:link,
.hp-btn--cta--ivr-pro-adjust:visited {
  white-space: nowrap;
  width: unset;
  padding: 0;
}

.hp-btn--cta-svg {
  width: 1.8rem;
  margin: -2px 0 0 1rem;
}

.hp-btn--cta-svg-a {
  fill: none;
}

.hp-btn--cta-svg-b {
  fill: #00BA82;
}

.hp-btn--cta--ivr-pro-adjust:link,
.hp-btn--cta--ivr-pro-adjust:visited {
  white-space: nowrap;
  width: unset;
  padding: 0;
  color: var(--healpay-green-light);
}

.hp-btn--cta--ivr-pro-adjust:hover,
.hp-btn--cta--ivr-pro-adjust:active {
  background-color: transparent;
  color: #222;
  backface-visibility: hidden;
}

.hp-btn--cta--ivr-pro-adjust .hp-btn--cta-svg-b {
  fill: var(--healpay-green-light);
}

.hp-btn--cta--ivr-pro-adjust:hover .hp-btn--cta-svg-b,
.hp-btn--cta--ivr-pro-adjust:active .hp-btn--cta-svg-b {
  fill: #222;
}


/* IVR Pro Large Benefits Card */

.large-benefits-card__container--ivr-pro {
  background-color: white;
}

@media (min-width: 400px) {

  .ivr-pro-hero__image {
    top: 5rem;
    right: 5%;
    width: 25rem;
  }
}

@media (min-width: 575px) {

  .ivr-pro-hero__image {
    position: absolute;
    top: 36rem;
    right: 9%;
    width: 28rem;
  }
}

@media (min-width: 768px) {

  .ivr-pro-hero__image {
    top: 39rem;
    right: 15%;
    width: 33rem;
  }

  .large-benefits-card__container--ivr-pro {
    margin-top: -13rem;
  }

  .ivr-benefits__image-mobile--2 {
    margin-bottom: 3rem;
  }

  .ivr-benefits__image-mobile--3 {
    margin-bottom: 3rem;
  }

}

@media (min-width: 992px) {

  .software-slant__ivr-pro {
    clip-path: polygon(0% 0%, 100% 0, 100% 64.5%, 0% 86%);
  }

  .ivr-pro-hero__image {
    top: 35rem;
    right: 14%;
    width: 35rem;
  }

  .ivr-pro-benefits__image--3 {
    top: -8rem;
  }

  .ivr-pro-features__container {
    margin: 10rem 8rem 6rem 8rem;
  }  

  .ivrpro-benefits__section {
    background-color: #f4f9fd;
    margin: -5rem 0 -18rem 0;
  }

  .ivr-pro-features__row-1 {
    border-bottom: 1px solid #ccc;
  }

  .ivr-pro-benefits__sub-heading--adjust {
    border-bottom: unset;
  }

  .ivr-pro-features__header {
    font-size: 3.5rem;
    font-weight: bold;
  }

  .large-benefits-card__container--ivr-pro {
    margin-top: -19.8rem;
  }
}

@media (min-width: 1200px) {

  .software-slant__ivr-pro {
    clip-path: polygon(0% 0%, 100% 0, 100% 68.5%, 0% 95%);
  }

  .ivr-pro-hero__image {
    top: 26rem;
    right: 9%;
    width: 42rem;
  }

  .ivr-pro-features__container {
    margin: 10rem auto;
  }
}

@media (min-width: 1400px) {
 
  .ivr-pro-hero__image {
    right: 13%;
  }
  
  .ivr-pro-features__container {
    margin: 10rem auto;
  }
}

@media (min-width: 1500px) {

  .ivr-pro-hero__image {
    right: -17%;
    top: 0;
  }

}