:root {
  --healpay-green-light: #00BA82;
  --healpay-green-dark: #00B983;
  --features-blue: #0071DC;
  --healpay-pro-blue: #1F3C84;
  --hover-black: #383838;
  --link-gray: #989898
}

.healpay-green-light {
  color: var(--healpay-green-light);
}

.features-blue {
  color: var(--features-blue);
}

.healpay-pro-blue {
  color: var(--healpay-pro-blue);
}
