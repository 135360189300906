.recurring__header-image {
    width: 80%;
    margin-top: 7rem;
    float: right;
}

.product-benefits__container--recurring-payments {
    margin-top: 10rem;
    margin-bottom: 7rem;
}

.recurring-payments__card {
    border: 2px solid #ccc;
    padding: 6.5rem 3rem 4rem 3rem;
    margin: 10rem 0 8rem 0;
    border-radius: 0.7rem;
    background-image: url("/assets/img/features/recurring-payments/rainbow-blur.png");
    background-size: 45rem;
    background-repeat: no-repeat;
    background-position: right -7rem;
}

.recurring-payments__card--col-1--subheader-container {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
}

.recurring-payments__card--col-1--subheader {
    color: var(--healpay-green-light);
    margin-bottom: 0;
    font-size: 2.6rem;
}

.recurring-payments__card--col-1--subheader-img {
    width: 3.5rem;
    margin-left: 3rem;
}

.recurring-payments__card--col-1--snippet-1 {
    margin-bottom: 3rem;
    font-size: 2rem;
}

.recurring-payments__card--col-1--snippet-2 {
    color: var(--healpay-green-light);
    margin-bottom: 5rem;
    font-size: 1.6rem;
}

.recurring-payments__card--col-2--subheader {
    color: #00BA82;
    margin-bottom: 3rem;
    font-size: 2.6rem;
}

.recurring-payments__card--col-2--snippet {
    margin-bottom: 2rem;
    font-size: 2rem;
}

.recurring-payments__card--col-2--image {
    width: 20rem;
}

.product-benefits__row--recurring-payments {
    justify-content: flex-start;
}

.product-benefits--recurring-payments {
    padding: 0 3rem;
}


@media (min-width: 575px) {

    .hero-sub-heading--recurring-payments {
        width: 80%;
    }

    .recurring-payments__card {
        padding: 6.5rem 5rem 4rem 5rem;
    }

    .recurring-payments__card--col-1--snippet-1 {
        width: 90%;
    }
    
    .recurring-payments__card--col-1--snippet-2 {
        width: 75%;
    }

    .product-benefits__snippet--recurring-payments {
        width: 90%;
    }
    
}


@media (min-width: 768px) {

    .hero-sub-heading--recurring-payments {
        width: 100%;
    }

    .recurring__header-image {
        width: 64%;
        margin-top: -1rem;
        margin-right: -3rem;
        float: right;
    }

    .product-benefits--recurring-payments {
        padding: 0 8rem;
    }

    .product-benefits__snippet--recurring-payments {
        width: 100%;
    }
}

@media (min-width: 992px) {

    .recurring__header-image {
        width: 80%;
        margin-top: 7rem;
        float: right;
    }

    .product-benefits__container--recurring-payments {
        margin-top: 20rem;
        margin-bottom: 10rem;
    }

    .recurring-payments__card--col-1--snippet-2 {
        margin-bottom: 4rem;
    }

    .product-benefits__snippet--recurring-payments {
        width: 110%;
    }

}

@media (min-width: 992px) {


    .recurring__header-image {
        width: 80%;
        margin-top: 3rem;
        margin-left: 5rem;
    }

}

@media (min-width: 1200px) {

    .recurring__header-image {
        width: 80%;
        margin-top: 6rem;
        margin-left: 10rem;
    }

    .product-benefits__snippet--recurring-payments {
        width: 90%;
    }

}