.careers-hero__container { 
    margin-top: 10rem;
}

.careers-hero {
    padding: 0;
}

.careers-hero__title {
    color: #1F3C84;
    font-size: 3.2rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.career-list__section {
    margin-top: 7rem;
    margin-bottom: 20rem;
}

.career-list {
    margin-top: 5rem;
}

.career-list__header {
    display: none;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 4rem;
}

.career-list__header-item {
    color: #1F3C84;
    text-transform: uppercase;
    font-weight: 800;
    flex: 1;
}

.career-list__header-item--feat {
    flex: 2;
}

.career-list__item {
    background-color: #FFFFFF;
    border-radius: .5rem;
    margin-bottom: 3rem;
}


.career-list__card {
    display: block;
    flex-direction: row;
    align-items: center;
    padding: 4rem 3rem;
    background-color: #fff;
    box-shadow: 0 0 1.5rem rgba(0,0,0,.2);
    overflow: hidden;
    text-decoration: none;
    transition: all .2s;
    border-radius: .5rem;
}

.career-list__card:hover {
    text-decoration: none;
    color: #000;
    transform: scale(1.01);
}

.career-list__card:hover .hp-btn.hp-button--career-list {
    background-color: #315ecf;
}

.hp-btn.hp-button--career-list {
    padding: 1rem 1rem;
    height: 4.5rem;
    background-color: #1F3C84;
    display: block;
}

.hp-btn.hp-button--career-list:hover {
    transform: none;
    box-shadow: none;
}

.career-list__item--title {
    flex: 2;
}

.career-list__item--type,
.career-list__item--location,
.career-list__item--experience {
    display: none;
}

@media screen and (min-width: 768px) {
    
    .career-list__header {
        display: flex;
    }

    .career-list__card {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6rem 3rem; 
        background-color: #fff;
        box-shadow: 0 0 1.5rem rgba(0,0,0,.2);
        height: 10rem;
        overflow: hidden;
        text-decoration: none;
        transition: all .2s;
        border-radius: .5rem;
    }


    .hp-btn.hp-button--career-list {
        padding: 1rem 1rem;
        min-width: 25rem;
        height: 4.5rem;
        background-color: #1F3C84;
        margin-bottom: 0;
        display: inline-block;
    }

    .career-list__item {
        margin-left: -3rem;
        margin-right: -3rem;
    }

    .career-list__item--type,
    .career-list__item--location,
    .career-list__item--experience {
        flex: 1;
        margin-bottom: 0;
        display: block;
    }
}

@media screen and (min-width: 992px) {
    .hp-btn.hp-button--career-list {
        min-width: 28rem;
    }
}
