.product-benefits__section {
  align-self: center;
  padding: 0;
  margin-top: 5rem;
}

.product-benefits__row {
  padding: 3rem 0;
  justify-content: center;
  align-items: center;
}

.product-benefits__row--payment-reminders {
  justify-content: space-between;
  margin: 3rem 0;
  padding: 0;
}

.product-benefits__row--payment-reminders--2 {
  align-items: self-start;
}

.product-benefits {
  padding: 0 1.6rem;
}

.product-benefits--portal-pro {
  padding: 0 2rem;
}

.product-benefits__sub-heading {
  color: rgba(0, 186, 130, 1);
  margin-bottom: 3rem;
  font-weight: 500;
}

.product-benefits__snippet {
  margin-bottom: 2.5rem;
}

.product-benefits__image {
  max-height: 22rem;
  max-width: 100%;
  margin: 0 auto;
}

.product-benefits__image-cont--recurring-payments-1st {
  text-align: center;
}

.product-benefits__image-cont--recurring-payments-2nd {
  text-align: center;
}

.product-benefits__image-cont--recurring-payments-3rd {
  text-align: center;
}

.product-benefits__image--recurring-payments-3rd {
  max-height: 18rem;
}

.product-benefits__image-container {
  text-align: center;
  max-width: 75%;
  margin: 2rem 0;
}

@media (min-width: 992px) {

  .product-benefits {
    padding: 0 1.6rem;
  }

  .product-benefits__row {
    justify-content: space-between;
  }
  .product-benefits__sub-heading {
    color: rgba(0, 186, 130, 1);
    margin-bottom: 1.5rem;
    font-size: 2.6rem;
    font-weight: 400;
  }

  .product-benefits__schedule-demo {
    display: block;
  }

  .product-benefits__row--recurring-payments-3rd {
    margin-top: 5rem;
  }

  .product-benefits__image-container {
    text-align: unset;
    max-width: unset;
    margin: 0;
  }

  .product-benefits__image {
    max-height: 24rem;
    padding: 0 2rem;
  }

  .product-benefits__image-cont--recurring-payments-3rd {
    text-align: left;
  }

  .product-benefits__image--recurring-payments-3rd {
    max-height: 18rem;
    margin-top: -10rem;
    margin-left: -3rem;
  }

  .product-benefits__row--payment-reminders {
    justify-content: space-between;
    margin: 3rem 0;
    padding: 0;
  }

}

@media (min-width: 1200px) {

  .product-benefits__row--payment-reminders {
    justify-content: flex-start;
  }

  .product-benefits__image {
    max-height: 27rem;
    padding: 0 2rem;
  }

  .product-benefits__image--recurring-payments-3rd {
    max-height: 20rem;
    margin-top: -10rem;
  }

}
