.events__header {
    color: #E2AD00;
    margin-left: 1rem;
}

.grouped-events-cards {
    margin-bottom: 0rem;
    text-decoration: none;
}

.grouped-events-cards--link:hover {
    text-decoration: none;
    color: #E2AD00;
}

.grouped-events-cards--link:hover>.events__card--event,
.grouped-events-cards--link:hover>.events__card--location {
    color: #E2AD00;
    text-decoration: none;
}

.events__card {
    box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .2);
    background-color: white;
    padding: 4rem 4rem;
    margin: 4rem -2rem;
    border-radius: .75rem;
}

.events__card--date {
    margin-bottom: 1.5rem;
    color: #E2AD00;
}

.events__card--event,
.events__card--location {
    font-size: 2.2rem;
}

.events__card--event,
.events__card--location,
.events-logo,
use {
    transition: all .2s;
}

.events__card--location {
    align-self: flex-end;
}

.events__card--image {
    margin: 4rem 0;
}

.events__card--image svg {
    width: 50%;
}

.hp-tag__svg {
    height: 5rem;
}

.hp-tag__svg symbol {
    height: 64px;
    width: 200px;
}

.grouped-events-cards--link:hover .events-logo {
    fill: #E2AD00;
}

.grouped-events-cards--link:hover use {
    fill: #E2AD00;
}

.event__checkbox {
    display: none;
}

.event__label {
    display: block;
    text-align: center;
    transition: all .1s;
    line-height: 0;
    padding: 4rem 0;
    margin: 0;
    font-size: 2.2rem;
}

.event__label:hover {
    cursor: pointer;
    color: #E2AD00;
}


.event__list--group .grouped-events-cards--link:last-of-type .events__card {
    margin-bottom: 0;
}
#newsletter-signup__events {
    margin-top: -20rem;
}


@media screen and (min-width: 992px) {
    .events__card {
        margin: 2rem -1rem;
    }

    .events__card--image {
        margin: 0;
    }

    .events__card--image svg {
        width: 40%;
        margin: 0;
    }
    #newsletter-signup__events {
        margin-top: 0rem;
    }
}

@media screen and (min-width: 1200px) {
    .events__card {
        margin: 2rem -2rem 0 -2rem;
    }

}
