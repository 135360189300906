/* BENEFITS SECTION */


.collection-benefits {
  margin-top: -5rem;
}

.collection-benefits__heading {
  color: #1F3C84;
  margin-bottom: 5rem;
  font-size: 2.4rem;
}

.collection-benefits__heading-2 {
  color: #1F3C84;
  margin-bottom: 5rem;
  font-size: 2.2rem;
  line-height: 1.3;
}

.collection-benefits__snippet {
  font-size: 1.6rem;
  line-height: 1.3;
}

.collection-benefits__snippet:not(:last-of-type) {
  margin-bottom: 3rem;
}

.collection-benefits__img-cont {
  position: static;
}

.collection-benefits__img {
  right: -13rem;
  height: 50%;
  position: absolute;
  top: 52rem;
}

.collection-payment__img {
  height: 17%;
}

/* FEATURES SECTION */


.collection-features {
  margin-top: 9rem;
}

.collection-features__row {
  margin-bottom: 15rem;
}

.collection-features__heading {
  color: #1F3C84;
  margin-bottom: 3rem;
  width: 85%;
  font-size: 2.4rem;
}

.collection-features__snippet {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-bottom: 2.5rem;
}
.collection-features__snippet .industry-link {
  font-weight: 600;
}

.collection-features__snippet:last-of-type {
  margin-bottom: 4rem;
}

.collection-features__img-cont {
  display: flex;
  justify-content: center;
}

.collection-features__img {
  width: 100vw;
  height: auto;
  align-self: flex-start;
}

.collection-boost-productivity__row {
  justify-content: center;
  align-items: center;
}

.collection-boost-productivity__img-cont {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.collection-boost-productivity__img {
  width: 100%;
  max-width: 28rem;
  align-self: flex-start;
}

.collection-boost-productivity__header {
  color: var(--healpay-pro-blue);
  font-size: 2.8rem;
  margin-bottom: 5rem;
}

.collection-boost-productivity__snippet {
  margin-bottom: 2rem;
  font-size: 2rem;
  line-height: 2.7rem;
}

/* ADDITIONAL FEATURES SECTION */

.collection-cards__section {
  height: unset;
  padding: 5rem 0 0 0;
  margin-bottom: 10rem;
  background-color: #f6f7fa;
  transform: skewY(-4deg);
}

.collection-cards__container {
  transform: skewY(4deg);
}

.collection-cards__header {
  color: #1F3C84;
  margin-bottom: 2rem;
}

.collection-cards__row {
  margin: 3rem 0 5rem 0;
  justify-content: center;
}

.collection-testimony {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 2rem;
}

.collection-testimony__header {
  color: var(--healpay-pro-blue);
  margin-bottom: 1rem;
}

.collection-testimony__snippet {
  margin-bottom: 3rem;
}

.collection-testimony__link-row {
  display: flex;
}

.collection-testimony__link {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 6rem;
  margin: 0 2rem;
  transition: all .2s;
}

.collection-testimony__link:hover {
  box-shadow: 0 1.2rem 1rem rgba(0, 0, 0, 0.2);
  transform: translateY(-.5rem);
}


/* CONTACT SALES SECTION */

.contact-sales__header--char-orgs {
  color: #1F3C84;
}

.contact-sales__btn--char-orgs {
  background-color: #1F3C84;
}

/* @media (min-width: 400px) {

  .collection-features__img {
    border-radius: .7rem;
    width: 110%;
    margin-left: -2rem;
  }

} */

@media (min-width: 575px) {

  .collection-benefits__img {
    border-radius: .7rem;
  }

  .collection-cards__section {
    padding-top: 10rem;
  }

  .collection-features__img {
    border-radius: .7rem;
    width: 100%;
  }

}

@media (min-width: 767px) {


  .collection-benefits {
    margin-top: 5rem;
  }

  .collection-payment__img {
    height: 31%;
  }

  .collection-testimony__link {
    width: 8rem;
  }

  .collection-boost-productivity__row {
    justify-content: center;
  }

  .collection-boost-productivity__header {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }

  .collection-boost-productivity__img-cont {
    justify-content: flex-end;
    margin-top: 0;
  }

  .collection-cards__row {
    margin-top: 3rem;
  }

  .collection-cards__snippet {
    width: 75%;
  }

  .collection-features__row {
    justify-content: center;
  }

  .collection-features__img-cont {    
    justify-content: center;
    display: flex;
  }

  .collection-features__img {
    border-radius: .7rem;
    width: 80%;
  }

}


@media (min-width: 992px) {

  .industries-hero__sub-heading--collections {
    width: 90%;
  }

  .collection-benefits {
    margin-top: -10rem;
    margin-bottom: 10rem;
  }

  .collection-benefits__heading {
    color: #1F3C84;
    margin-bottom: 5rem;
    width: 75%;
    font-size: 2.8rem;
  }
  
  .collection-benefits__heading-2 {
    color: #1F3C84;
    margin-bottom: 5rem;
    font-size: 2.4rem;
  }
  
  .collection-benefits__snippet {
    font-size: 2rem;
  }

  .collection-benefits__img {
    right: -12rem;
    height: 44rem;
    top: -10rem;
  }

  .collection-cards__section {
    padding-top: 15rem;
  }

  .collection-cards__row {
    margin-top: 5rem;
    margin-bottom: 0;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }

  .collection-cards__snippet {
    width: 55%;
  }

  .collection-features__row {
    justify-content: unset;
  }

  .collection-features__heading {
    margin-bottom: 3rem;
    font-size: 2.8rem;
  }

  .collection-features__snippet {
    font-size: 2rem;
    line-height: 2.7rem;
  }

  .collection-features__snippet:not(:last-of-type) {
    margin-bottom: 2.5rem;
  }

  .collection-features__img {
    right: -25rem;
    height: 90%;
    width: unset;
    position: absolute;
    top: 0rem;
    border-radius: .7rem;
  }

  .collection-boost-productivity__row {
    justify-content: space-around;
  }

  .collection-boost-productivity__header {
    margin-bottom: 5rem;
  }

  .collection-boost-productivity__img {
    width: 100%;
  }

  .feature-mobile-header {
    display: none;
  }

}

@media (min-width: 1200px) {

  .collection-benefits {
    margin-top: -9rem;
  }

  .collection-benefits__img {
    right: -12rem;
    height: 44rem;
    top: -10rem;
  }

  .collection-cards__row {
    margin-bottom: 5rem;
  }
  
  .collection-features__heading {
    margin-bottom: 5rem;
  }

  .collection-features__snippet:not(:last-of-type) {
    margin-bottom: 3rem;
  }

  .collection-features__img {
    right: -20rem;
    top: 0rem;
    height: 100%;
  }

  .collection-boost-productivity__img {
    width: 70%;
  }

}

@media (min-width: 1500px) {

  .collection-benefits__img {
    top: -10rem;
    right: -15rem;
    height: 45rem;
  }

  .collection-payment__img {
    height: 37%;
  }

  .collection-benefits {
    margin-top: -12rem;
  }

}

