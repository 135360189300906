.blog-post-header__container .category-navigation__link:hover {
  color: #707070;
}

.blog-post-header__container .category-navigation__link.active {
  color: #E2AD00;
}

.blog-post-list {
  padding: 0;
}

.blog-post-list__recent-posts {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 600;
  margin-left: 5rem;
  margin-bottom: 5rem;
  color: #707070;
}

.blog-post-list__row {
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
}

.blog-card {
  margin: 0 auto;
  margin-bottom: 5rem;
  background-color: #fff;
  box-shadow: 0 2px 7px -1px #C1C7D0;
  transition: .3s ease-in-out;
  width: 40rem;
  max-width: 95%;
  min-height: 48rem;
  border-radius: 3px;
  overflow: hidden;
  display: block;
}

.blog-card:hover {
  text-decoration: none;
  color: #212529;
}

.blog-card:hover {
  -webkit-transform: scale(1.015);
  -ms-transform: scale(1.015);
  transform:  scale(1.015);
}

.blog-card__url {
  text-decoration: none;
}

.blog-card-img {
  height: 45%;
  background-color: white;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 22rem;
}

.blog-card__info {
  height: 30rem;
  padding: 2rem 2rem 3rem 2rem;
}

.blog-card__info--top {
  height: 50%;
}

.blog-card__category {
  text-transform: uppercase;
  color: #E2AD00;
  font-size: 1.4rem;
  text-decoration: none;
  line-height: 1.2rem;
  margin: .5rem 0;
}

.blog-card__title {
  height: 40%;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 3rem;
}

.blog-card__title:hover {
  color: #707070;
}

.blog-card__title-link:link,
.blog-card__title-link:visited {
  font-size: 1.8rem;
  line-height: 2rem;
  text-decoration: none;
}

.blog-card__title-link:hover {
  color: #707070; 
}

.blog-card__title-link:hover {
  color: #606060;
}

.blog-card__metadata {
  display: flex;
  justify-content: space-between;
  padding-right: 2rem;
}

.blog-card__author, .blog-card__date {
  display: inline-block;
  font-size: 1.5rem;
  line-height: 2;
}

.blog-card__excerpt {
  font-size: 1.8rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-navigation {
  padding-bottom: 10rem;
}

@media screen and (min-width: 768px) {

  .blog-post-list__recent-posts {
    font-size: 1.8rem;
    margin-left: 5rem;
  }

  .blog-card {
    width: 100%;
  }

  .blog-card__title-link:link,
  .blog-card__title-link:visited {
    font-size: 2rem;
    line-height: 2.3rem;
  }

}

@media screen and (min-width: 992px) {
  .blog-card {
    width: 100%;
  }

  .blog-post-list__row {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .blog-card {
    max-width: 90%;
    margin-bottom: 8rem;
  }
}
