
  .rainbow {
    height: 8rem;
    position: absolute;
    top: 30rem;
    right: -50rem;
    transform: skewY(-10deg);
  }

  .rainbow__segment {
    width: 50rem;
    height: 3.5rem;
  }

  .rainbow__segment--1 {
    background-color: #56DBA1;
    height: 2rem;
  }

  .rainbow__segment--2 {
    background-color: #00BA82;
    height: 3rem;
  }

  .rainbow__segment--3 {
    background-color: #F5D879;
    height: 5rem;
  }

  .rainbow__segment--4 {
    background-color: #F2F2F2;
    height: 8rem;
  }

  .rainbow--blur {
    filter: blur(2.7rem);
    position: absolute;
    left: 1rem;
    top: 0rem;
    z-index: -1;
    text-align: end;
  }

  .rainbow__segment--1--blur {
    width: 20rem;
    position: absolute;
    right: 3rem;
    top: 0;
  }
  
  .rainbow__segment--2--blur {
    width: 33rem;
    position: absolute;
    right: 0;
    top: 2rem;
  }

  .rainbow__segment--3--blur {
    width: 43rem;
    position: absolute;
    right: 0;
    top: 5rem;
  }

  .rainbow__segment--4--blur {
    width: 50rem;
    position: absolute;
    right: 0;
    top: 10rem;
  }

  .rainbow.rainbow--software {
    height: 8rem;
    position: absolute;
    top: 7rem;
    right: 0;
    transform: skewY(-10deg);
    z-index: -1;
  }
  
  .rainbow--software .rainbow__segment {
    position: absolute;
    right: 0;
  }
  
  .rainbow--software .rainbow__segment--1 {
    width: 30vw;
    height: 1.4rem;
  }
  
  .rainbow--software .rainbow__segment--2 {
    top: 1.4rem;
    width: 45vw;
    height: 2rem;
  }
  
  .rainbow--software .rainbow__segment--3 {
    top: 3.4rem;
    width: 78vw;
    height: 2.4rem;
  }
  
  .rainbow--software .rainbow__segment--4 {
    top: 5.8rem;
    width: 88vw;
    height: 3.4rem;
  }

  .rainbow--software .rainbow__segment--1-pro {
    background-color: #000000;
  }

  .rainbow--software .rainbow__segment--2-pro {
    background-color: #1f3c84;
  }

  .rainbow--software .rainbow__segment--3-pro {
    background-color: #0071dc;
  }

  .rainbow--software .rainbow__segment--4-pro {
    background-color: #a2d2ff;
  }

  .rainbow--error {
    display: none;
  }


  @media (min-width: 400px) {
    .rainbow.rainbow--software {
      top: 10rem;
    }

    .rainbow--software .rainbow__segment--1 {
      width: 30vw;
    }
    
    .rainbow--software .rainbow__segment--2 {
      width: 45vw;
    }
    
    .rainbow--software .rainbow__segment--3 {
      width: 78vw;
    }
    
    .rainbow--software .rainbow__segment--4 {
      width: 88vw;
    }
  }

  @media (min-width: 575px) {

    .rainbow.rainbow--software {
      height: 8rem;
      position: absolute;
      top: 43.2rem;
      right: 0;
      transform: skewY(-10deg);
      z-index: -1;
    }
  
    .rainbow--software .rainbow__segment {
      position: absolute;
      right: 0;
    }
  
    .rainbow--software .rainbow__segment--1 {
      width: 25vw;
      height: 1.6rem;
    }
  
    .rainbow--software .rainbow__segment--2 {
      top: 1.6rem;
      width: 45vw;
      height: 2.4rem;
    
    }
  
    .rainbow--software .rainbow__segment--3 {
      top: 3.9rem;
      width: 65vw;
      height: 3.3rem;
    }
  
    .rainbow--software .rainbow__segment--4 {
      top: 7.2rem;
      width: 80vw;
      height: 5.1rem;
    }
  }
  
  @media (min-width: 768px) {
    .rainbow.rainbow--software {
      height: 8rem;
      top: 46rem;
      right: 0;
      z-index: -1;
    }
  
    .rainbow--software .rainbow__segment {
      position: absolute;
      right: 0;
    }
  
    .rainbow--software .rainbow__segment--1 {
      width: 20vw;
      height: 2rem;
    }
  
    .rainbow--software .rainbow__segment--2 {
      top: 1.8rem;
      width: 40vw;
      height: 3rem;
    }
  
    .rainbow--software .rainbow__segment--3 {
      top: 4.5rem;
      width: 70vw;
      height: 4rem;
    }
  
    .rainbow--software .rainbow__segment--4 {
      top: 8rem;
      width: 80vw;
      height: 5rem;
    }
  }

    
  @media (min-width: 992px) {
    .rainbow.rainbow--software {
      height: 8rem;
      top: 42rem;
      right: -1rem;
      z-index: -1;
    }
  
    .rainbow--software .rainbow__segment {
      position: absolute;
      right: 0;
    }
  
    .rainbow--software .rainbow__segment--1 {
      height: 2rem;
      width: 30vw;
    }
  
    .rainbow--software .rainbow__segment--2 {
      height: 3rem;
      width: 40vw;
      top: 1.7rem;
    }
  
    .rainbow--software .rainbow__segment--3 {
      height: 4rem;
      width: 56vw;
      top: 4.3rem;
    }
  
    .rainbow--software .rainbow__segment--4 {
      height: 6rem;
      width: 65vw;
      top: 7.9rem;
    }

    .rainbow--error {
      display: block;
    }

  }

  
  @media (min-width: 1200px) {
    .rainbow.rainbow--software {
      top: 36rem;
      right: -1rem;
    }
  
    .rainbow--software .rainbow__segment {
      position: absolute;
      right: 0;
    }
  
    .rainbow--software .rainbow__segment--1 {
      width: 25vw;
      height: 1.75rem;
    }

    .rainbow--software .rainbow__segment--2 {
      width: 40vw;
      height: 2.62rem;
    }
  
    .rainbow--software .rainbow__segment--3 {
      width: 57vw;
      height: 4.375rem;
    }
  
    .rainbow--software .rainbow__segment--4 {
      width: 65vw;
      height: 7rem;
    }
  }

  @media (min-width: 1400px) {
    .rainbow.rainbow--software {
      top: 34rem;
    }
  
    .rainbow--software .rainbow__segment--2 {
      width: 40vw;
    }
  
    .rainbow--software .rainbow__segment--3 {
      width: 51vw;
    }
  
    .rainbow--software .rainbow__segment--4 {
      width: 60vw;
    }
  }

  @media (min-width: 1500px) {
    .rainbow.rainbow--software {
      top: 3rem;
      right: -58rem;
    }
  
    .rainbow--software .rainbow__segment--1 {
      width: 35vw;
    }
  
    .rainbow--software .rainbow__segment--2 {
      width: 55vw;
    }

    .rainbow__segment.rainbow__segment--2--vterm {
      width: 45vw;
    }

    .rainbow__segment.rainbow__segment--2--ivr {
      width: 50vw;
    }
  
    .rainbow--software .rainbow__segment--3 {
      width: 63vw;
    }
  
    .rainbow--software .rainbow__segment--4 {
      width: 69vw;
    }
  }

  @media (min-width: 1800px) {
    .rainbow.rainbow--software {
      top: 0;
      right: -80rem;
    }
  }

