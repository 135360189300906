.slanted-product__section {
  transform: skewY(-3deg);
  padding: 10rem 0 5rem 0;
  background-color: #f0f7fd
}

.slanted-product__container {
  transform: skewY(3deg);
}

.slanted-product__header {
  color: var(--healpay-green-dark);
  text-align: center;
  margin-bottom: 5rem;
  display: none;
}

.slanted-product__snippet-container {
  margin: 0 auto; 
}

.slanted-product__snippet {
  font-size: 2rem;
  margin-bottom: 4rem;
  text-align: center;
}

.slanted-product__snippet span {
  font-weight: 700;
}

.slanted-product-feature__container {
  width: 50%;
  margin: 0 auto;
  display: none;
} 

.slanted-product-feature {
  display: flex;
  padding: 1.2rem 0;
  justify-content: flex-start;
  align-items: center;
}

.slanted-product-feature:not(:last-of-type) {
  border-bottom: 1px solid #88DDC3;
}

.slanted-product-feature__snippet {
  margin: 0;
  margin-left: 2rem;
  font-weight: 600;
  font-size: 2rem;
  color: var(--healpay-green-dark);
  white-space: nowrap;
}
/* 
.slanted-product-feature__snippet {
  white-space: nowrap;
} */

.slanted-product-feature__img {
  width: 2rem;
}

@media (min-width: 992px) {

  .recurring__header-image {
      width: 80%;
      margin-top: 3rem;
      margin-left: 5rem;
  }

  .slanted-product__section {
      background-color: #f0f7fd
  }

  .slanted-product-feature__container {
    display: block;
  }

  .slanted-product__icon-container {
    justify-content: center;
    text-align: center;
    padding-top: 8rem;
  }

  .slanted-product__icon-container--account-grouping {
    padding-top: 5rem;
  }

  .slanted-product__icon-container--payment-reminders {
    padding-top: 3rem;
  }

  .slanted-product__header {
      font-size: 3.5rem;
      font-weight: bold;
      display: block;
  }

}