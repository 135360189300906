.brand-kit-header__container {
    margin-top: 5rem;
    padding: 0 3rem;
}

.brand-kit-header__container .category-navigation__link.active {
    color: #E2AD00;
}

.brand-kit-header__container .category-navigation__link:hover {
    color: #707070;
}

.brand-kit-header {
    flex-direction: column;
    padding: 1rem 0;
}

.brand-kit-header__title {
    color: #E2AD00;
}

.brand-kit-header__icon {
    width: 2rem;
    height: 2rem;
}

.brand-kit-header__button {
    display: flex;
    margin-top: 3rem;
    width: 22rem;
}

.brand-kit-values__container {
    margin-top: 8rem;
    padding: 0 3rem;
}

.brank-kit-values__title {
    font-weight: 500;
    color: #E2AD00;
}

.brank-kit-values__row {
    justify-content: space-between;
    margin-bottom: 5rem;
}

.brank-kit-values__row-item {
    padding: 0;
    margin-bottom: 3rem;
}

.brank-kit-values__title {
    text-transform: uppercase;
    margin-bottom: 2rem;
}

.brand-kit-colors__container {
    margin-bottom: 10rem;
    padding: 0 3rem;
}

.brand-kit-colors__col {
    padding: 0;
    margin-bottom: 3rem;
    width: 41rem;
}

.brand-kit-colors__title {
    text-transform: uppercase;
    margin-bottom: 2rem;
    padding: none;
    color: #E2AD00;
    font-weight: 500;
}

.brand-kit-colors__color-row {
    flex-direction: column;
    margin-left: 0;
}

.brand-kit-colors__color-box-cont {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    width: 100%;
}

.brand-kit-colors__color-box-cont:not(:last-of-type) .brand-kit-colors__color-box {
    margin-right: 2rem;
}

.brand-kit-colors__color-box-cont:first-of-type .brand-kit-colors__color-box {
    width: 100%;
}

.brand-kit-colors__color-box {
    display: flex;
    width: 15rem;
}

.brand-kit-colors__color-box:not(:last-of-type) {
    margin-bottom: 1.5rem;
}

.brand-kit-colors__color-shape {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    box-shadow: 0 .5rem .5rem rgba(0,0,0,.2);
    margin-right: 1rem;
}

.brand-kit-colors__color-shape--hp-shade { background-color: #008961; }
.brand-kit-colors__color-shape--hp-green { background-color: #00BA82; }
.brand-kit-colors__color-shape--hp-accent { background-color: #56DBA1; }
.brand-kit-colors__color-shape--dk-blue { background-color: #1F3C84; }
.brand-kit-colors__color-shape--blue { background-color: #0071DC; }
.brand-kit-colors__color-shape--light-blue { background-color: #A2D2FF; }
.brand-kit-colors__color-shape--gold { background-color: #E2AD00; }
.brand-kit-colors__color-shape--gold-accent { background-color: #F5D879; }
.brand-kit-colors__color-shape--light-gold { background-color: #F2EFE9; }

.brand-kit-colors__color-info {
    display: flex;
    flex-direction: column;
}

.brand-kit-colors__name {
    white-space: nowrap;
}

.brand-kit-logo__container {
    padding: 0 3rem;
}

.brand-kit-logo__row {
    flex-direction: column;
    justify-content: center;
}

.brand-kit-logo__title {
    color: #E2AD00;
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.brand-kit-logo-require__row {
    display: flex;
    flex-direction: column;
    margin-bottom: 7rem;
}

.brand-kit-logo-require__logo-cont {
    display: flex;
}

.brand-kit-logo-require__healpay-logo {
    width: 11rem;
    height: 11rem;
    margin-left: 0;
    margin-bottom: 4rem;
}

.brand-kit-logo-require__healpay-logo--black {
    margin-right: 4rem;
}

.brand-kit-logo-require__healpay-logo--white {
    margin-right: 5rem;
}

.brand-kit-logo-require__points {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -1rem;
}

.brand-kit-logo-require__points li {
    font-size: 1.6rem;
}

.brand-kit-logo-avoid__row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10rem;
}

.brand-kit-logo-avoid__logo-round-square {
    height: 10.5rem;
    margin-right: 7rem;
    margin-bottom: 2rem;
}

.brand-kit-logo-avoid__small-logos {
    margin-top: 1rem;
}

.brand-kit-logo__healpay-logo-small-circle-cont {
    margin-bottom: 5rem;
}

.brand-kit-logo__healpay-logo-small-circle {
    max-width: 30rem;
}

.brand-kit-logo-avoid__points {
    padding-left: 2rem;
}

.brand-kit-logo-avoid__points li {
    font-size: 1.6rem;
}


@media screen and (min-width: 400px) {

    .brand-kit-colors__color-row {
        flex-direction: row;
        margin-left: -1.5rem;
    }

    .brand-kit-colors__color-box-cont {
        width: unset;
    }

}


@media screen and (min-width: 575px) {

    .brand-kit-colors__color-box-cont:first-of-type .brand-kit-colors__color-box {
        width: 17.5rem;
    }

    .brand-kit-colors__color-box-cont:not(:last-of-type) .brand-kit-colors__color-box {
        margin-right: 1rem;
    }

    .brand-kit-colors__color-box {
        display: flex;
        width: 15rem;
    }

    .brand-kit-logo__title {
        margin-bottom: 5rem;
    }

    .brand-kit-logo-require__healpay-logo {
        width: 13rem;
        height: 13rem;
    }

    .brand-kit-logo-avoid__logo-round-square {
        height: 12.5rem;
    }

    .brand-kit-logo__healpay-logo-small-circle {
        max-width: 35rem;
    }
}

@media screen and (min-width: 768px) {

    .brank-kit-values__row-item {
        max-width: 45%;
    }

    .brand-kit-colors__color-box-cont:not(:last-of-type) .brand-kit-colors__color-box {
        margin-right: 7rem;
    }
    
    .brand-kit-logo-require__healpay-logo {
        margin-left: -2rem;
    }

    .brand-kit-logo-require__row {
        flex-direction: row;
        margin-bottom: 10rem;
    }

    .brand-kit-logo-require__healpay-logo {
        margin-bottom: 0;
    }

    .brand-kit-logo-require__points {
        margin-left: 0;
    }

    .brand-kit-logo-avoid__row {
        flex-direction: row;
    }

    .brand-kit-logo-avoid__logo-round-square {
        margin-left: -1rem;
    }

}

@media screen and (min-width: 992px) {

    .brand-kit-header__container {
        padding: 0 2rem;
    }

    .brand-kit-header__title {
        padding: 0 1rem;
    }

    .brand-kit-header__snippet {
        padding: 0 1rem;
    }

    .brank-kit-values__row {
        margin-bottom: 10rem;
    }

    .brank-kit-values__row-item {
        margin-bottom: 0;
    }

    .brank-kit-values__row-item {
        max-width: 30rem;
    }

    .brand-kit-colors__color-box-cont:not(:last-of-type) .brand-kit-colors__color-box {
        margin-right: 10rem;
    }

    .brand-kit-logo__container {
        padding: 0 3rem;
    }

    .brand-kit-logo-avoid__row {
        margin-bottom: 20rem;
    }

    .brand-kit-logo-require__healpay-logo {
        margin-left: 0;
    }

}


@media screen and (min-width: 1200px) {

    .brank-kit-values__row-item {
        max-width: 35rem;
    }

    .brand-kit-colors__color-box-cont:not(:last-of-type) .brand-kit-colors__color-box {
        margin-right: 15rem;
    }

    .brand-kit-logo-require__healpay-logo {
        margin-left: -1rem;
    }

}