.account-grouping__header-image {
  width: 60%;
  margin-top: 4rem;
  float: right;
  margin-right: -2rem;
}

.account-grouping-features__sub-heading {
  color: rgba(0, 186, 130, 1);
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.account-grouping-cards__title {
  font-weight: 500;
  margin-bottom: 3rem;
}

.account-grouping-features__snippet {
  font-size: 2rem;
  margin-bottom: 6rem
}

.account-grouping-benefits__section {
  margin: 10rem 0 5rem 0;
}

.account-grouping-cards__box-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.account-grouping__box {
  width: 100%;
  height: 15rem;
  padding: 3rem 1.5rem 0 1.5rem;
  font-size: 1.8rem;
  line-height: 1.3;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
}

.account-grouping__box--light-green {
  background-color: #66deaa;
}

.account-grouping__box--green {
  background-color: #00ce90;
}

.account-grouping__box--yellow {
  background-color: #f5d879;
}

.account-grouping__box--gray {
  background-color: #f2f2f2;
}

.account-grouping__box--light-green::after,
.account-grouping__box--green::after,
.account-grouping__box--yellow::after {
  content: " ";
  position: absolute;
  right: 25px;
  bottom: -11px;
  border-right: 12px solid transparent;
  border-top: 13px solid #66deaa;
  border-left: 12px solid transparent;
  border-bottom: none;
}

.account-grouping__box--green::after {
  border-top-color: #00ce90;
}

.account-grouping__box--yellow::after {
  border-top-color: #f5d879;
}

.account-grouping__box--green::before,
.account-grouping__box--yellow::before,
.account-grouping__box--gray::before {
  content: " ";
  position: absolute;
  right: 25px;
  top: 0;
  border-right: 13px solid transparent;
  border-top: 12px solid #fff;
  border-left: 13px solid transparent;
  border-bottom: none;
}
.slanted-product__snippet--adjust {
  font-size: 2rem;
  margin-bottom: 4rem;
  text-align: center;
}


@media (min-width: 400px) {
  .account-grouping__header-image {
    margin-top: -4.5rem;
    float: right;
    margin-right: -2rem;
    width: 50%;
  }

  .account-grouping-cards__box-group {
    align-items: center;
  }

  .account-grouping__box {
    width: 80%;
  }
}

@media (min-width: 575px) {

  .account-grouping__header-image {
    width: 50%;
    margin-top: -5rem;
    float: right;
    margin-right: -5rem;
  }

  .account-grouping-cards__title {
    margin-left: 4rem;
  }

}

@media (min-width: 768px) {

  .account-grouping__header-image {
    width: 40%;
    margin-right: 0;
  }

  .account-grouping-cards__title {
    margin-left: 10rem;
  }

  .account-grouping__box {
    width: 60%;
  }
  .account-grouping-features__sub-heading {
    color: rgba(0, 186, 130, 1);
    margin-bottom: 1.5rem;
    font-weight: 400;
  }

}

@media (min-width: 992px) {

  .hero-heading--account-grouping {
    width: 90%;
  }

  .hero-sub-heading--account-grouping {
    width: 80%;
  }

  .account-grouping__header-image {
    width: 65%;
    margin-top: 6rem;
    margin-left: 7rem;
    float: left;
  }
  .slanted-product__snippet--adjust {
    display: none;
  }
  .account-grouping-features__feature {
    padding: 0;
  }

  .account-grouping-benefits__section {
    margin: 10rem 0 5rem 0;
  }

  .account-grouping-cards__title {
    margin-left: unset;
  }

  .account-grouping-cards__box-group {
    flex-direction: row;
  }

  .account-grouping__box {
    width: 24%;
    height: 12rem;
    padding: 2rem 1.5rem;
    font-size: 1.8rem;
    line-height: 1.3;
    margin-right: 1.5rem;
    margin-bottom: 0;
    position: relative;
  }

  .account-grouping__box--light-green::after,
  .account-grouping__box--green::after,
  .account-grouping__box--yellow::after {
    right: -6px;
    top: 16px;
    bottom: unset;
    border-right: none;
    border-top: 6px solid transparent;
    border-left: 6px solid #66deaa;
    border-bottom: 6px solid transparent;
  }

  .account-grouping__box--green::after {
    border-left-color: #00ce90;
  }

  .account-grouping__box--yellow::after {
    border-left-color: #f5d879;
  }
  
  .account-grouping__box--yellow::after {
    border-left-color: 6px solid #f5d879;
  }

  .account-grouping__box--green::before,
  .account-grouping__box--yellow::before,
  .account-grouping__box--gray::before {
    content: " ";
    position: absolute;
    left: 0px;
    top: 16px;
    border-right: none;
    border-top: 6px solid transparent;
    border-left: 6px solid white;
    border-bottom: 6px solid transparent;
  }

  .account-grouping__snippet {
    display: none;
  }

}

@media (min-width: 1200px) {

  .hero-heading--account-grouping {
    width: 80%;
  }

  .account-grouping__header-image {
    width: 65%;
    margin-top: 3rem;
    margin-left: 10rem;
  }

  .account-grouping-benefits__section {
    margin: 15rem 0 10rem 0;
  }

  .account-grouping__box {
    font-size: 2rem;
  }

}