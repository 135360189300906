
.free-trial-slant {
    background-color: #e5f1fb;
}

.free-trial-slant::after {
    background-color: #e5f1fb;
}

.free-trial__container {
    margin: 5rem auto;
}

.free-trial__row {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.free-trial__title {
    color: #1F3C84;
}

.free-trial__snippet {
    text-align: center;
    margin: 1rem 0;
}

.free-trial__form {
    background-color: #A2D2FF;
    padding: 2rem;
    margin-bottom: 15rem;
    border-radius: 1rem;

}

.free-trial__input {
    border-radius: 3px;
    transition: all .2s ease;
    border: 4px transparent;
    display: block;
    padding: 9px 11px;
    width: 100%;
    margin: 0;
    font-size: 1.3rem;
    line-height: 0;
}

.free-trial__input:focus {
    box-shadow: 0px 3px 6px rgba(0,0,0,.25);
}

.free-trial__form label {
    margin: 1rem auto;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.free-trial__select {
    background-color: white;
    border: none;
    border-radius: .3rem;
    display: block;
    padding: 9px 11px;
    width: 100%;
    margin: 0;
    font-size: 1.3rem;
    line-height: 0;
    transition: all .1s ease;
}

.free-trial__select:focus {
    box-shadow: 0px 3px 6px rgba(0,0,0,.25);
}


.free-trial__button {
    width: 100%;
    margin: 4rem 0;
}