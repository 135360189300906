/* BENEFITS SECTION */

.charitable-orgs-benefits {
  margin-top: -15rem;
  margin-bottom: 8rem;
}

.charitable-orgs-benefits__heading {
  color: #1F3C84;
  margin-bottom: 5rem;
  width: 80%;
  font-size: 2.4rem;
}

.charitable-orgs-benefits__snippet {
  font-size: 1.6rem;
  line-height: 2.7rem;
  margin-bottom: 2rem;
}

.charitable-orgs-benefits__img-cont {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
}

.charitable-orgs-benefits__img {
  width: 100vw;
  align-self: flex-start;
  height: auto;
}


/* FEATURES SECTION */


.charitable-orgs-features {
  background-color: #f6f7fa;
}

.charitable-orgs-features__container {
  padding: 8rem 2rem 15rem 2rem;
}

.charitable-orgs-features__header {
  text-align: center;
  margin-bottom: 10rem;
  color: #1F3C84;
  font-size: 2.8rem;
}

.charitable-orgs-features__row {
  justify-content: space-around;
}

.charitable-orgs-features__feature-cont {
  margin-bottom: 7rem;
}


.charitable-orgs-features__sub-header-cont {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.charitable-orgs-features__sub-header {
  font-size: 2.5rem;
  color: #1F3C84;
  margin-bottom: 0; 
}

.charitable-orgs-features__icon {
  width: 1.8rem;
  margin: 0 0 0 2.2rem;
}

.charitable-orgs-features__snippet {
  /* width: 85%; */
}

.charitable-orgs-features__btn:link,
.charitable-orgs-features__btn:visited {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1F3C84;
  padding: .7rem 4rem;
}

.charitable-orgs-features__btn:hover,
.charitable-orgs-features__btn:active {
  background-color: rgba(0, 0, 0, 1);
  transform: translate(-50%, -3px);
}

.charitable-orgs-features__btn:active {
  transform: translate(-50%, -1px);
}


/* BUNDLE PRODUCTS SECTION */

.charitable-orgs-bundle {
  padding: 10rem 0 5rem 0;
}

.charitable-orgs-bundle__header {
  text-align: center;
  margin-bottom: 7rem;
  color: #1F3C84;
  font-size: 2.8rem;
}


/* ADDITIONAL FEATURES SECTION */
.slanted-section__orgs {
  background: #f5f7fa;
  transform: skewY(-4deg);
  position: relative;
  padding: 10rem 2rem;
  margin: 5rem 0;
}

.slanted-section.charitable-orgs-additional-features {
  height: 88rem;
  padding: 10rem 0;
  margin: 0;
  background-color: #f6f7fa;
  transform: skewY(-4deg);
}

.charitable-orgs-additional-features__container {
  transform: skewY(4deg);
}

.charitable-orgs-additional-features__row {
  margin-top: 7rem;
}

.charitable-orgs-additional-features__heading-cont {
  margin-bottom: 5rem;
}

.charitable-orgs-additional-features__heading {
  text-align: center;
  color: #1F3C84;
  margin-bottom: 2.5rem;
}

.charitable-orgs-additional-features__heading-lower {
  text-align: center;
}

.charitable-orgs-additional-features__sub-heading {
  margin-bottom: 4rem;
  color: #1F3C84;
  font-size: 2.5rem;
}

.charitable-orgs-additional-features__snippet-cont {
  display: flex;
  align-items: baseline;
}

.charitable-orgs-additional-features__snippet {
  margin-bottom: 4rem;
  line-height: 2.4rem;
  padding-left: 2rem;
}

.charitable-orgs-additional-features__icon {
  width: 1.6rem;
  position: relative;
  top: .3rem;
}

.charitable-orgs-additional-features__img-cont {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.charitable-orgs-additional-features__img {
  width: 100vw;
  height: auto;
  align-self: flex-start;
}


/* CONTACT SALES SECTION */

.contact-sales__header--char-orgs {
  color: #1F3C84;
}

.contact-sales__btn--char-orgs {
  background-color: #1F3C84;
}


@media screen and (min-width: 400px) {

  .industries-hero__heading.industries-hero__heading--charitable-orgs {
    width: 95%;
  }

}

@media screen and (min-width: 575px) {

  .charitable-orgs-benefits__heading {
    width: 75%;
  }

  .charitable-orgs-benefits__img {
    border-radius: .7rem;
    width: 100%;
  }

  .charitable-orgs-additional-features__img {
    width: 100%;
    border-radius: .7rem;
  }
  
}

@media screen and (min-width: 768px) {

  .charitable-orgs-benefits__row {
    justify-content: center;
  }

  .charitable-orgs-benefits__heading {
    color: #1F3C84;
    margin-bottom: 5rem;
    width: 80%;
    font-size: 2.8rem;
  }
  
  .charitable-orgs-benefits__snippet {
    font-size: 2rem;
    line-height: 2.7rem;
    margin-bottom: 2rem;
  }

  .charitable-orgs-benefits__img {
    width: 80%;
  }

  .charitable-orgs-additional-features__row {
    justify-content: center;
  }

  .charitable-orgs-additional-features__img {
    /* margin-left: 26rem; */
    width: 80%;
  }

}

@media screen and (min-width: 992px) {

  .charitable-orgs-benefits__img-cont {
    margin-top: 0;
  }

  .charitable-orgs-benefits__img {
    right: -25rem;
    height: 40rem;
    top: -10rem;
    position: absolute;
    width: unset;
  }

  .charitable-orgs-features__header {
    text-align: center;
    margin-bottom: 10rem;
    color: #1F3C84;
    font-size: 2.8rem;
  }

  .charitable-orgs-features__snippet {
    width: 85%;
  }

  .charitable-orgs-additional-features__img-cont {
    height: 50rem;
    display: block;
  }

  .charitable-orgs-additional-features__img {
    position: relative;
    top: 5rem;
    right: 0rem;
    max-height: 80%;
    width: unset;
    margin-left: 0;
  }

  .contact-sales__section--charitable-orgs {
    padding: 15rem 0 25rem 0; 
  }
}


@media screen and (min-width: 1200px) {

  .industries-hero__heading.industries-hero__heading--charitable-orgs {
    width: 90%;
  }

  .charitable-orgs-benefits {
    margin-top: -9rem;
  }

  .charitable-orgs-benefits__heading {
    width: 90%;
  }

  .charitable-orgs-benefits__img {
    right: -15rem;
    height: 40rem;
    top: -10rem;
    position: absolute;
    width: unset;
  }

  .charitable-orgs-additional-features__img {
    right: -5rem;
    top: 0;
    max-height: 85%;
  }

}

@media screen and (min-width: 1500px) {

  .charitable-orgs-benefits {
    margin-top: -15rem;
  }

  .charitable-orgs-benefits__img {
    right: -20rem;
    height: 40rem;
    top: -10rem;
  }

  .charitable-orgs-additional-features__img {
    /* right: -15rem; */
  }
}