.industries-hero__row {
  position: relative;
}

.industries-hero {
  height: 60rem;
}

.industries-hero__container {
  padding-right: 4rem;
  padding-left: 4rem;
} 

.industries-hero__heading-container {
  padding: 0;
}

.industries-hero__heading {
  color: #1F3C84;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: 2.6rem;
  line-height: 1.2;
}

.industries-hero__sub-heading {
  font-size: 1.8rem;
  line-height: 1.3;
  margin-bottom: 8rem;
  width: 90%
}

.industries-hero__image {
  width: 55%;
  margin-top: -8rem;
  display: none;
}

.industries-hero__button-container {
  display: flex;
  align-items: center;
  width: 90%;
}

.industries-hero__btn {
  background-color: #1F3C84;
}

.industries-hero__btn:link,
.industries-hero__btn:visited {
  background-color: #1F3C84;
}

.industries-hero__btn:hover,
.industries-hero__btn:active {
  background-color: #383838;
}

.industry-mobile-header__product {
  text-transform: uppercase;
  font-weight: bold;
  color: #1F3C84;
  
}
.industry-mobile-header__row {
  margin: 8rem -1.5rem 0rem -1.5rem;
  justify-content: space-between;
}

.industries-hero__image--mobile {
  width: 7rem;
  margin-left: 5rem;
}

@media (min-width: 575px) {

  .industries-hero__heading {
    font-size: 2.8rem;
  }

  .industries-hero__button-container {
    width: 80%;
  }

  .industries-hero__image--mobile {
    width: 7rem;
    margin-left: 10rem;
  }

}

@media (min-width: 768px) {

  .industries-hero__heading {
    font-size: 3.6rem;
  }

  .industries-hero__heading-container {
    padding: 0;
  }

  .industries-hero__sub-heading {
    font-size: 2rem;
  }

  .industries-hero__sub-heading, .industries-hero__button-container {
    width: 80%;
  }

}

@media (min-width: 992px) {

  .industries-hero__container {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .industries-hero {
    height: 75rem;
  }  

  .industries-hero__heading {
    font-size: 3.4rem;
  }

  .industries-hero__sub-heading {
    line-height: 2.9rem;
    margin-bottom: 5rem;
    font-size: 2rem;
  }

  .industries-hero__heading, .industries-hero__sub-heading, .industries-hero__button-container {
    width: 100%;
  }

  .industries-hero__container {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .industries-hero__container .category-navigation__link {
    color: #1F3C84;
  }

  .industries-hero__container .category-navigation__link:not(:last-of-type) {
    margin-right: 3rem;
  }

  .industries-hero__row {
    align-items: center;
  }

  .industries-hero__heading-container {
    width: 60%;
  }

  .industries-hero__image-container {
    width: 15rem;
    padding-left: 2rem;
    margin-right: 2rem;
  }

  .industries-hero__image {
    display: block;
  }

  .industries-hero__image--mobile {
    display: none;  
  }

  .industries-mobile-header__image {
    display: none;
  }

  .industries-header__icon {
    display: block;
    position: absolute;
    left: -8.5rem;
    top: 1rem;
    width: 3.5rem;
    opacity: 70%;
  }
}

@media (min-width: 1200px) {

  .slanted-masthead.slanted-masthead--industries {
    height: 40rem;
  }

  .industries-hero__row {
    align-items: center;
  }

  .industries-hero__heading-container {
    width: 55%;
  }
}