.mobile-hero-heading {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.v-term-mobile-schedule-demo {
  transform: skewY(-5deg);
  height: 35rem;
  background-color: rgba(0, 186, 130, .07);
  position: relative;
}

.v-term-mobile-schedule-demo__container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) skewY(5deg);
  width: 70%;
  max-width: 55rem;
}

.v-term-mobile-schedule-demo__button:link,
.v-term-mobile-schedule-demo__button:visited {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -80px;
}

.v-term-mobile-schedule-demo__button:hover {
  transform: translateX(-50%) translateY(-3rem);
}

.v-term-mobile-schedule-demo__product {
  text-transform: uppercase;
  color: rgba(0, 186, 130, 1);;
}

.v-term-header__image {
  position: absolute;
  top: 5rem;
  right: 1rem;
  width: 70%;
}

.product-benefits__vterm-product-name {
  text-transform: uppercase;
  color: var(--healpay-green-light);
  font-weight: 600;
  white-space: nowrap;
}

.product-benefits__section--v-term {
  padding-top: 0rem;
  margin-top: -2rem;
}

.product-benefits__schedule-demo--v-term {
  display: none;
}

.product-benefits__image-container--v-term {
  text-align: center;
}

.v-term-features__section {
  transform: skewY(-3deg);
  padding: 15rem 0 0 0;
}

.v-term-features__row {
  margin-bottom: 5rem;
}

.v-term-features__feature {
  padding: 0 4rem;
}

.v-term-features__feature--adjust {
  margin-top: 2rem;
}

.v-term-features__container {
  transform: skewY(3deg);
}

.v-term-features__header {
  color: #0071DC;
  margin-bottom: 6rem;
  padding: 0 4rem;
}

.v-term-features__sub-heading {
  font-weight: 500;
  color: rgba(0, 186, 130, 1);
  margin-bottom: 1.5rem;
}

.v-term-features__snippet-block {
  display: flex;
  justify-content: space-between;
}

.v-term-features__snippet-container {
  width: 45%
}

.v-term-features__snippet {
  font-size: 1.6rem;
  margin-bottom: 6rem;
}

.v-term-features__icon {
  width: 2.4rem;
  height: 2.4rem;
  display: block;
  max-width: max-content;
}

.v-term-features__icon--adjust {
  width: 3.2rem;
  height: 2.8rem;
}

.v-term-features__snippet--upper {
  margin-bottom: 3rem;
}

.v-term-features__snippet--lower {
  display: inline-block;
  margin-top: 1rem;
}

.v-term-features__image-container {
  text-align: start;
}

.v-term-features__image {
  width: 100%;
  margin-bottom: 4rem;
}

.v-term-features__image--1 {
  padding: 0 4rem;
}

.v-term-features__image--2 {
  padding: 0 10rem;
}

.v-term-features__image--3 {
  padding: 0 10rem;
}

.hp-btn--cta--adjust:link,
.hp-btn--cta--adjust:visited {
  min-width: max-content;
  color: #0071DC;
  padding: 0;
  margin-top: .75rem;
  width: 33rem;
  height: 3rem;
  display: block;
  text-align: left;
  background-image: url(/assets/img/software/virtual-terminal/icon/arrow_forward-24px-blue.svg);
  background-position: 83% 25%;
  background-repeat: no-repeat;
  background-size: 1.8rem;
}

.hp-btn--cta--adjust:hover,
.hp-btn--cta--adjust:active {
  color: #383838;
  background-image: url(/assets/img/software/virtual-terminal/icon/arrow_forward-24px-dark-gray.svg);
}

@media (min-width: 400px) {
  .v-term-header__image {
    top: 5rem;
    /* right: 0; */
    width: 30rem;
  }

}

@media (min-width: 575px) {
  .mobile-hero-heading {
    line-height: 3.5rem;
  }

  .v-term-header__image {
    top: 38rem;
    right: 5%;
    width: 33rem;
  }

  .product-benefits__section--v-term {
    margin-top: -5rem;
  }
}

@media (min-width: 768px) {

  .v-term-header__image {
    top: 42.7rem;
    right: 10%;
    width: 37rem;
  }

  .product-benefits__section--v-term {
    margin-top: 0;
  }

  .v-term-features__sub-heading {
    font-weight: 700;
    color: rgba(0, 186, 130, 1);
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 992px) {

  .mobile-hero-heading {
    line-height: 4rem;
  }

  .v-term-header__image {
    top: 36rem;
    right: 9%;
    width: 37rem;
  }

  .product-benefits__section--v-term {
    /* margin-top: -10rem; */
  }

  .product-benefits__row--v-term {
    margin-bottom: 5rem;
  }

  .product-benefits__image-container--v-term {
    text-align: right;
  }

  .product-benefits__image--v-term {
    width: 90%;
  }

  .v-term-mobile-schedule-demo {
    display: none;
  }

  .v-term-features__section {
    background-color: rgba(0, 186, 130, .07);
  }

  .product-benefits__schedule-demo--v-term {
    display: block;
  }

  .v-term-features__row {
    margin-bottom: 10rem;
  }

  .v-term-features__feature {
    margin-left: 4rem;
    padding: 0;
  }  

  .v-term-features__header {
    font-weight: bold;
  }

  .v-term-features__sub-heading {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .v-term-features__snippet {
    margin-bottom: 6rem
  }

  .v-term-features__snippet--upper {
    margin-bottom: 6rem;
  }

  .v-term-features__image {
    width: 40rem;
  }

  .v-term-features__image--1,
  .v-term-features__image--3 {
    padding: 0 2rem;
  }

  .v-term-features__image--2 {
    padding: 0 2rem;
    margin-left: 4rem;
  }

  .v-term-features__image--3 {
    margin-left: 3rem;
  }

  .v-term-image-adjust {
    position: relative;
    left: -8rem;
  }
  
}

@media (min-width: 1200px) {

  .v-term-header__image {
    top: 28rem;
    right: 10%;
    width: 42rem;
  }

  .product-benefits__section--v-term {
    margin-top: 0;
  }

  .product-benefits__image--v-term {
    width: 80%;
  }

  .v-term-features__image {
    width: 45rem;
  }
}


@media (min-width: 1400px) {
  .v-term-header__image {
    right: 12%;
    top: 28rem;
  }
}

@media (min-width: 1500px) {
  .v-term-header__image {
    right: -25%;
    top: 1rem;
  }
}