.slanted-masthead.feature-slant {
  height: 40rem;
}

.slanted-masthead.feature-slant, .slanted-masthead.feature-slant::after {
  background-color: #f5fcfa;
}

.feature-header {
  position: relative;
}

.feature-hero__container {
  padding-right: 3rem;
  padding-left: 3rem;
}

.feature-mobile-header__row {
  margin: 6rem 0 2rem 0;
  justify-content: space-between;
}

.feature-mobile-header__product {
  text-transform: uppercase;
  font-weight: bold;
  color: rgba(0, 186, 130, 1);
}

.features-mobile-header__get-started:link,
.features-mobile-header__get-started:visited {
  background-color: rgba(0, 186, 130, 1);
}

.features-mobile-header__get-started:hover,
.features-mobile-header__get-started:active {
  background-color: #383838;
}

@media (max-width: 768px) {
  .feature-mobile-header__product {
    text-transform: uppercase;
    font-weight: bold;
    color: rgba(0, 186, 130, 1);
  }

  .feature-mobile-header__row {
    margin: 8rem 0 -1rem 0;
    justify-content: space-between;
  }
}


@media (min-width: 768px) {
  .feature-header {
    position: relative;
  }

  .feature-hero__container {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .feature-mobile-header__product {
    display: none;
  }
}

@media (min-width: 1200px) {
  
}
