.blog-post-header__container {
  padding: 0 3rem;
  margin-top: 5rem;
}

.blog-post-header {
  margin: 3rem 0 6rem 0;
}

.blog-post-header__category {
  color: #E2AD00;
  font-weight: 600;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.blog-post-header__title-container {
  padding: 2rem 0 1rem 0;
}

.blog-post-header__link:link,
.blog-post-header__link:visited {
  color: #212529;
}

.blog-post-header__link:hover,
.blog-post-header__link:active {
  color: #707070;
  text-decoration: none;
}

.blog-post-header__title {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.4rem;
}

.blog-post-header__excerpt {
  margin-bottom: 3rem;
}

.blog-post-header__date {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.blog-post-header__author {
  font-size: 2rem;
}

.blog-post-header__image-mobile {
  border-radius: 3px;
  height: 25rem;
  background-size: cover;
  background-position: center;
}

@media (min-width: 575px) {
  .blog-post-header__info-container {
    width: 75%;
  }
}

@media (min-width: 768px) {
  .blog-post-header__image-mobile {
    height: 30rem;
  }
}

@media (min-width: 992px) {

  .blog-post-header {
    display: flex;
    background-color: #fff;
    margin: 6rem 0;
    box-shadow: 2px .5rem 2rem -0.5rem rgba(0,0,0,.5);
    border-radius: 1.5em 1.5em;
  }

  .blog-post-header__info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    position: relative;
    width: 38%;
    border-radius: 5%;
    height: 45rem;
    z-index: 2;
    padding: 3rem;
  }

  .blog-post-header__info-container::after {
    content: "";
    background-color: #fff;
    position: absolute;
    right: -5.5rem;
    top: 0;
    width: 20rem;
    height: 100%;
    transform: skewX(13deg);
    z-index: -1;
  }


  .blog-post-header__excerpt {
    margin-bottom: 0;
  }

  .blog-post-header__image {
    text-align: end;
    padding: 0;
    width: 62%;
    background-size: cover;
    background-position: center;
    border-top-right-radius: 1.5em 1.5em;
    border-bottom-right-radius: 1.5em 1.5em;
  }

  .blog-post-header__image-mobile {
    display: none;
  }
}

@media (min-width: 1200px) {
  .blog-post-header__info-container {
    padding: 4rem;
  }
}