.blog-post-header__image, .blog-post-header__image-mobile {
  position: relative;
}

.blog-post-header__credit, .blog-post-header__credit-mobile {
  font-size: 1.2rem;
  color: #909090;
}

.blog-post-header__credit {
  display: none;
}

.blog-post-header__credit-mobile {
  position: absolute;
  bottom: -3.5rem;
  right: 1rem;
}

.blog-post__content {
  margin: 0 3rem;
  border-bottom: 1px #7c7c7c solid;
  padding-bottom: 4rem;
}

.blog-post__content h2, 
.blog-post__content h3,
.blog-post__content h4,
.blog-post__content h5,
.blog-post__content h6 {
  margin-bottom: 2.5rem;
}

.blog-post__content p {
  margin-bottom: 2rem;
}

.blog-post__content h2 {
  font-weight: 600;
  font-size: 3.1rem;
  line-height: 3.4rem;
  color: #E2AD00;
}

.blog-post__content p {
  font-size: 1.8rem;
  line-height: 37px;
  color: #383838;
}

.blog-post__content p:first-of-type::first-line {
  // color: black;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 37px;
}

.blog-post__content a:link,
.blog-post__content a:visited {
  font-size: 1.8rem;
  color: #0071DC;
  text-decoration: none;
  font-weight: 400;
}

.blog-post__content a:hover,
.blog-post__content a:active {
  color: #707070;
}

.blog-post__content li {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #383838;
}

.blog-post__content li>p {
  margin-bottom: 0rem;
}

.blog-post + hr {
  margin: 0 auto;
  width: 90%;
}
.related-posts-header {
  text-align: center;
  margin: 4rem 0;
}

.related-post {
  justify-content: center;
}

.related-post__container {
  margin-bottom: 4rem;
  box-shadow: 0 1.5rem 5rem rgba(0,0,0,.1);
  max-width: 95%;
  width: 90%;
  transition: all .2s ease;
}

.related-post__container:hover {
  transform: scale(1.03);
  color: #212529;
}

.related-post__container:active {
  transform: scale(1.01);
}

.related-post--1 {
  margin-right: 0;
}

.related-post--2 {
  margin-left: 0;
}

.related-post__image {
  width: 40%;
  height: 19rem;
  background-size: cover;
  float: left;
  background-position: right;
}

.related-post__info {
  padding: 1.5rem;
  width: 60%;
  float: left;
  height: 100%;
  padding-top: 5rem;
  position: relative;
}

.related-post__title {
  display: inline-block;
  font-size: 1.8rem;
  line-height: 2.1rem;
}

.related-post__snippet {
  display: none;
}

.related-post__meta {
  position: absolute;
  bottom: 1rem;
  width: 90%;
}

.related-post__author {
  display: none;
  font-weight: 600;
}

.related-post__type {
  display: none;
  font-weight: 600;
  color: #E2AD00;
}

@media (min-width: 768px) {
  .blog-post-slant::after {
    height: 60rem;
  }  
}

@media (min-width: 992px) {

  .blog-post-header__container {
    padding: 0;
    margin-top: 0;
  }

  .blog-post-header__credit-mobile {
    display: none;
  }

  .blog-post-header__credit {
    position: absolute;
    bottom: -3.5rem;
    right: 1rem;
    display: block;
  }

  .blog-post__content {
    margin: 0 8rem;
  }

  .related-posts-header {
    text-align: center;
    margin: 4rem 0;
  }

  .related-post {
    justify-content: center;
  }

  .related-post__container {
    margin-bottom: 7rem;
    box-shadow: 0 1.5rem 5rem rgba(0,0,0,.1);
    max-width: 47%;
  }

  .related-post--1 {
    margin-right: 1.5rem;
  }

  .related-post--2 {
    margin-left: 1.5rem;
  }

  .related-post__image {
    width: 40%;
    height: 19rem;
    background-size: cover;
    float: left;
    background-position: right;
  }

  .related-post__info {
    padding-top: 1.5rem;
  }

  .related-post__title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .related-post__snippet {
    font-size: 1.4rem;
    display: inline-block;
    line-height: 1.5rem;
    height: 3rem;
    overflow: hidden;
    max-width: 100%;
  }

  .related-post__meta {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .related-post__author {
    display: inline-block;
    margin: 0;
  }

  .related-post__type {
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
  }

}

@media (min-width: 1200px) {
  .related-post--1 {
    margin-right: 2rem;
  }

  .related-post--2 {
    margin-left: 2rem;
  }
}
