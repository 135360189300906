.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0);
  z-index: 1019;
  opacity: 0;
  visibility: hidden;
  transition: all .1s;
}

.modal-overlay.modal-overlay-active {
  visibility: visible;
  opacity: .4;
}

@keyframes fadeInOverlay { 
  0% { 
      opacity: 0; 
  } 
  100% { 
      opacity: .5; 
  } 
}

#submission-complete-modal {
  position: fixed;
  top: 10vw;
  left: 50%;
  margin-left: -25vw;
  width: 50vw;
  height: 40vh;
  background-color: white;
  border-radius: 5px;
  z-index: 1051;
  display: none;
  box-shadow: 2px 3px 50px #999;
}

#submission-complete-modal h3,
#submission-complete-modal p {
  margin: 0 auto;
  text-align: center;
}

#submission-complete-modal p {
  margin-top: 15vh;
  font-size: 3vh;
}

div#submission-complete-modal i {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 25px;
  z-index: 1099;
}

div#submission-complete-modal i:hover {
  color: #999;
}

#submission-complete-modal i:active {
  color: #ccc;
}

#submission-complete-modal h3::before {
  content: '\2714';
  color: green;
  font-size: 10vh
}

@media screen and (max-width: 1230px) {
  #submission-complete-modal {
      width: 70vw;
      height: 40vh;
      top: 25vh;
      margin-left: -35vw;
  }

  #submission-complete-modal {
      width: 80vw;
      margin-left: -40vw;
      height: 40vh;
      top: 25vh;
  }
}

@media screen and (max-width: 992px) {
  #submission-complete-modal {
      width: 80vw;
      margin-left: -40vw;
      height: 40vh;
      top: 25vh;
  }
}


@media screen and (max-width: 768px) {

  #submission-complete-modal {
      width: 90vw;
      margin-left: -45vw;
      height: 40vh;
      top: 30vh;
  }
}

@media screen and (max-width: 575px) {
  #submission-complete-modal {
      width: 90vw;
      margin-left: -45vw;
      height: 40vh;
      top: 30vh;
  }
}
