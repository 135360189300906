.hero-header--faq {
    margin-bottom: 1.5rem;
}

.faq__container {
    margin-top: 15rem;
    margin-bottom: 25rem;
}

.faq__link-list-header { 
    color: #1F3C84;
    margin-bottom: 1.5rem;
}

.faq__link-list-header--2 {
    margin-top: 5rem;
}

.faq__row {
    margin: 12rem 0;
}

.faq-slant {
    background-color: #fbfbfb;
    clip-path: polygon(0% 0%, 100% 0, 100% 66%, 0% 100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 88vh;
    width: 100%;
    z-index: -1;
}

.faq__row--header {
    flex-direction: column;
}

.faq-section-heading {
    font-weight: 500;
}

.faq-section-text {
    margin-top: 1.5rem
}

.faq__image {
    width: 100%;
    margin: 1.5rem 0;
}

.faq__card span {
    color: #1F3C84;
    font-weight: 600;
}

.faq__card {
    box-shadow: 0 .25rem 1rem .25rem rgba(0, 0, 0, .1);
    padding: 2rem;
    border-radius: 3px;
    position: relative;
    margin: 6rem 0rem;
}

.faq__links:link,
.faq__links:visited {
    font-family: 'Metric Web', Helvetica, Arial, Lucida, sans-serif;
    color: #989898;
    display: block;
    text-decoration: none;
    margin-top: 1rem;
}

.faq__links:hover,
.faq__links:active {
    color: #00BA82;
}

.faq__password {
    color: #1F3C84;
}

.faq__note {
    padding: 3rem 3.5rem;
    margin-top: 3rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.2);
    border-radius: 3px;
}

.faq__note span {
    color: #1F3C84;
    font-weight: 700;
}

#faq_mybtn {
    position: fixed;
    bottom: 10px;
    float: right;
    right: 18.5%;
    left: 77.25%;
    max-width: 12rem;
    width: 100%;
    font-size: 12px;
    border-color: rgba(85, 85, 85, 0.2);
    background-color: #00BA82;
    padding: .5px;
    border-radius: 4px;
    text-decoration: none;
}

.return-to-top-btn {
    position: fixed;
    bottom: 5rem;
    float: right;
    right: 23rem;
    left: 77.25%;
    max-width: 12rem;
    width: 100%;
    font-size: 12px;
    border: none;
    background-color: #00BA82;
    padding: 7px;
    border-radius: 4px;
    text-decoration: none;
    outline: none;
    transition: all .3s;
}

.return-to-top-btn:active {
    color: red;
}


/*On Hover Color Change*/
#faq_mybtn a:hover {
    background-color: #00BA82;
    text-decoration: none;
}

@media (max-width: 992px) {
    .faq__container {
        padding: 0 3rem;
    }

    #faq_mybtn {
        left: 67%;
    }
}

@media (min-width: 992px) {

    .faq__link-list-header--2 {
        margin-top: 0;
    }

    .faq__row--what {
        margin-top: 15rem;
        justify-content: space-between;
    }

    .faq__image {
        width: 110%;
    }

    .faq__note {
        width: 110%;
    }

    .faq__note--adjust {
        width: 44rem;
        max-width: 100%;
    }
}

@media (min-width: 1200px) {
    .hero-subheader--faq {
        width: 90%;
    }

    .faq__image {
        width: 150%;
    }
}