.footer {
    position: relative;
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
    background-color: #f2f2f2;
    line-height: 1.5;
}

.footer::before {
    content: "";
    position: absolute;
    transform: skewY(-3deg);
    transform-origin: 0;
    background: #f2f2f2;
    left: 0;
    right: 0;
    top: -1rem;
    height: 30rem;
    box-shadow: 0 -1rem 1.5rem #e2e2e2;
}

.footer h6 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 3rem;
    font-size: 1.6rem;
    color: #383838;
}

.footer p {
    font-size: 1.6rem;
}

.footer a:link,
.footer a:visited {
    color: #707070;
    line-height: 1.75;
    font-size: 1.6rem;
}

.footer a:hover {
    color: #00BA82;
    text-decoration: none;
}

.footer__container {
    position: relative;
    margin-top: 10rem;
}

.footer__links-container {
    margin-top: 1rem;
    margin-bottom: 3rem;
    min-width: 70%;
}

.footer__links-row {
    position: relative;
    margin: 0;
    justify-content: center;
}

.footer__why-healpay-col,
.footer__products-col,
.footer__industries-col,
.footer__resources-col {
    margin-bottom: 5rem;
}

.footer__why-healpay-col, 
.footer__products-col,
.footer__industries-col,
.footer__resources-col {
    width: 80%;
}

.footer__logo {
    display: none;
}

.footer__icons {
    fill: #383838;
}

.footer__post-row {
    margin-top: 3.5rem;
    padding: 0 2rem;
    justify-content: center;
}

.footer__post-row__links-col {
    margin-top: 4rem;
    width: 81%;
    max-width: 81%;
}

.footer__post-row__links-col a {
    color: #707070;
    display: block;
    margin-bottom: 1rem;
}

.footer__post-copyright-block {
    color: #00BA82;
    margin-top: 5rem;
    width: 100%;
    text-align: center;
}

.footer hr {
    width: 100%;
    margin: 0 auto;
}

.footer__post-mobile-icons {
    width: 100%;
    text-align: center;
    margin-top: 5rem;
}

.footer__post-mobile-icons a:link, 
.footer__post-mobile-icons a:visited {
    font-size: 2.5rem;
    margin-right: .8rem;
}

@media (min-width: 400px) {

    .footer__why-healpay-col {
        width: 50%;
    }
    
    .footer__products-col {
        width: 30%;
    }
    
    .footer__industries-col {
        width: 50%;
    }
    
    .footer__resources-col {
        width: 30%;
    }

}

@media (min-width: 768px) {
    
    .footer__post-row {
        position: relative;
        align-items: center;
        padding: 0;
        justify-content: flex-end;
    }

    .footer-links-container {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .footer__links-row {
        justify-content: flex-end;
    }

    .footer__logo {
        display: block;
        position: absolute;
        left: -2%;
        top: -1%
    }

    .footer__logo img {
        width: 10rem;
    }

    .footer__logo img {
        margin-bottom: 2rem;
    }

    .footer__post-row__links-col {
        width: 79%;
        max-width: 80%;
    }

}

@media (min-width: 992px) {

    .footer__container {
        margin-top: 5rem;
    }

    .footer__links-row, 
    .footer__post-row {
        justify-content: flex-end;
    }

    .footer__logo {
        left: 2%;
    }

    .footer__logo img {
        width: auto;
    }

    .footer__why-healpay-col {
        width: 18rem;
    }
    
    .footer__products-col {
        width: 16rem;
    }
    
    .footer__industries-col {
        width: 21rem;
    }
    
    .footer__resources-col {
        width: 16rem;
    }

    .footer__post-mobile-icons,
    .footer__post-copyright-block,
    .footer__post-row__links-col {
        width: auto;
    }

    .footer__post-mobile-icons {
        margin-top: 0;
        margin-right: 10rem;
    }

    .footer__post-row__links-col {
        margin-top: 0;
        margin-right: 0;
        position: relative;
        right: 3rem;
    }

    .footer__post-row__links-col .footer_careers_link {
        margin-right: 0;
    }

    .footer__post-row__links-col a {
        display: inline;
        margin: 0 1rem .5rem 0rem;
    }

    .footer__post-copyright-block {
        position: absolute;
        left: 5%;
        top: 20%;
        margin-top: 0;
    }

}

@media (min-width: 1200px) {

    .footer__links-row,
    .footer__post-row {
        justify-content: flex-end;
    }

    .footer-links-row {
        margin-left: 0;
    }

    .footer__logo {
        position: absolute;
        left: 15rem;
    }

    .footer__logo {
        left: 6%;
        top: 4%;
    }

    .footer__why-healpay-col {
        width: 20rem;
    }
    
    .footer__products-col {
        width: 18rem;
    }
    
    .footer__industries-col {
        width: 24rem;
    }
    
    .footer__resources-col {
        width: 18rem;
    }

    .footer__post-row {
        margin: 0;
        margin-top: 3rem;
        align-items: center;
    }

    .footer__post-row__links-col {
        margin-top: 0;
        max-width: max-content;
        right: -1rem;
    }

    .footer__post-row__links-col a {
        margin: 0 3.5rem 0 0;
        display: inline;
    }

    .footer__post-row__links-col .footer_careers_link {
        margin-right: 0;
    }

    .footer__post-mobile-icons {
        width: auto;
        padding: 0;
        margin-right: 9rem;
    }
    
    .footer__post-copyright-block {
        margin-top: 0;
        position: absolute;
        left: 7%;
        font-size: 1.6rem;
    }

}
