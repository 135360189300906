/* Portal Hero Section */


.portal-header__image {
  position: absolute;
  top: 5rem;
  right: 1rem;
  width: 80%;
}

.hp-btn--cta.hp-btn--portal-lets-talk {
  display: none;
}

.hp-btn--cta.hp-btn--portal-lets-talk:link,
.hp-btn--cta.hp-btn--portal-lets-talk:visited {
  color: #00BA82;
}

.hp-btn--cta.hp-btn--portal-lets-talk:hover,
.hp-btn--cta.hp-btn--portal-lets-talk:active {
  color: #222;
}

.hp-btn--cta.hp-btn--portal-lets-talk .hp-btn--cta-svg-b {
  fill: #00BA82;
}

/* Portal Benefits Section */

.portal-benefits__section {
  padding: 0 1.5rem;
}

.portal-benefits__col-2 {
  display: none;
}

.portal-benefits__benefit:not(:last-of-type) {
  margin-bottom: 5rem;
}

.portal-benefits__image {
  width: 55rem;
}

/* Portal Features Slant Section */


.slanted-section.slanted-section--portal {
  padding: 10rem 7rem;
  height: unset;
}

.portal-features-slant__section {
  transform: skewY(3deg);
}

.portal-features-slant__header {
  text-align: center;
  margin-bottom: 5rem;
}

.portal-features-slant__product {
  text-transform: uppercase;
  color: #00BA82;
  font-weight: 700;
}

.hp-btn--portal-cont {
  margin-top: 3rem;
  text-align: center;
}


/* Portal Benefits Slant Section */

.portal-benefits-slant__section {
  clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
  background-color: #f0f7fd;
  padding: 12rem 1.5rem;
}

.portal-benefits-slant__row-1 {
  justify-content: center;
  margin: 3rem 0 8rem 0;
}

.portal-benefits-slant__row-1-header {
  margin-bottom: 2.6rem;
  color: #00BA82;
}

.portal-benefits-slant__row-2-header {
  color: #1F3C84;
  font-size: 2.6rem;
  margin-bottom: 2rem;
}

.portal-benefits-slant__row-2-snippet {
  font-size: 2rem
}

.portal-benefits-slant__row-2-image {
  display: none;
}

.payment-icon-box--desktop {
  display: none;
}

.payment-icon-box__container {
  justify-content: center;
  margin-top: 10rem;
}

.payment-icon-box {
  margin: 0 4rem;
}

.payment-icon-box__header {
  color: rgba(0, 186, 130, 1);
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.payment-icon-box__snippet {
  width: 65%;
  float: left;
  font-size: 2rem;
  padding-right: 3rem;
  margin-bottom: 1rem;
}

.payment-icon-box__image {
  float: left;
  width: 25rem;
}

.payment-icon-box--mobile .payment-icon-box__snippet {
  width: unset;
  padding-right: unset;
  margin-bottom: 3rem;
}


/* Large Benefits Card Section */

.large-benefits-card__point-container--portal {
  width: 100%;
}

.portal-pro-promo__container {
  padding: 0 6rem;
  margin-top: -3rem;
  margin-bottom: 5rem;
}

.hp-btn--cta-svg {
  width: 1.8rem;
  margin: -2px 0 0 1rem;
}

.hp-btn-cta-svg-1 {
  margin-right: 1rem;
  margin-top: -3px;
}

.hp-btn--cta-svg-a {
  fill: none;
}

.hp-btn--cta-svg-b {
  fill: #0071DC;
}

.hp-btn--cta--portal-adjust:link,
.hp-btn--cta--portal-adjust:visited {
  white-space: nowrap;
  width: unset;
  padding: 0;
  color: #0071DC;
}

.hp-btn--cta--portal-adjust:hover,
.hp-btn--cta--portal-adjust:active {
  background-color: transparent;
  color: #222;
  backface-visibility: hidden;
}

.hp-btn--cta--portal-adjust:hover .hp-btn--cta-svg-b,
.hp-btn--cta--portal-adjust:active .hp-btn--cta-svg-b {
  fill: #222;
}

.hp-btn--cta--ivr-pro-b {
  color: var(--healpay-green-light);
}

.portal-pro-promo h5 {
  padding-bottom: 2rem;
}

/* Contact Sales Section */

.contact-sales__section--portal-adjust {
  margin-top: 10rem;
  padding-top: 0rem;
}

@media (min-width: 400px) {

  .portal-header__image {
    position: absolute;
    top: 5rem;
    width: 32rem;
  }

}

@media (min-width: 575px) {

  .portal-header__image {
    position: absolute;
    top: 39rem;
    right: 3%;
    width: 38rem;
  }

  .portal-pro-promo__container {
    padding: 0;
    padding-left: 5rem;
  }

  .software-header.software-header--portal-row {
    position: static;
  }

  .software-header--portal-col {
    position: static;
  }

}

@media (min-width: 768px) {


  .portal-header__image {
    position: absolute;
    top: 39.5rem;
    right: 6%;
    width: 43rem;
  }

  .portal-pro-promo__container {
    margin-top: 3rem;
    padding-left: 9rem;
  }
}


@media (min-width: 992px) {

  .slanted-section.slanted-section--portal {
    padding: 10% 0 5% 0;
    height: 45rem;
  }

  .software-slant__portal {
    clip-path: polygon(0% 0%, 100% 0, 100% 64.5%, 0% 86%);
  }

  .software-header.software-header--portal-row {
    position: static;
  }

  .software-header--portal-col {
    position: static;
  }

  .hero-heading--portal {
    width: 80%
  }

  .portal-header__image {
    position: absolute;
    top: 35rem;
    right: 5%;
    width: 45rem;
  }

  .hp-btn--cta.hp-btn--portal-lets-talk {
    display: inline-block;
  }

  .portal-benefits__image {
    width: 45vw;
  }

  .portal-benefits-slant__row-1-header {
    font-size: 3.2rem;
  }

  /* Portal Benefits Slant Section */

  .portal-benefits-slant__section {
    clip-path: polygon(0 10%, 100% 0, 100% 93%, 0% 100%);
    background-color: #f0f7fd;
    padding: 12rem 0;
  }

  .portal-benefits-slant__row-2 {
    padding: 4rem 5rem 7rem 5rem;
    background: #fff;
    position: relative;
    border-radius: 3px;
    box-shadow: 2px .3rem .5rem rgba(0,0,0,.2);
    margin: 0 5rem;
  }

  .portal-benefits-slant__row-2-image {
    width: 7.5rem;
    margin: 2rem 0 0 7rem;
    display: block;
  }

  .portal-benefits__col-2 {
    display: block;
    align-self: center;
  }


  .portal-benefits-slant__row-2-header {
    font-size: 3rem;
  }

  .payment-icon-box--desktop {
    display: block;
  }

  .payment-icon-box--mobile {
    display: none;
  }

  .payment-icon-box {
    margin: 0 8rem;
  }
  

}

@media (min-width: 1200px) {

  .portal-header__image {
    position: absolute;
    top: 26rem;
    right: 5%;
    width: 55rem;
  }

  .portal-benefits__image {
    max-width: 65rem;
  }

  .portal-benefits__benefit {
    width: 85%;
  }

  .portal-benefits-slant__row-1-header {
    font-size: 3.6rem;
  }
}

@media (min-width: 1400px) {

  .portal-header__image {
    right: 7%;
  }

}

@media (min-width: 1500px) {

  .software-header--portal-col {
    position: relative;
  }

  .portal-header__image {
    right: -40%;
    top: 0;
  }

}