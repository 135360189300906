*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

/* adding bootstrap width helper */
:root {
  --w-15: width:15%!important;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

html {
  overflow-x: hidden;
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  margin-top: 15rem;
  font-family: 'Metric Web', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 400;
}

h1 {
  font-size: 4.2rem;
  line-height: 5.3rem;
}

h2 {
  font-size: 3.2rem;
  line-height: 4.1rem;
}

h3 {
  font-size: 2.6rem;
  line-height: 3.3rem;
}

h4 {
  font-size: 2.4rem;
  line-height: 3.0rem;
  font-weight: 400;
}

h5 {
  font-size: 2.0rem;
  line-height: 2.5rem;
  font-weight: 400;
}

h6 {
  font-size: 1.8rem;
  line-height: 2.1rem;
}

p {
  font-size: 1.6rem;
}

a:link,
a:visited {
  font-size: 3rem;
  line-height: 3.9rem;
  color: #0071DC;
  transition: all .1s;
  outline: none;
}

a:hover,
a:active {
  color: #383838;
}

hr {
  width: 100%;
}

ul,
ol {
  padding-left: 30px;
}

li {
  font-size: 1.8rem;
  line-height: 2.3rem;
}

.fw-semibold {
  font-weight: 600;
}

/* simple container */

.intro__container {
  margin-top: -8rem;
  padding-bottom: 2rem;
}

.content__container {
  padding-bottom: 5rem;
}

.header-gradient-blue {
  background: rgb(255,255,255);
  background: linear-gradient(55deg, rgba(255,255,255,1) 21%, rgba(214,232,249,1) 47%, rgba(255,255,255,1) 80%);
  position: relative;
}

.content-header {
  margin-top: 4rem;
  margin-bottom: 5rem;
}

.content-section-heading {
  font-weight: 500;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.content-body {
  font-size: 2rem;
  line-height: 2.4rem;
}

.content-link:link,
.content-link:visited {
  color: #00BA82;
}

.content-link:hover,
.content-link:active {
  color: #707070;
}

.feature-bg {
  background-color: #e9f0fb;
}

.feature-list {
  background-color: #ffffff;
  border-color: #afb4bc;
}

/* heading modifiers */

.features {
  color: #00BA82;
}

.products {
  color: #0071DC;
}

.industries {
  color: #5D4BA4;
}

.blog {
  color: #E2AD00;
  font-weight: 600;
}

.news {
  color: #1F3C84;
  font-weight: 600;
}

.product-name {
  color: #56DBA1;
  font-weight: 600;
}

.metadata {
  font-size: .9rem;
  line-height: 1.2rem;
}

.pro-product-name {
  color: #1F3C84;
  font-weight: 600;
}

.heading-strong {
  font-weight: 600;
  line-height: 3.6rem;
}

.feature-grid-header {
  font-weight: 500;
  color: #0071dc;
}

.feature-grid:hover{
  color: #e9f0fb;
}

.icon--software {
  fill: #0071dc;
}

/* table */
.table-border-inside {
	border-collapse: collapse;
        border-style: hidden;

    td {
	  border-left: 1px solid #dee2e6!important;
	  border-right: 1px solid #dee2e6!important;
	  border-top: 1px solid #dee2e6!important;
	  border-bottom: 1px solid #dee2e6!important;
    }

    p {margin-bottom: 0; font-size: 1.8rem;}

}

.table tbody tr:hover td, .table tbody tr:hover th {
  background-color: #d6e8f9;
}

/* Blog post */

.blog-post-title {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.4rem;
}

.blog-category {
  color: #E2AD00;
  font-weight: 600;
  font-size: 1.9rem;
  line-height: 2.6rem;
}

.related-post-excerpt {
  font-size: 2.1rem;
  line-height: 2.7rem;
}

.related-post-meta {
  font-size: 2.4rem;
  line-height: 3.0rem;
}


/* News */

.news-post-title {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.4rem;
}

.news-category {
  color: #989898;
  font-weight: 600;
  font-size: 1.9rem;
  line-height: 2.6rem;
}

.news-low-impact {
  color: #383838;
  font-weight: 600;
  font-size: 1.9rem;
  line-height: 2.6rem;
}


/* pricing card styling */

.pricing-card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom:0;
}

.pricing-card-header h4 {
  font-weight: 500;
  margin-top: 1.5rem;
}

/* Masthead */

.hero-heading {
  color: #00BA82;
  margin-bottom: 2.5rem;
  font-size: 2.6rem;
  line-height: 3.3rem;
}

.hero-sub-heading {
  font-size: 1.8rem;
  line-height: 1.2;
  margin-bottom: 5rem;
  width: 90%;
}

.paragraph-heading {
  font-size: 1.8rem;
  line-height: 2.3rem;
}

blockquote {
  background: #fcf6df;
  border-left: 10px solid #E2AD00;
  margin: 5rem 1rem;
  padding: 2rem;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: #E2AD00;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

@media (min-width: 767px) {

  h1 {
    font-size: 5rem;
    line-height: 7rem;
  }

  h2 {
    font-size: 4.1rem;
    line-height: 5.7rem;
  }

  h3 {
    font-size: 3.2rem;
    line-height: 3.5rem;
  }

  h4 {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  h5 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  h6 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .hero-heading {
    margin-bottom: 4.5rem;
    font-size: 3rem;
    line-height: 3.3rem;
  }
  
  .hero-sub-heading {
    font-size: 2.4rem;
    line-height: 1.2;
    margin-bottom: 5rem;
  }

  .content-header {
    font-size: 5rem;
  }

}

@media (min-width: 992px) {

  .hero-heading {
    margin-bottom: 4rem;
    font-size: 3.5rem;
    line-height: 1.3;
  }
  
  .hero-sub-heading {
    font-size: 2.4rem;
    line-height: 1.2;
    margin-bottom: 5rem;
  }

  .content-header {
    font-size: 5rem;
  }

}

@media screen and (min-width: 1200px) {

  .hero-heading {
    margin-bottom: 4.5rem;
    font-size: 4rem;
  }
  
  .hero-sub-heading {
    font-size: 2.4rem;
    line-height: 1.2;
    margin-bottom: 5rem;
    width: 85%;
  }
  .content-header {
    font-size: 5rem;
  }
  
}



@media (max-width: 76.7rem) {

  h1 {
    font-size: 3.8rem;
    line-height: 5.3rem;
  }

  h2 {
    font-size: 2.9rem;
    line-height: 4.1rem;
  }

  h3 {
    font-size: 2.3rem;
    line-height: 3.3rem;
  }

  h4 {
    font-size: 2rem;
    line-height: 3.0rem;
  }

  h5 {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }

  h6 {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  li {
    font-size: 1.6rem;
    line-height: 2.3rem;
  }

  .content-header {
    font-size: 5rem;
  }
}

