.product-hero--cbd-payments {
  margin-bottom: 10rem;
}

.hero-heading--cdb-payment {
  color: #00BA82;
  line-height: 1.6;
  font-size: 3.5rem;
  margin-bottom: .7rem;
}

.hero-sub-heading--cdb-payment {
  color: #00BA82;
  margin-bottom: 2rem;
}

.cbd-payment_hero-container .category-navigation__link {
  margin-right: 3rem;
}

.cbd-payments__header-image {
  width: 90%;
  margin-left: 5rem;
  margin-top: 4rem;
}

.cbd-online {
  margin-bottom: 15rem;
}

.cbd-compliant {
  margin-bottom: 10rem;
}

.cbd-online__header, .cbd-compliant__header {
  color: #00BA82;
}

.cbd-online__sub-header {
  color: #00BA82;
  margin-bottom: 2rem;
}

.cbd-online__paragraph {
  margin-bottom: 2rem;
}

.cbd-online__image-cont {
  text-align: center;
}

.cbd-online__image {
  width: 70%;
  margin-bottom: 2rem;
}

.cbd-compliant__image-cont {
  text-align: center;
}

.cbd-compliant__image {
  width: 70%;
  margin-bottom: 2rem;
}

.cbd-compliant__paragraph {
  margin-bottom: 2.5rem;
}

.cbd-payments__list {
  list-style: none;
}

.cbd-payments__list-item {
  margin-bottom: .5rem;
  font-size: 1.6rem;
}

.cbd-payments__list-item::before {
  content: "\2022";
  color: #00BA82;
  display: inline-block;
  width: 1em;
  margin-left: -3.2rem;
  margin-right: 1.2rem;
  text-align: right;
  font-size: 2.5rem;
  vertical-align: bottom;
}

.cbd-compliant__list-item::before {
  margin-left: -4.5rem;
}

@media only screen and (min-width: 400px) {
  .cbd-payments__header-image {
    width: 90%;
    margin-left: 5rem;
    margin-top: 4rem;
  }

  .cbd-online__image-cont {
    text-align: left;
  }

  .cbd-online__image {
    max-width: 30rem;
  }

  .cbd-compliant__image-cont {
    text-align: left;
  }

  .cbd-compliant__image {
    max-width: 30rem; 
  }
}

@media only screen and (min-width: 575px) {
  .cbd-payments__header-image {
    width: 80%;
    margin-left: 15rem;
    margin-top: 4rem;
  }

  .cbd-online__image {
    width: 60%;
    margin-bottom: 3rem;
    max-width: none;
  }

  .cbd-compliant__image {
    width: 60%;
    margin-bottom: 3rem;
    max-width: none;
  }

}

@media only screen and (min-width: 768px) {
  .cbd-payments__header-image {
    width: 80%;
    margin-left: 20rem;
    margin-top: 4rem;
  }

  .cbd-online__image {
    width: 45%;
  }

  .cbd-compliant__image {
    width: 45%;
  }

}

@media only screen and (min-width: 992px) {
  .hero-heading--cdb-payment {
    line-height: .5;
    font-size: 4.5rem;
    margin-bottom: 3.5rem;
  }
  
  .hero-sub-heading--cdb-payment {
    margin-bottom: 4rem;
  }

  .hero-sub-heading--cdb-payment-lower {
    width: 70%;
  }

  .cbd-payments__header-image {
    width: 110%;
    margin-left: -10rem;
    margin-top: 11rem;
  }

  .cbd-online__sub-header {
    width: 90%;
  }

  .cbd-online__image {
    width: 95%;
    margin-top: 12rem;
  }

  .cbd-compliant__paragraph--1 {
    width: 95%;
  }

  .cbd-compliant__paragraph--2 {
    width: 75%;
  }

  .cbd-compliant__paragraph--3 {
    width: 72%;
  }

  .cbd-compliant__image {
    width: 100%;
    margin-top: 15rem;
    margin-left: -8rem;
  }
}

@media only screen and (min-width: 1200px) {
  .hero-heading--cdb-payment {
    font-size: 5rem;
  }

  .hero-sub-heading--cdb-payment-lower {
    width: 75%;
  }

  .cbd-payments__header-image {
    width: 110%;
    margin-left: -10rem;
    margin-top: 6rem;
  }

  .cbd-online__sub-header {
    width: 100%;
  }

  .cbd-online__image {
    width: 95%;
    margin-top: 5rem;
  }

  .cbd-compliant__paragraph--1 {
    width: 95%;
  }

  .cbd-compliant__paragraph--2 {
    width: 75%;
  }

  .cbd-compliant__paragraph--3 {
    width: 72%;
  }

  .cbd-compliant__image {
    width: 85%;
    margin-top: 14rem;
    margin-left: -5rem;
  }

}

