.wallet__header-image {
    width: 80%;
    margin-top: 7rem;
    float: right;
    margin-right: 3rem;
}

.wallet-benefits__row {
    justify-content: center;
}

.wallet__sub-heading-cont {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.wallet__sub-heading {
    color: #00BA82;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 500;
}

.wallet-benefits__section {
    margin: 10rem 0 8rem 0;
}

.wallet-benefit__image--mobile {
    margin-right: 1rem;
}

.wallet-benefit__image--desktop-cont {
    display: flex;
    justify-content: center;
}

.wallet-benefit__image--desktop {
    display: none;
    width: 8rem;
    margin-top: -11rem;
}


@media (min-width: 575px) {

    .wallet__header-image {
        width: 80%;
        margin-top: 0;
        float: right;
        margin-right: -5rem;
    }

    .wallet-benefit__image--mobile {
        margin-right: 2rem;
    }

}

@media (min-width: 768px) {

    .wallet__header-image {
        width: 50%;
        margin-top: -3rem;
        margin-right: 4rem;
    }
    .wallet__sub-heading {
        color: #00BA82;
        margin-bottom: 0;
        line-height: 1;
        font-weight: 400;
    }

    .wallet-benefit__image--mobile {
        margin-right: 3rem;
    }

}

@media (min-width: 992px) {

    .hero-sub-heading--wallet {
        width: 110%;
    }

    .wallet-benefits__section {
        margin: 14rem 0 8rem 0;
    }

    .wallet__header-image {
        width: 80%;
        margin-top: 3rem;
        margin-left: 5rem;
    }

    .wallet-benefit__image--mobile {
        display: none;
    }

    .wallet-benefit__image--desktop {
        display: block;
    }

}

@media (min-width: 1200px) {

    .hero-sub-heading--wallet {
        width: 100%;
    }

    .wallet__header-image {
        margin-top: 2rem;
        margin-left: 10rem;
    }

}