.formgroup {
  font-size: 1.6;
  line-height: 1.2;
}

label {
  margin: 2rem 0;
  font-size: 1.6rem;
  line-height: 1.1;
}

input {
  transition: all .1s ease;
  border: 1px solid #707070;
  width: 100%;
  font-size: 2rem;
  line-height: 1.2rem;
  padding: 1.4rem 2.5rem;
}

textarea {
  transition: all .1s ease;
  border: 1px solid #707070;
  width: 100%;
  font-size: 2rem;
  line-height: 1.2rem;
  padding: 2rem 2.5rem;
}

input:focus, textarea:focus {
  outline: none;
  border-color: #56DBA1;
  box-shadow: 0 .3rem .6rem rgba(0,0,0,0.1);
  transition: all .2s;
}

.formactive {
  border: 1px solid #56DBA1;
  box-shadow: 0 .6rem 1.2rem #00000029;
}

.formerror {
  border: 1px solid #E05955;
}

::-webkit-input-placeholder {
  color: rgba(112, 112, 112, .8);
  font-size: 1.6rem;
}

::-moz-placeholder {
  color: rgba(112, 112, 112, .8);
  font-size: 1.6rem;
}

:-ms-input-placeholder {
  color: rgba(112, 112, 112, .8);
  font-size: 1.6rem;
}

:-moz-placeholder {
  color: rgba(112, 112, 112, .8);
  font-size: 1.6rem;
}

@media (min-width: 776px) {

  input {
    transition: all 0.1s ease;
    border: 1px solid #707070;
    width: 100%;
  }

  input:focus {
    outline: none;
    border-color: #56DBA1;
  }

  .formcontrol {
    padding: .5rem 1rem;
    border: 1px solid #707070;
    font-size: 12px;
    line-height: 1.4;
  }

  .formactive {
    padding: .5rem 1rem;
    border: 1px solid #56DBA1;
    font-size: 1.2rem;
    line-height: 1.4;
  }

  .formerror {
    padding: .5rem 1rem;
    border: 1px solid #E05955;
    font-size: 1.2rem;
    line-height: 1.4;
  }

}