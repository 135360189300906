.integrations-cards__section {
    margin-top: 5rem;
}

.integrations-card {
    border-radius: .3rem;
    box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .2);
    background-color: white;
    padding: 2.5rem 3.5rem;
    margin: 4rem 0rem;
    align-items: center;
}

.integrations-card__row {
    align-items: center;
}

.integrations-card__title {
    color: #1F3C84;
    margin-bottom: 1rem;
    font-size: 2.2rem;
}

.integrations-card__snippet {
    font-size: 2rem;
}

.integrations-card__logo-container {
    padding: 3rem 0;
    display: flex;
    justify-content: space-around;
}

.integrations-card__logo {
    margin: 0;
}

.integrations-card__logo--authorize {
    width: 25%;
}

.integrations-card__logo--vericheck {
    width: 20%;
}

.integrations-card__logo--jst {
    width: 12%;
}

.integrations-card__logo--cogent {
    width: 20%;
}

.integrations-card__logo--vertican {
    width: 24%;
}

.integrations-card__logo--spreedly {
    width: 20%;
}

.integrations-card__logo--usaepay {
    width: 20%;
}

.integrations-card__logo--wells {
    width: 11%;
}

.integrations-cta-button {
    margin: 4rem 0 3rem 0px;
}

.integrations-benefits__section {
    margin: 3rem 0 10rem 0;
}

.integrations-benefits {
    padding: 0 2rem;
}

.integrations-benefits__title {
    margin-bottom: 3rem;
}

.integrations-benefits__snippet {
    font-size: 2rem;
    width: 90%
}

.integrations-benefits__img {
    display: none;
}

.integrations-form__container {
    margin-bottom: 15rem;
}

.integrations-form__row {
    justify-content: center;
}

.integrations-form__title {
    color: #1F3C84;
}


.integrations-form__label {
    color: #707070;
}

.integrations-form__input {
    border-radius: .3rem;
}
#how-to-int {
    line-height: 3rem;
}

@media(min-width: 575px) {

    .why-healpay-intro__snippet--integrations {
        width: 87%;
    }

}

@media(min-width:768px) {

    .why-healpay-intro__snippet--integrations {
        width: 85%;
    }

    .integrations-card {
        padding: 4rem 5rem;
        margin: 3rem -6rem;
    }

    .integrations-card__title {
        font-size: 2.4rem;
    }

    .integrations-card__snippet {
        font-size: 2.2rem;
    }


    .integrations-card__logo {
        margin: 0 2rem;
    }

}

@media(min-width: 992px) {

    .why-healpay-intro__title--integrations {
        white-space: nowrap;
    }

    .why-healpay-intro__snippet--integrations {
        width: 95%;
    }

    .integrations-cards__section {
        border-bottom: 1px solid #ccc;
    }

    .integrations-card__logo-container {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
    }

    .integrations-card__logo {
        width: 10rem;
        margin: 0 2rem;
    }

    .integrations-card__logo--authorize {
        width: 10rem;
    }
    
    .integrations-card__logo--jst {
        width: 8rem;
    }
    
    .integrations-card__logo--cogent {
        width: 12rem;
    }
    
    .integrations-card__logo--vertican {
        width: 14rem;
    }
    
    .integrations-card__logo--spreedly {
        width: 10rem;
        /* margin-left: 4rem; */
    }
    
    .integrations-card__logo--usaepay {
        width: 10rem;
        /* margin-left: 4rem; */
    }
    
    .integrations-card__logo--wells {
        width: 6rem;
        margin: 0 0 0 4rem;
    }

    .integrations-cta-button {
        margin: 7rem 0 3rem 0px;
    }

    .integrations-benefits__section {
        margin: 10rem 0;
    }

    .integrations-benefits__row {
        display: flex;
        justify-content: space-between;
    }

    .integrations-benefits__snippet {
        font-size: 2rem;
        width: 60%
    }

    .integrations-benefits__img {
        height: 25rem;
        margin-top: -10rem;
        display: inline;
    }
}

@media(min-width: 1200px) {

    .integrations-card__logo {
        margin: 0 3rem;
    }

    .integrations-card__snippet--2 {
        width: 80%;
    }

    .integrations-benefits__row {
        display: flex;
        justify-content: space-between;
    }

    .integrations-benefits__snippet {
        font-size: 2rem;
        width: 70%
    }

}