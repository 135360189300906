.hp-btn,
.hp-btn:link,
.hp-btn:visited {
  display: inline-block;
  padding: 7px 4px;
  min-width: 15rem;
  height: 4rem;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 1.6rem;
  border: 0;
  border-radius: 3px;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  transition: transform .2s ease-out;
}

.hp-btn:hover {
  transform: translateY(-.3rem);
  box-shadow: 0px 10px 10px #00000029;
  background-color: #383838; 
}

.hp-btn:active {
  transform: translateY(-.1rem);
  box-shadow: 0px 6px 8px #00000029;
  background-color: #383838; 
}

.hp-btn--primary {
  background: #00BA82 0% 0% no-repeat padding-box;
}

.hp-btn-alternate {
  background: #383838 0% 0% no-repeat padding-box;
}

.hp-btn-industries {
  background: #5D4BA4 0% 0% no-repeat padding-box;
}


.hp-btn--contact {
  background: #0071DC 0% 0% no-repeat padding-box;
}

.hp-btn-blog {
  background: #E2AD00 0% 0% no-repeat padding-box;
}

.hp-btn--high-impact:link {
  padding: 10px 1px;
  background-color: #00BA82;
}

.hp-btn--high-impact:hover {
  background-color: #383838;
}

.hp-btn--cta:link,
.hp-btn--cta:visited {
  padding: 10px;
  width: 100px;
  color: #00BA82;
  box-shadow: none;
}

.hp-btn--cta:hover {
  color: #00d495;
  background-color: rgba(0,0,0,0);
}

.hp-btn--cta-img {
  margin-left: 2rem;
  height: 1.6rem;
  margin-top: -5px;
}