.product-hero--convenience-fees {
  margin-bottom: 2rem;
}

.convenience-fees__header-image {
  width: 85%;
  margin-top: 5rem;
  margin-left: 25%;
}

.conv-fees__how-it-works {
  margin-bottom: 10rem;
}

.conv-fees__how-it-works-title {
  color: var(--healpay-green-light);
  margin-bottom: 2.5rem;
  margin-right: 1rem;
}

.conv-fees__how-it-works-cont {
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;
}

.conv-fees__how-it-works-item {
  margin: 0 1rem 4rem;
}

.conv-fees__how-it-works-icon {
  width: 4rem;
  margin-left: -1rem;
  margin-bottom: 1rem;
}

.conv-fees__how-it-works-text {
  font-size: 2rem;
}

.conv-fees-legal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  justify-content: space-around;
}

.conv-fees-legal__image {
  width: 100%;
  margin-bottom: 5rem;
  max-width: 35rem;
}

.conv-fees-legal__block {
  width: 50rem;
  max-width: 100%;
  margin: 0 1rem;
}

.conv-fees-legal__title {
  color: var(--healpay-green-light)
}

.conv-fees-legal__text {
  font-size: 2rem;
}

.slanted-product__section.slanted-product__section--conv-fees {
  background-color: #f5fcfa;
  padding: 10rem 0;
}

.conv-fees__frictionless {
  transform: skewY(3deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.conv-fees__frictionless-block {
  width: 50rem;
  max-width: 100%;
  margin-bottom: 4rem;
}

.conv-fees__frictionless-title {
  color: var(--healpay-green-light);
}

.conv-fees__frictionless-text {
  font-size: 1.8rem;
}

.conv-fees__frictionless-text:not(:last-of-type) {
  margin-bottom: 3rem;
}

.conv-fees__frictionless-list {
  margin-bottom: 3rem;
}

.conv-fees__frictionless-list-item-group {
  margin-bottom: 3rem;
}

.conv-fees__frictionless-list-item {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
}

.conv-fees__frictionless-list-item-image {
  margin-right: 1rem;
  width: 1.6rem;
}

.conv-fees__frictionless-list-item-text {
  margin: 0;
  font-size: 1.6rem;
  margin-bottom: 2px;
}

.conv-fees__frictionless-image {
  width: 25rem;
}

@media only screen and (min-width: 400px) {
  .convenience-fees__header-image {
    width: 75%;
    margin-top: 5rem;
    margin-left: 30%;
  }
}

@media only screen and (min-width: 575px) {
  .convenience-fees__header-image {
    width: 75%;
    margin-top: 5rem;
    margin-left: 30%;
  }
}

@media only screen and (min-width: 768px) {
  .convenience-fees__header-image {
    width: 65%;
    margin-top: -1rem;
    margin-left: 40%;
  }

  .conv-fees__frictionless {
    flex-direction: row;
  }

  .conv-fees__frictionless-block {
    width: 50rem;
    margin-bottom: 0;
  }

  .conv-fees__frictionless-list-item {
    margin-left: 2rem;
  }
}

@media only screen and (min-width: 992px) {
  .convenience-fees__header-image {
    width: 120%;
    margin-top: 10rem;
    margin-left: -5rem;
  }

  .product-hero--convenience-fees {
    margin-bottom: 10rem;
  }  

  .conv-fees__how-it-works-title {
    margin-right: 0;
  }

  .conv-fees__how-it-works-cont {
    flex-direction: row;
    margin-bottom: 15rem;
  }

  .conv-fees__how-it-works-item {
    margin-right: 3rem;
    margin-bottom: 0;
  }

  .conv-fees-legal {
    flex-direction: row;
  }

  .conv-fees-legal__image {
    width: 30rem;
    margin-bottom: 0;
    max-width: unset;
  }

}

@media only screen and (min-width: 1200px) {
  .convenience-fees__header-image {
    width: 110%;
    margin-top: 12rem;
    margin-left: -5rem;
  }

  .conv-fees-legal__image {
    width: 40rem;
  }
}