
.newsletter-signup__container {
  padding: 0;
  margin: 0;
  max-width: unset;
  position: relative;
  top: 7rem
}

.newsletter-signup {
  z-index: 10;
  position: relative;
  top: -2rem;
  background-color: #F8DB67;
  box-shadow: 0 .5rem 1.5rem rgba(0,0,0,.2);
  width: 100%;
  padding: 3rem 2rem;
  text-align: center;
}

.newsletter-signup.newsletter-signup--news {
  background-color: #A2D2FF;
}

.newsletter-signup__tagline {
  margin-bottom: 2rem;
}

.newsletter-signup__form {
  display: block;
  margin: 0 auto;
  font-size: 0;
}

.newsletter-signup__input {
  border: 1px solid #ABB7AF;
  width: 80%;
  height: 5rem;
  margin-bottom: 3rem;
}

.newsletter-signup__input:focus {
  box-shadow: none;
}

.newsletter-signup__input::placeholder {
  font-size: 1.6rem;
}

.newsletter-signup__submit {
  border-radius: 0;
  box-shadow: none;
  display: block;
  margin: 0 auto;
}

.newsletter-signup__submit:hover,
.newsletter-signup__submit:active {
  transform: none;
  box-shadow: none;
}

@media (min-width: 768px) {
  .newsletter-signup__container {
    max-width: 72rem;
    margin: 0 auto;
    padding: 0 1.5rem; 
  }
}


@media (min-width: 992px) {
  .newsletter-signup__container {
    top: 0;
    max-width: 96rem;
  }

  .newsletter-signup {
    z-index: 10;
    position: relative;
    top: -2rem;
    background-color: #F8DB67;
    box-shadow: 0 .5rem 1.5rem rgba(0,0,0,.2);
    width: 100%;
    padding: 5rem 2rem;
    text-align: center;
  }
  
  .newsletter-signup__tagline {
    display: inline-block;
  }
  
  .newsletter-signup__form {
    display: inline-block;
    margin-left: 2rem;
    width: 60%;
    font-size: 0;
  }
  
  .newsletter-signup__input {
    border: 1px solid #ABB7AF;
    width: 70%;
    height: 5rem;
    margin-bottom: 0;
    display: inline-block;
  }
  
  .newsletter-signup__input:focus {
    box-shadow: none;
  }
  
  .newsletter-signup__submit {
    border-radius: 0;
    box-shadow: none;
    height: 4.99rem;
    position: relative;
    top: -0.1rem;
    display: inline-block;
  }
  
  .newsletter-signup__submit:hover,
  .newsletter-signup__submit:active {
    transform: none;
    box-shadow: none;
  }
}

@media (min-width: 1200px) {
  .newsletter-signup__container {
    max-width: 114rem;
  }
}