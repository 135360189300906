.testimonial-template {
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.testimonial-template__title {
  margin-bottom: 5rem;
  font-size: 2.6rem;
  margin-left: 1.6rem;
}

.testimony-template__client {
  color: #1F3C84;
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.testimony-template__client--mobile {
  color: #1F3C84;
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 2.2rem;
}

.testimony-template__video-container {
  padding: 0;
  margin-left: -6.5rem;
  margin-right: -6.5rem;
}

.testimonial-template__text-container {
  padding: 0;
}

.testimonial-template__header {
  font-size: 2rem;
  margin-bottom: 3rem;
}

.testimonial-template__name {
  font-size: 2rem;
}

.testimonial-template__link:link,
.testimonial-template__link:visited {
  font-size: 2rem;
  color: var(--healpay-green-light);
}

.testimonial-template__link:hover,
.testimonial-template__link:active {
  color: var(--hover-black);
  text-decoration: none;
}

.testimonial-template__products-used-cont {
  margin-bottom: 10rem;
}

.testimony-template__product-used:link,
.testimony-template__product-used:visited {
   text-transform: uppercase;
   color: #00BA82;
   font-weight: 700;
}

.testimony-template__product-used:hover,
.testimony-template__product-used:active {
  color: #56DBA1;
  text-decoration: none;
}

.testimonial-template__product {
  font-size: 2rem;
}

.testimonial-template__product-link:link,
.testimonial-template__product-link:visited {
  color: #1F3C84;
  font-size: 2rem;
  font-weight: bold;
}

.testimonial-template__product-link:hover {
  color: #A2D2FF;
  text-decoration: none;
}

.testimonial-template__product-link:active {
  color: #1F3C84;
  text-decoration: none;
}

.testimony-video {
  width: 100%;
}

.testimony-template__snippet {
  margin-top: 5rem;
  font-style: italic;
  font-size: 2rem;
  width: 90%;
}

.testimonial-template__transcript {
  margin: 3rem 0 5rem 0;
}

.testimony-template__testimony-navigation {
  padding: 0 0 0 4rem;
  display: none;
}

.testimony-template__navigation-header {
  margin-bottom: 3rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  color:#989898;
  font-weight: 600;
}

.testimony-template__navigation-header--mobile {
  margin-bottom: 2.5rem;
  text-transform: uppercase;
  color:#989898;
  font-weight: 600;
}

.testimony-template__navigation-link:link,
.testimony-template__navigation-link:visited {
  text-transform: uppercase;
  color:#989898;
  text-decoration: none;
  display: block;
  margin-bottom: 2rem;
  font-weight: 700;
}


.testimony-template__navigation-link:hover {
  color: #A2D2FF;
}

.testimony-template__navigation-link:active {
  color: #1F3C84;
}

.testimony-template__testimony-navigation--mobile {
  margin-right: 0;
  margin-left: 0;
}

.testimony-template__navigation-link--mobile:link,
.testimony-template__navigation-link--mobile:visited {
  color: #1F3C84;
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  margin-bottom: 2rem;
}

.testimony-template__navigation-link--mobile:hover,
.testimony-template__navigation-link--mobile:active {
  color: #A2D2FF;
  text-decoration: none;
}

.transcript__checkbox {
  display: none;
}

.testimony-quote {
  height: 0;
  overflow: hidden;
  transition: height .3s ease;
}

.transcript__button {
  color: #1F3C84;
  font-weight: 600;
  cursor: pointer;
  font-size: 2rem;
}

.transcript__checkbox:checked ~ .testimony-quote {
  height: 100%;
}

.transcript__button--arrow {
  height: 4rem;
  margin-top: -.75rem;
  transition: all .5s;
  transform: rotate(-90deg);
}

.transcript__checkbox:checked ~ .transcript__button .transcript__button--arrow {
  transform: rotate(0deg);
}

@media screen and (min-width: 767px) {
  .testimony-video {
    width: 100%;
  }

  .testimony-template__video-container {
    margin-left: 0;
    margin-right:0;
  }
}

@media screen and (min-width: 992px) {

  .testimonial-template {
    margin-top: 0;
  }

  .testimonial-template__title {
    font-size: 3.8rem;
    margin-left: 0;
  }

  .testimony-template__client--mobile {
    display: none;
  }

  .testimonial-template__content {
    padding: 0 0 0 0rem;
  }

  .testimony-template__testimony-navigation {
    padding-left: 11rem;
    display: block;
  }

  .testimonial-template__transcript {
    margin: 3rem 0 25rem 0;
  }

  .testimony-template__testimony-navigation--mobile {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .testimony-video {
    width: 100%;
  }
}