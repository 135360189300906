.treatments__header-image {
    width: 86%;
    margin-top: 7rem;
    float: right;
}

.treatments__intelligent-portfolio-image {
    width: 100%;
}

.treatments_card_mobile-title {
    margin-bottom: 3rem;
}

.treatments_card {
    border: 1rem solid rgba(0, 0, 0, 0);
    box-shadow: 2px 3px 9px #00000029;
    z-index: 1;
    padding: 2rem;
}

.treatments-card__title {
    font-weight: 600;
    color: #00B983;
    font-size: 2.5rem;
    padding: 2% 0;
}

.treatments-card__title--desktop {
    display: none;
}

.treatments-card__row {
    justify-content: space-around;
}

.treatments-card__card-block {
    background-color: #f6faff;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 48%;
    height: 22rem;
}

.treatment-card__item {
    display: flex;
    align-items: center;
}

.treatment-card__image {
    width: 1.8rem;
}

.treatment-card__snippet {
    margin: 0 0 0 3rem;
}

.treatment-card__button-container {
    justify-content: right;
    margin: 4rem 1rem 0 0;
}

.treatment-features__row {
    justify-content: center;
}

.treatment-features__card {
    padding: 3rem 0;
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 27rem;
    margin-top: 5rem;
}

.treatment-features__card--1-snippet-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.treatment-features__card-snippet {
    color: var(--healpay-green-dark);
    margin-bottom: 0;
    font-weight: 500;
}

.treatment-features__card--1-snippet {
    width: 80%;
    color: var(--healpay-green-dark);
    margin-bottom: 0;
    font-weight: 500;
}

.treatment-features__card--1-icon {
    width: 4rem;
}

.treatment-features__image {
    width: 17rem;
}

.treatments-benefits__row {
    padding: 9rem 0;
    align-items: center;
    justify-content: space-between;
}

.treatments-benefits__image {
    width: 95%;
}

.treatments-benefits__row--mobile {
    padding: 6rem 0;
    align-items: center;
    justify-content: center;
}

.slanted-product__snippet--treatments-mobile {
    margin: 0 2rem;
}

.treatments-benefits {
    background-color: #f0f7fd;
    margin: 10rem 0;
    display: none;
}

.treatments-benefits-mobile {
    background-color: #f0f7fd;
    margin: 10rem 0;
}

.treatments-button__portalpro {
    width: 30rem;
}

@media (min-width: 575px) {
    .treatments_card_mobile-title__title {
        margin: 0 3rem;
    }
}


@media (min-width: 768px) {
    .treatments__header-image {
        width: 65%;
        margin-top: 1rem;
        float: right;
    }
    .treatment-features__card--1-snippet {
        width: 80%;
        color: var(--healpay-green-dark);
        margin-bottom: 0;
        font-weight: 400;
    }
    .treatment-features__card-snippet {
        color: var(--healpay-green-dark);
        margin-bottom: 0;
        font-weight: 400;
    }

    .treatments__intelligent-portfolio-image {
        width: 100%;
    }
}

@media (min-width: 992px) {

    .treatments__header-image {
        width: 90%;
        margin-top: 1rem;
        margin-left: 5rem;
        float: left;
    }
    

    .treatment-card__button-container {
        justify-content: left;
        margin: 3rem 0 0 2.8rem ;
    }

    .treatments-benefits {
        display: block;
    }

    .treatments-benefits-mobile {
        display: none;
    }

    .treatment-features__row {
        justify-content: space-between;
    }

    .treatment-features__card {
        padding: 3rem 3rem;
        width: 99%;
        height: 30rem;
        box-shadow: 0 .3rem .5rem rgba(0,0,0,.2);
    }

    .treatments__header-image {
        margin-top: 5rem;
        margin-left: 5rem;
    }

    .slanted-product__snippet--treatments {
        text-align: left;
        width: 90%;
    }

    .treatment-features__card--description {
        font-size: 2rem;
        line-height: 1.4;
    }

    .treatments_card_mobile-title {
        display: none;
    }

    .treatments-card__title--desktop {
        display: block;
    }

}

@media screen and (min-width: 1200px) {

    .slanted-product__snippet--treatments {
        width: 85%;
    }

    .treatment-features__card {
        height: 27rem;
    }
}