/* Merchant services hero */

.software-hero--merchant-services {
  margin-bottom: 30rem;
  height: unset;
}

.software-header__col-1 {
  z-index: 2;
}

.merchant-services__hero-tagline {
  color: #008961;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.hero-heading--merchant-services {
  font-size: 4.5rem;
  line-height: 1.35;
  color: #008961;
  margin-bottom: 4rem;
}

.hero-sub-heading--merchant-services {
  color: #008961;
  margin-bottom: 3rem;
}

.hero-snippet--merchant-services {
  width: 85%;
  margin-bottom: 3rem;
}

.hp-btn--merchant-services {
  background-color: #008961;
}

.software-header__image-container.software-header__image-container--merchant-services {
  top: 10vw;
  right: -5vw;
  position: relative;
}

.software-header__image.software-header__image--merchant-services {
  display: none;
  width: 90vw;
}

/* Merchant Services benefits */

.merchant-services-benefits__container {
  padding-right: 3rem;
  padding-left: 3rem;
}

.merchant-services-benefits__row {
  margin-bottom: 10rem;
}

.merchant-services-benefits__image-1-cont {
  text-align: center;
  display: none;
}

.merchant-services-benefits__image-1 {
  width: 83%;
}

.merchant-services-benefits__image-2-cont {
  text-align: end;
  padding-bottom: 5rem;
}

.merchant-services-benefits__image-2 {
  width: 95%;
}

.merchant-services-benefits__image-3-cont {
  text-align: center;
  padding-bottom: 5rem;
}

.merchant-services-benefits__image-3 {
  width: 70%;
}

.merchant-services-benefits__image-text {
  margin: 7rem auto;
  color: #00BA82;
  line-height: 3.2rem;
}

.merchant-services-benefits__category {
  text-transform: uppercase;
  color: #008961;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.merchant-services-benefits__title {
  margin-bottom: 2.5rem;
  font-size: 3.5rem;
}

.merchant-services-benefits__main-snippet {
  color: #008961;
  margin-bottom: 2.5rem;
  line-height: 1.3;
  font-size: 1.8rem;
}

.merchant-services-benefits__main-snippet--1 {
  width: 75%;
}

.merchant-services-benefits__main-snippet--2 {
  margin-bottom: 4rem;
}

.merchant-services-benefits__sub-heading {
  color: #008961;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.merchant-services-benefits__snippet {
  margin-bottom: 4.5rem;
  width: 85%;
}

.merchant-services-benefits__snippet--2-bottom {
  margin-bottom: 2rem;
}

.merchant-services-benefits__list {
  margin-bottom: 4rem;
  list-style-image: url('/assets/img/software/merchant-services/check-24px.svg');
  list-style-position: outside;
}

.merchant-services-benefits__list-item {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

.contact-sales__section--merchant-services {
  padding: 2rem 0;
}

.merchant-services__fin-footer {
  position: relative;
  top: 11rem;
  left: -5rem;
  max-width: 100%; 
  display:block; 
  height: auto;
}

@media (min-width: 360px) {

  .hero-heading--merchant-services {
    font-size: 3.8rem;
  }
  

  .software-hero--merchant-services {
    margin-bottom: 0rem;
  }

  .software-header__image-container.software-header__image-container--merchant-services {
    top: -6vw;
    right: -4vw;
  }

  .software-header__image.software-header__image--merchant-services {
    display: block;
  }

  .merchant-services-benefits__section {
    padding-top: 30rem;
  }

  .merchant-services-benefits__container {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media (min-width: 575px) {
  .software-header--merchant-services .portal-header__image {
    top: 47rem;
    right: -8%;
  }

  .software-header--merchant-services .rainbow--software {
    right: -12rem;
    top: 50rem;
  }
}

@media (min-width: 768px) {

  .hero-heading--merchant-services {
    font-size: 4.5rem;
  }

  .software-hero--merchant-services {
    margin-bottom: 30rem;
  }

  .software-header--merchant-services .portal-header__image {
    top: 41rem;
    right: -5%;
  }

  .software-header--merchant-services .rainbow--software {
    right: -16rem;
    top: 45rem;
  }

  .merchant-services-benefits__section {
    padding-top: 0;
  }

  .merchant-services-benefits__image-2-cont {
    text-align: center;
    padding-bottom: 5rem;
  }

  .merchant-services-benefits__image-2 {
    width: 75%;
  }

  .merchant-services-benefits__image-3 {
    width: 50%;
  }
}

@media (min-width: 992px) {

  .hero-heading--merchant-services {
    font-size: 5.3rem;
  }

  .software-hero--merchant-services {
    margin-bottom: 15rem;
  }

  .software-header--merchant-services .portal-header__image {
    top: 10rem;
    right: 0;
  }

  .software-header--merchant-services .rainbow--software {
    right: -12rem;
    top: 15rem;
  }

  .merchant-services-mobile-schedule-demo {
    display: none;
  }

  .merchant-services-benefits__image-text {
    width: 75%;
  }

  .merchant-services-benefits__image-1-cont {
    text-align: left;
    display: block;
  }

  .merchant-services-benefits__image-2-cont {
    text-align: end;
    padding-top: 15rem;
  }

  .merchant-services-benefits__title--2 {
    white-space: nowrap;
  }

  .merchant-services-benefits__image-3-cont {
    padding-top: 10rem;
  }
  
  .software-header__image.software-header__image--merchant-services {
    width: 80vw;
  }

  .software-header__image-container.software-header__image-container--merchant-services {
    top: 25vw;
    right: 25vw;
  }

  .merchant-services-benefits__image-2-cont {
    text-align: end;
    padding-top: 15rem;
  }

  .merchant-services-benefits__image-2 {
    width: 95%;
  }

  .merchant-services-benefits__image-3 {
    width: 70%;
  }
}

@media (min-width: 1200px) {

  .software-header--merchant-services {
    position: static;
  }

  .software-header--merchant-services .software-header--portal-col {
    position: static;
  }

  .software-header--merchant-services .portal-header__image {
    top: 35rem;
    right: 5rem;
  }

  .software-header--merchant-services .rainbow--software {
    right: -12rem;
    top: 40rem;
  }

  .software-header__image-container.software-header__image-container--merchant-services {
    top: 4vw;
    right: 15vw;
    position: static;
  }

  .software-header__image.software-header__image--merchant-services {
    width: 70vw;
    max-width: 90rem;
    position: absolute;
    top: 36rem;
    right: -1rem;
  }

}
  
@media (min-width: 1600px) {
  .software-header--merchant-services .portal-header__image {
    right: 15%;
  }
}

@media (min-width: 1900px) {
  .software-header--merchant-services .portal-header__image {
    right: 20%;
  }
}



  