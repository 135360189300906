.terms__container {
  margin-top: 10rem;
  margin-bottom: 25rem;
}

.terms-header {
  margin-bottom: 4rem;
}

.terms-section-heading {
  font-weight: 500;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.terms-link:link,
.terms-link:visited {
  color: #00BA82;
}

.terms-link:hover,
.terms-link:active {
  color: #707070;
}