.mobile-hero-heading {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.ivr-hero__image {
  position: absolute;
  top: 4rem;
  right: 0;
  width: 75%;
}

.ivr-benefits__image-container {
  position: relative;
  margin-bottom: 1.5rem;
  text-align: center;
}

.ivr-benefits__image {
  width: 75%;
  display: none
}

.ivr-benefits__image-mobile {
  width: 75%;
  max-width: 30rem;
}

.ivr-benefits__image-mobile--2 {
  margin-right: -5rem;
}

.ivr-benefits {
  margin-bottom: 4rem;
  padding: 0 4rem;
}

.slanted-section--ivr {
  height: 75rem;
}

.slanted-section__ivr-benefits {
  transform: skewY(3deg);
}

.slanted-section__ivr-benefits-header {
  color: var(--healpay-green-light);
  margin-bottom: 2rem;
  font-weight: 500;
}

.slanted-section__ivr-benefits-row {
  padding: 3rem 0;
  justify-content: space-evenly;
  align-items: center;
}

.hp-btn--cta--ivr-adjust:link,
.hp-btn--cta--ivr-adjust:visited {
  text-align: left;
  margin-top: .5rem;
}


.large-benefits-card__header {
  margin-right: 0rem;
}

.ivr-precard__container {
  padding-top: 4rem;
}

.ivr-precard {
  padding: 0 3rem;
}

.ivr-precard__header {
  color: var(--healpay-green-light);
  margin-bottom: 3rem;
  font-size: 2.8rem;
  text-transform: uppercase;
}

.ivr-card__header {
  justify-content: center;
}

.ivr-card__header-title {
  color: var(--healpay-green-light);
  padding: 0 1rem;
}

.ivr-card__subheading {
  margin: 0rem -3rem;
}

.ivr-card__subheading .col-1 {
  position: relative;
  top: 4rem;
  right: -10rem;
}

.ivr-card__row {
  justify-content: space-evenly;
  margin: 0 auto;
}

.ivr-card__container {
  position: relative;
  right: 0rem;
  top: 1rem;
}

.ivr-card__title {
  color: var(--features-blue);
  text-align: left;
  font-size: 1.6rem;
  padding-left: 4rem;
}

.ivr-card__snippet {
  font-size: 1.6rem;
}

.ivr-card__icon {
  position: absolute;
  height: 5.5rem;
  left: 1.5rem;
  width: 2.5rem;
  top: -1.5rem;
}


@media (min-width: 400px) {

  .ivr-hero__image {
    top: 5rem;
    right: 5%;
    width: 25rem;
  }

  .ivr-card__header-title {
    padding: 0 2rem;
  }

  .slanted-section--ivr {
    height: 65rem;
  }
}

@media (min-width: 575px) {

  .ivr-hero__image {
    position: absolute;
    top: 36rem;
    right: 9%;
    width: 28rem;
  }

  .ivr-benefits__image-container {
    text-align: right;
  }

  .ivr-benefits__image-container--2 {
    text-align: left;
    padding-left: 4rem;
  }

  .ivr-benefits__image-mobile {
    max-width: 35rem;
  }

  .ivr-benefits__image-mobile--2 {
    margin-right: 0;
  }

  .ivr-card__title {
    font-size: 2rem;
  }
  
  .ivr-card__snippet {
    font-size: 1.8rem;
  }

  .slanted-section--ivr {
    height: 60rem;
  }

  .ivr-precard {
    padding: 0 2rem;
  }
}

@media (min-width: 768px) {

  .ivr-hero__image {
    top: 39rem;
    right: 15%;
    width: 33rem;
  }

  .ivr-promo__container {
    margin-top: 3rem;
    padding-left: 8rem;
  }

  .ivr-benefits__image-container {
    margin-bottom: -1.5rem;
    text-align: right;
  }
  .slanted-section__ivr-benefits-header {
    color: var(--healpay-green-light);
    margin-bottom: 2rem;
    font-weight: 400;
  }
  
}

@media (min-width: 992px) {

  .ivr-hero__image {
    top: 35rem;
    right: 10%;
    width: 35rem;
  }

  .ivr-precard__section {
    background-color: #f4f9fd;
    margin: 5rem 0 -31rem 0;
    height: 45rem;
  }

  .ivr-benefits__row {
    padding: 1rem 0;
  }

  .ivr-benefits {
    padding: 0 2rem;
    margin-bottom: 0;
  }

  .ivr-benefits__snippet {
    margin-bottom: 4rem;
    width: 95%;
  }

  .ivr-benefits__snippet--2 {
    margin-bottom: 4rem;
    width: 85%;
  }

  .ivr-benefits__snippet--3 {
    padding: 0 2rem;
    margin-bottom: 5rem;
  }

  .ivr-benefits__image-container {
    position: relative;
    text-align: unset;
    margin-bottom: 0;
  }

  .ivr-benefits__image {
    width: 38rem;
    position: absolute;
    display: block;
  }

  .ivr-benefits__image--1 {
    right: 2rem;
    top: -1rem;
  }

  .ivr-benefits__image--2 {
    right: 13rem;
    top: -6rem;
  }

  .ivr-benefits__image--3 {
    right: 9rem;
    top: -3rem;
  }

  .ivr-benefits__image-mobile {
    display: none;
  }
  
  .slanted-section--ivr {
    height: 55rem;
  }
  
  .slanted-section__ivr-benefits {
    transform: skewY(3deg);
  }
  
  .slanted-section__ivr-benefits-header-row {
    justify-content: center;
    text-align: center;
  }

  .slanted-section__ivr-benefits-header {
    font-size: 2.6rem;
  }
  
  .slanted-section__ivr-benefits-row {
    padding: 3rem 0;
    justify-content: space-evenly;
    align-items: center;
  }

  .ivr-pro-features__header {
    font-size: 3.5rem;
    font-weight: bold;
  }
  .ivr-card__subheading .col-1 {
    position: relative;
    top: -2rem;
    right: -2rem;
  }
  .ivr-card__subheading .product-benefits__sub-heading {
    position: relative;
    right: 0rem;
    top: 1rem;
  }
  

  .ivr-precard__container {
    padding-top: 8rem;
    text-align: center;
  }

  .ivr-precard__header {
    font-size: 3.2rem;
    text-transform: capitalize;
  }

  .ivr-precard__snippet {
    width: 55rem;
    margin: 0 auto;
  }

  .ivr-card__header-title {
    font-size: 2.8rem;
    padding: 0;
  }

  .ivr-card__row {
    padding: 3rem 0;
  }

  .ivr-card__container {
    margin-bottom: 0;
  }

  .ivr-card__title {
    text-align: left;
    padding-left: 0rem;
  }

  .ivr-card__icon {
    position: absolute;
    height: 5.5rem;
    left: -4rem;
    width: 2.5rem;
    top: -1.5rem;
  }

  .ivr-card__snippet {
    width: 120%;
  }
  
  .ivr-card__snippet--adjust {
    width: 130%;
  }

}

@media (min-width: 1200px) {

  .ivr-hero__image {
    top: 26rem;
    right: 10%;
    width: 42rem;
  }

  .hero-heading--ivr {
    white-space: nowrap;
  }

  .ivr-card__header-title {
    font-size: 3.6rem;
  }

  .slanted-section--ivr {
    height: 50rem;
  }

  .ivr-precard__snippet {
    font-size: 2rem;
  }

  .ivr-benefits__snippet--3 {
    padding: 0 4rem;
  }

}

@media (min-width: 1400px) {

  .ivr-hero__image {
    right: 13%;
  }

}

@media (min-width: 1500px) {

  .ivr-hero__image {
    right: -17%;
    top: 0;
  }

}
@media (max-width: 992px) {
  #ivr__slanted-section {
    display: none;
  }
}