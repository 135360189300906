.ninety-day-trial-promo-modal {
  box-sizing: border-box;
  position: fixed;
  top: 9vw;
  left: 50%;
  display: none;
  margin-left: calc(-25%);
  width: 50vw;
  height: auto;
  opacity: 0;
  background: linear-gradient(135deg, #86F9A4, #0BCDF7);
  border-radius: 10px;
  z-index: 1051;
  box-shadow: 2px 3px 50px #000;
  color: white;
  max-height: min-content;
}

.ninety-day-trial-promo-modal-active {
  display: initial;
  animation: fadeInPromoModal ease .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInPromoModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ninety-day-trial-promo-modal-submitted {
  box-sizing: border-box;
  position: fixed;
  top: 9vw;
  left: 50%;
  margin-left: calc(-25%);
  width: 50vw;
  background: linear-gradient(135deg, #86F9A4, #0BCDF7);
  border-radius: 10px;
  z-index: 1051;
  box-shadow: 2px 3px 50px #000;
  color: white;
  max-height: min-content;
  transition: height .3s;
}

.ninety-day-logo {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 75px;
  height: 75px;
}

.close-promo {
  position: absolute;
  right: 40px;
  top: 30px;
  font-size: 24px;
  transition: font-size .3s;
  transition: rotate .3s;
  cursor: pointer;
}

.close-promo:hover {
  font-size: 26px;
  rotate: 90deg;
}

.close-promo:active {
  font-size: 28px;
}

.ninety-day-promo-container {
  margin-top: 100px;
}

.ninety-day-promo-container-submitted {
  display: none;
}

.ninety-day-trial-messaging h3 {
  font-size: 48px;
  margin-bottom: 0;
}

.ninety-day-trial-messaging p {
  font-size: 18px;
}

.ninety-day-trial-promo-form input[type="text"],
.ninety-day-trial-promo-form input[type="email"] {
  border-radius: 3px;
  border: 1px solid white;
  padding: 5px 10px;
  width: 199px;
  min-width: 199px;
  height: 52px;
  box-sizing: border-box;
  margin: 10px;
}

.ninety-day-trial-promo-form input::placeholder {
  color: #9c9c9c;
  font-size: .9rem;
  opacity: 1;
}

.ninety-day-trial-promo-form button {
  box-sizing: border-box;
  font-size: 18px;
  background-color: transparent;
  color: white;
  border: 4px solid white;
  border-radius: 2px;
  padding: 10px 25px;
  margin-top: 1rem;
  transition: box-shadow .3s;
  transition: background-color .2s;
}

.ninety-day-trial-promo-form button:hover {
  box-shadow: 2px 2px 10px #aaa;
  background-color: rgba(255, 255, 255, 1);
  color: #48E3CE;
}

.ninety-day-trial-promo-form button:active {
  box-shadow: 2px 2px 10px #fff;
  color: rgb(109, 255, 236);
}

.ninety-day-promo-submitted-container {
  display: none;
  transition: display 1s;
}

.ninety-day-promo-submitted-container-submitted {
  margin-top: 120px;
  display: inline-block;
}

.ninety-day-promo-submitted-container .row {
  margin-bottom: 86px;
}


@media screen and (max-width: 1230px) {
  #submission-complete-modal {
    width: 70vw;
    height: 40vh;
    top: 25vh;
    margin-left: -35vw;
  }

  #submission-complete-modal {
    width: 80vw;
    margin-left: -40vw;
    height: 40vh;
    top: 25vh;
  }

  .ninety-day-trial-promo-modal {
    position: fixed;
    top: 15vw;
    left: 50%;
    margin-left: -30vw;
    width: 60vw;
    height: auto;
    max-height: min-content;
    transition: height .3s;
  }

  .ninety-day-trial-promo-modal-submitted {
    height: 44vh;
  }

  .ninety-day-promo-submitted-container .row {
    margin-bottom: 36px;
  }

  .ninety-day-promo-container {
    margin-top: 120px;
  }

  .ninety-day-trial-messaging h3 {
    font-size: 42px;
    margin-bottom: 0;
  }

  .ninety-day-trial-messaging p {
    font-size: 18px;
    max-width: 60%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 992px) {
  #submission-complete-modal {
    width: 80vw;
    margin-left: -40vw;
    height: 40vh;
    top: 25vh;
  }

  .ninety-day-trial-promo-modal {
    position: fixed;
    top: 15vw;
    left: 50%;
    margin-left: -36vw;
    width: 72vw;
    height: auto;
    max-height: min-content;
    transition: height .3;
  }

  .ninety-day-trial-promo-modal-submitted {
    height: 44vh;
  }

  .ninety-day-promo-submitted-container .row {
    margin-bottom: 46px;
  }

  .ninety-day-promo-container {
    margin-top: 120px;
  }

  .ninety-day-trial-messaging h3 {
    font-size: 42px;
    margin-bottom: 0;
  }

  .ninety-day-trial-messaging p {
    font-size: 18px;
    max-width: 60%;
    margin: 0 auto;
  }
}


@media screen and (max-width: 768px) {

  #submission-complete-modal {
    width: 90vw;
    margin-left: -45vw;
    height: 40vh;
    top: 30vh;
  }

  .ninety-day-trial-promo-modal {
    position: fixed;
    top: 25vw;
    left: 50%;
    margin-left: -40vw;
    width: 80vw;
    height: auto;
    max-height: min-content;
    transition: height .3;
  }

  .ninety-day-trial-promo-modal-submitted {
    height: 50vh;
  }

  .ninety-day-promo-submitted-container .row {
    margin-bottom: 46px;
  }

  .ninety-day-promo-container {
    margin-top: 140px;
  }

  .ninety-day-trial-messaging h3 {
    font-size: 36px;
    margin-bottom: 0;
  }

  .ninety-day-trial-messaging p {
    font-size: 16px;
    max-width: 65%;
    margin: 0 auto;
  }

}

@media screen and (max-width: 575px) {
  #submission-complete-modal {
    width: 90vw;
    margin-left: -45vw;
    height: 40vh;
    top: 30vh;
  }

  .ninety-day-trial-promo-modal {
    position: fixed;
    top: 25vw;
    left: 50%;
    margin-left: -40vw;
    width: 80vw;
    height: auto;
    max-height: min-content;
    transition: height .3s;
  }

  .ninety-day-trial-promo-modal-submitted {
    height: 44vh;
  }

  .ninety-day-promo-submitted-container .row {
    margin-bottom: 56px;
  }

  .ninety-day-promo-container {
    margin-top: 140px;
  }

  .ninety-day-trial-messaging h3 {
    font-size: 36px;
    margin-bottom: 0;
  }

  .ninety-day-trial-messaging p {
    font-size: 14px;
    max-width: 80%;
    margin: 0 auto;
  }

  .ninety-day-trial-promo-form input[type="text"] {
    box-sizing: border-box;
    margin: 10 auto;
  }

  .ninety-day-trial-promo-form input::placeholder {
    color: #9c9c9c;
    font-size: .9rem;
    opacity: 1;
  }

  .ninety-day-trial-promo-form button {
    box-sizing: border-box;
    font-size: 18px;
    padding: 10px 25px;
    margin-top: 1rem;
  }

}

@media screen and (max-width: 500px) {

  .ninety-day-trial-promo-modal {
    position: fixed;
    top: 25vw;
    left: 50%;
    margin-left: -45vw;
    width: 90vw;
    height: auto;
    max-height: min-content;
    transition: height .3s;
  }

  .ninety-day-trial-promo-modal-submitted {
    height: 44vh;
  }

  .ninety-day-promo-submitted-container .row {
    margin-bottom: 56px;
  }

  .ninety-day-promo-container {
    margin-top: 140px;
  }

  .ninety-day-trial-messaging h3 {
    font-size: 28px;
  }

  .ninety-day-trial-messaging p {
    font-size: 14px;
  }
}