.grouped-organizations-cards {
    position: relative;
    margin: 6rem auto;
    /* margin-bottom: -5rem; */
    z-index: 2;
}

.organizations-card {
    box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .2);
    background-color: white;
    padding: 3rem 2rem;
    margin: 4rem 0rem;
    border-radius: .5rem;
}

.organizations-card__img {
    width: 15rem;
}

.organizations-card__img--rmai {
    width: 12rem;
}

.organizations-card__img--nasp {
    width: 10rem;
}

.organizations-card__img--general-bar {
    width: 7rem;
}

.organizations-card__img--nasp {
    width: 8rem;
}

.organizations-card__img--ncba {
    width: 12rem;
}

.organizations-card__img--maca {
    width: 10rem;
}

.organizations-card__img--ccef {
    width: 9rem;
}

.organizations-card__img--mcba {
    width: 10rem;
}

.organizations-card__img--mwaa {
    width: 12rem;
}

.organizations-card__name {
    display: none;
    color: #1F3C84;
    padding-bottom: 1rem;
    font-size: 2.3rem;
}

.organizations-card__snippet {
    margin: 3rem 0;
    line-height: 1.4;
}

.organizations__btn-container {
    display: flex;
    justify-content: flex-end;
}

.organizations__btn-container--2 {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.hp-btn--cta--organizations:link,
.hp-btn--cta--organizations:visited {
    float: right;
    font-weight: 600;
    height: unset;
    margin-top: 2rem;
}

@media screen and (min-width: 400px) {
    .organizations__btn-container {
        flex-direction: row;
    }

    .hp-btn--cta--organizations:link,
    .hp-btn--cta--organizations:visited {
        margin-top: 0;
    }
}

@media screen and (min-width:768px) {

    .organizations-card {
        padding: 4rem;
    }
    
}

@media screen and (min-width: 992px) {

    .organizations-card {
        justify-content: space-between;
        align-items: center;
        height: 26rem;
        margin: 4rem -3rem;
        padding: 4rem 2rem;
    }

    .organizations-card__img-container--general-bar {
        padding: 0;
    }

    .organizations-card__img {
        width: 12rem;
    }

    .organizations-card__img--nasp {
        width: 8rem;
        margin: 0 0 0 2rem;
    }

    .organizations-card__img--general-bar {
        width: 7rem;
        padding: 0;
        margin: 0 0 0 4rem;
    }

    .organizations-card__img--maca {
        width: 11rem;
        margin: 0 0 0 1rem;
    }

    .organizations-card__img--ccef {
        width: 10rem;
        margin: 0 0 0 1rem;
    }

    .organizations-card__img--mcba {
        width: 10rem;
        margin: 0 0 0 1rem;
    }

    .organizations-card__img--mwaa {
        width: 11em;
        margin: 0 0 0 .5rem;
    }

    .organizations-card__name {
        display: block;
    }

    .organizations-card__snippet {
        width: 80%;
        margin: 1rem 0 0 0;
        line-height: 1.4;
    }

    .hp-btn--cta--organizations:link,
    .hp-btn--cta--organizations:visited {
        float: unset;
    }

    .organizations__btn-container {
        flex-direction: column;
        height: 90%;
        justify-content: flex-end;
    }

    .organizations__btn-container--2 {
        justify-content: space-between;
    }
}

@media screen and (min-width: 1200px) {
    .organizations-card__img-container {
        padding: 5rem;
    }

    .organizations-card__img-container--nasp {
        padding: 2rem 5rem;
    }

    .organizations-card__img-container--general-bar {
        padding: 0;
    }

    .organizations-card__img {
        width: 15rem;
    }

    .organizations-card__img--nasp {
        width: 10rem;
        margin: 0 0 0 3rem;
    }

    .organizations-card__img--general-bar {
        width: 7rem;
        margin: 0 0 0 8rem;
    }

    .organizations-card__img--maca {
        width: 12rem;
        margin: 0 0 0 2rem;
    }

    .organizations-card__img--ccef {
        width: 12rem;
        margin: 0 0 0 2rem;
    }

    .organizations-card__img--mcba {
        width: 12rem;
        margin: 0 0 0 2rem;
    }

    .organizations-card__img--mwaa{
        width: 13rem;
        margin: 0 0 0 1rem;
    }
}